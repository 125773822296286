<template>
  <div v-if="vehicle" id="service-eyeservice">
    <div class="service-tabs">
      <button
        class="flex-grow truncate reyesol-services-tab"
        :class="{
          selected: activeTab === 'service',
        }"
        @click="activeTab = 'service'"
      >
        {{ i18n.t("services.eyeservice.tabs.service") }}
      </button>
      <button
        v-if="has_eyeservice_expenses"
        class="flex-grow truncate flex flex-row justify-center gap-1 reyesol-services-tab"
        :class="{
          selected: activeTab === 'expenses',
        }"
        @click="activeTab = 'expenses'"
      >
        <span class="truncate">{{
          i18n.t("services.eyeservice.tabs.expenses")
        }}</span>
        <span
          v-if="active_alerts && active_alerts.length > 0"
          class="rounded-full bg-reyesol-gray px-2"
          >{{ active_alerts.length }}</span
        >
      </button>
      <button
        class="flex-grow truncate reyesol-services-tab"
        :class="{
          selected: activeTab === 'config',
        }"
        @click="activeTab = 'config'"
      >
        {{ i18n.t("services.eyeservice.tabs.config") }}
      </button>
    </div>
    <div v-show="activeTab === 'service'">
      <div class="w-full mr-2">
        <button
          class="btn btn-sm btn-primary mb-3 w-full"
          @click="addNewMaintenance"
        >
          <div class="w-8 h-8 rounded-md flex items-center justify-center">
            <PlusIcon />
          </div>
          <div class="ml-4 mr-auto">
            <div class="font-medium">
              {{ i18n.t("services.eyeservice.add") }}
            </div>
          </div>
        </button>
      </div>
      <!-- ServiceCard -->
      <div
        v-if="addActive"
        class="flex-cols items-center justify-between p-5 border-2 border-color-gray-500"
      >
        <!-- type -->
        <div class="flex items-center mb-4">
          <label class="w-4/12 mr-4">{{
            i18n.t("services.eyeservice.select_type")
          }}</label>
          <select v-model="addType" class="form-select">
            <option v-if="has_eyeservice_fridge" value="0">
              {{ i18n.t("services.eyeservice.type.0.name") }}
            </option>
            <option v-if="has_eyeservice_fgas" value="1">
              {{ i18n.t("services.eyeservice.type.1.name") }}
            </option>
            <option v-if="has_eyeservice_recorder" value="2">
              {{ i18n.t("services.eyeservice.type.2.name") }}
            </option>
            <option value="3">
              {{ i18n.t("services.eyeservice.type.3.name") }}
            </option>
          </select>
        </div>
        <div class="flex items-center mb-4 capitalize">
          <label class="w-4/12 mr-4">{{
            i18n.t("services.eyeservice.date")
          }}</label>
          <input v-model="addDate" type="date" class="form-control" />
        </div>
        <!-- code -->
        <div
          v-if="
            addType == 0 ||
            (addType == 3 && vehicle.configurations.general_maintenance_hours)
          "
          class="flex items-center mb-4 capitalize"
        >
          <label class="w-4/12 mr-4">{{
            i18n.t("services.eyeservice.type." + addType + ".hours")
          }}</label>
          <input v-model="addHours" type="text" class="form-control" />
        </div>
        <div
          v-if="addType == 3 && vehicle.configurations.general_maintenance_km"
          class="flex items-center mb-4 capitalize"
        >
          <label class="w-4/12 mr-4">{{
            i18n.t("services.eyeservice.type." + addType + ".km")
          }}</label>
          <input v-model="addKm" type="text" class="form-control" />
        </div>

        <!-- save -->
        <div class="flex justify-end">
          <button class="btn btn-secondary mr-2" @click.stop="cancelForm()">
            {{ i18n.t("general.cancel") }}
          </button>

          <button class="btn btn-primary" @click.stop="save()">
            <loading-icon
              v-if="loading"
              icon="rings"
              color="white"
              class="mr-2"
            />
            {{ i18n.t("general.save") }}
          </button>
        </div>
      </div>
      <div
        v-if="
          power_data &&
          power_data.power &&
          power_data.power.general_hour_meter !== null
        "
        class="box p-5 my-5"
      >
        <div
          v-if="power_data.power.general_hour_meter !== null"
          class="flex flex-wrap items-center justify-center"
        >
          <!--Power Level-->
          <VehicleParameter
            :active="power_data.power.general_hour_meter !== null"
            :name="i18n.t('services.eyepower.general_hour_meter.name')"
            icon="ClockIcon"
            :value="power_data.power.general_hour_meter + ' h'"
          />
        </div>
      </div>
      <div v-if="data" class="box">
        <template
          v-if="
            data.maintenance ||
            data.routine_maintenance ||
            data.temperature_recorder_maintenance ||
            data.fgas_maintenance
          "
        >
          <div class="report-box cursor-pointer mb-8">
            <div class="box p-5">
              <div class="flex justify-around">
                <VehicleParameter
                  :active="data.routine_maintenance !== null"
                  :name="i18n.t('services.eyeservice.routine_maintenance.name')"
                  :icon="
                    dayjs(data.routine_maintenance?.message_datetime).add(
                      vehicle.configurations.fridge_maintenance_time || 12,
                      'month',
                    ) < dayjs()
                      ? 'AlertOctagonIcon'
                      : 'ToolIcon'
                  "
                  :value="
                    dayjs(data.routine_maintenance?.message_datetime)
                      .add(
                        vehicle.configurations.fridge_maintenance_time || 12,
                        'month',
                      )
                      .format('DD/MM/YYYY')
                  "
                  :setclass="
                    (val) =>
                      dayjs(val, 'DD/MM/YYYY') < dayjs()
                        ? 'bg-reyesol-alert-warning'
                        : 'bg-reyesol-gray'
                  "
                />
                <VehicleParameter
                  v-if="
                    data.maintenance !== null &&
                    vehicle.configurations.general_maintenance_km &&
                    geo_data &&
                    geo_data.position
                  "
                  :active="
                    data.maintenance !== null &&
                    vehicle.configurations.general_maintenance_km &&
                    geo_data &&
                    geo_data.position &&
                    geo_data.position.km !== null
                  "
                  :name="
                    parseInt(vehicle.configurations.general_maintenance_km) +
                      data.maintenance.km >
                    geo_data.position.km
                      ? i18n.t('services.eyeservice.maintenance.next_km')
                      : i18n.t('services.eyeservice.maintenance.since_km')
                  "
                  :icon="
                    parseInt(vehicle.configurations.general_maintenance_km) +
                      data.maintenance.km >
                    geo_data.position.km
                      ? 'AlertOctagonIcon'
                      : 'ToolIcon'
                  "
                  :value="
                    Math.abs(
                      parseInt(vehicle.configurations.general_maintenance_km) +
                        data.maintenance.km -
                        geo_data.position.km,
                    ) +
                    ' ' +
                    i18n.t('services.eyeservice.maintenance.km')
                  "
                  :setclass="
                    parseInt(vehicle.configurations.general_maintenance_km) +
                      data.maintenance.km -
                      geo_data.position.km <=
                    0
                      ? 'bg-reyesol-alert-warning'
                      : 'bg-reyesol-gray'
                  "
                />
                <VehicleParameter
                  v-if="
                    data.maintenance !== null &&
                    vehicle.configurations.general_maintenance_hours &&
                    power_data &&
                    power_data.power
                  "
                  :active="
                    data.maintenance !== null &&
                    vehicle.configurations.general_maintenance_hours &&
                    power_data &&
                    power_data.power &&
                    power_data.power.general_hour_meter !== null
                  "
                  :name="
                    parseInt(vehicle.configurations.general_maintenance_hours) +
                      data.maintenance.hours >
                    power_data.power.general_hour_meter
                      ? i18n.t('services.eyeservice.maintenance.next_hours')
                      : i18n.t('services.eyeservice.maintenance.since_hours')
                  "
                  :icon="
                    parseInt(vehicle.configurations.general_maintenance_hours) +
                      data.maintenance.hours >
                    power_data.power.general_hour_meter
                      ? 'AlertOctagonIcon'
                      : 'ToolIcon'
                  "
                  :value="
                    Math.abs(
                      parseInt(
                        vehicle.configurations.general_maintenance_hours,
                      ) +
                        data.maintenance.hours -
                        power_data.power.general_hour_meter,
                    ) +
                    ' ' +
                    i18n.t('services.eyeservice.maintenance.hours')
                  "
                  :setclass="
                    parseInt(vehicle.configurations.general_maintenance_hours) +
                      data.maintenance.hours -
                      power_data.power.general_hour_meter <=
                    0
                      ? 'bg-reyesol-alert-warning'
                      : 'bg-reyesol-gray'
                  "
                />
                <VehicleParameter
                  :active="
                    data.maintenance !== null &&
                    vehicle.configurations.general_maintenance_time > 0
                  "
                  :name="
                    dayjs(data.maintenance?.message_datetime).add(
                      vehicle.configurations.general_maintenance_time,
                      'month',
                    ) < dayjs()
                      ? i18n.t('services.eyeservice.maintenance.since_months')
                      : i18n.t('services.eyeservice.maintenance.next_months')
                  "
                  :icon="
                    dayjs(data.maintenance?.message_datetime).add(
                      vehicle.configurations.general_maintenance_time,
                      'month',
                    ) < dayjs()
                      ? 'AlertOctagonIcon'
                      : 'ToolIcon'
                  "
                  :value="
                    dayjs(data.maintenance?.message_datetime)
                      .add(
                        vehicle.configurations.general_maintenance_time,
                        'month',
                      )
                      .diff(dayjs(), 'month') +
                    ' ' +
                    i18n.t('services.eyeservice.maintenance.months')
                  "
                  :setclass="
                    (val) =>
                      dayjs(val, 'DD/MM/YYYY') < dayjs()
                        ? 'bg-reyesol-alert-warning'
                        : 'bg-reyesol-gray'
                  "
                />
                <VehicleParameter
                  :active="
                    data.maintenance !== null &&
                    vehicle.configurations.general_maintenance_time > 0
                  "
                  :name="
                    dayjs(data.maintenance?.message_datetime).add(
                      vehicle.configurations.general_maintenance_time,
                      'month',
                    ) < dayjs()
                      ? i18n.t('services.eyeservice.maintenance.since_date')
                      : i18n.t('services.eyeservice.maintenance.next_date')
                  "
                  :icon="
                    dayjs(data.maintenance?.message_datetime).add(
                      vehicle.configurations.general_maintenance_time,
                      'month',
                    ) < dayjs()
                      ? 'AlertOctagonIcon'
                      : 'ToolIcon'
                  "
                  :value="
                    dayjs(data.maintenance?.message_datetime)
                      .add(
                        vehicle.configurations.general_maintenance_time,
                        'month',
                      )
                      .format('DD/MM/YYYY')
                  "
                  :setclass="
                    (val) =>
                      dayjs(val, 'DD/MM/YYYY') < dayjs()
                        ? 'bg-reyesol-alert-warning'
                        : 'bg-reyesol-gray'
                  "
                />
                <VehicleParameter
                  :active="data.temperature_recorder_maintenance !== null"
                  :name="
                    i18n.t(
                      'services.eyeservice.temperature_recorder_maintenance.name',
                    )
                  "
                  :icon="
                    dayjs(
                      data.temperature_recorder_maintenance?.message_datetime,
                    ).add(
                      vehicle.configurations.recorder_maintenance_time || 12,
                      'month',
                    ) < dayjs()
                      ? 'AlertOctagonIcon'
                      : 'ClockIcon'
                  "
                  :value="
                    dayjs(
                      data.temperature_recorder_maintenance?.message_datetime,
                    )
                      .add(
                        vehicle.configurations.recorder_maintenance_time || 12,
                        'month',
                      )
                      .format('DD/MM/YYYY')
                  "
                  :setclass="
                    (val) =>
                      dayjs(val, 'DD/MM/YYYY') < dayjs()
                        ? 'bg-reyesol-alert-warning'
                        : 'bg-reyesol-gray'
                  "
                />

                <VehicleParameter
                  :active="data.fgas_maintenance !== null"
                  :name="i18n.t('services.eyeservice.fgas_maintenance.name')"
                  :icon="
                    dayjs(data.fgas_maintenance?.message_datetime).add(
                      vehicle.configurations.fgas_maintenance_time || 12,
                      'month',
                    ) < dayjs()
                      ? 'AlertOctagonIcon'
                      : 'FeatherIcon'
                  "
                  :value="
                    dayjs(data.fgas_maintenance?.message_datetime)
                      .add(
                        vehicle.configurations.fgas_maintenance_time || 12,
                        'month',
                      )
                      .format('DD/MM/YYYY')
                  "
                  :setclass="
                    (val) =>
                      dayjs(val, 'DD/MM/YYYY') < dayjs()
                        ? 'bg-reyesol-alert-warning'
                        : 'bg-reyesol-gray'
                  "
                />
              </div>
            </div>
          </div>
        </template>
        <div v-else class="font-light text-xl text-gray-500">
          {{ i18n.t("general.no-results") }}
        </div>
      </div>
      <div v-else class="flex justify-center py-6">
        <loading-icon icon="rings" class="w-20 h-20" />
      </div>
      <div class="py-6">
        <div class="text-lg capitalize mb-4">
          {{ i18n.t("services.eyeservice.service_history") }}
        </div>
        <div v-if="!loadingHistory" class="max-h-80 overflow-y-auto">
          <div v-if="history">
            <div v-for="h in history" :key="h.id">
              <div class="intro-y">
                <div
                  class="box px-4 py-4 mb-3 flex items-center cursor-pointer mx-2 shadow-md hover:shadow-lg hover:bg-gray-200"
                >
                  <div
                    class="w-10 h-10 flex items-center justify-center image-cover rounded-md overflow-hidden"
                  >
                    <FeatherIcon v-if="h.type == 1" />
                    <ClockIcon v-else-if="h.type == 2" />
                    <ToolIcon v-else />
                  </div>
                  <div class="ml-4 mr-auto">
                    <div class="font-medium">
                      {{ dayjs(h.message_datetime).format("DD/MM/YYYY") }}
                    </div>
                    <div class="text-gray-600 text-xs mt-0.5">
                      {{
                        i18n.t("services.eyeservice.type." + h.type + ".date")
                      }}
                    </div>
                  </div>
                  <div class="flex flex-col items-center mr-3">
                    <div
                      class="py-1 px-2 rounded-full text-xs bg-theme-10 text-white cursor-pointer font-medium"
                    >
                      {{
                        i18n.t("services.eyeservice.type." + h.type + ".name")
                      }}
                    </div>
                    <div v-if="h.data != null" class="mt-2">
                      {{ h.data }}
                      {{
                        i18n.t("services.eyeservice.type." + h.type + ".unit")
                      }}
                    </div>
                    <div v-if="h.hours != null" class="mt-2">
                      {{ h.hours }}
                      {{
                        i18n.t("services.eyeservice.type." + h.type + ".hours")
                      }}
                    </div>
                    <div v-if="h.km != null" class="mt-2">
                      {{ h.km }}
                      {{ i18n.t("services.eyeservice.type." + h.type + ".km") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="font-light text-xl text-gray-500">
            {{ i18n.t("general.no-results") }}
          </div>
        </div>
        <loading-icon v-else icon="rings" class="w-20 h-20" />
      </div>
    </div>
    <div
      v-if="has_eyeservice_expenses"
      v-show="activeTab === 'expenses'"
      class=""
    >
      <div class="flex">
        <div class="grow w-full mr-2">
          <button
            class="btn btn-sm btn-primary mb-3 w-full"
            @click="addNewExpense"
          >
            <div class="w-8 h-8 rounded-md flex items-center justify-center">
              <PlusIcon />
            </div>
            <div class="ml-4 mr-auto">
              <div class="font-medium">
                {{ i18n.t("services.eyeservice.add_expense") }}
              </div>
            </div>
          </button>
        </div>
      </div>
      <!-- ServiceCard -->
      <div
        v-if="addExpenseActive"
        class="flex-cols items-center justify-between p-5 border-2 border-color-gray-500"
      >
        <!-- type -->
        <div class="flex items-center mb-4 capitalize">
          <label class="w-4/12 mr-4">{{
            i18n.t("services.eyeservice.date")
          }}</label>
          <input v-model="addDate" type="date" class="form-control" />
        </div>
        <!-- description -->
        <div class="flex items-center mb-4 capitalize">
          <label class="w-4/12 mr-4">{{
            i18n.t("services.eyeservice.description")
          }}</label>
          <input v-model="addDescription" type="text" class="form-control" />
        </div>
        <!-- amount -->
        <div class="flex items-center mb-4 capitalize">
          <label class="w-4/12 mr-4">{{
            i18n.t("services.eyeservice.amount")
          }}</label>
          <input v-model="addAmount" type="text" class="form-control" />
        </div>

        <!-- save -->
        <div class="flex justify-end">
          <button class="btn btn-secondary mr-2" @click.stop="cancelForm()">
            {{ i18n.t("general.cancel") }}
          </button>

          <button class="btn btn-primary" @click.stop="saveExpense()">
            <loading-icon
              v-if="loading"
              icon="rings"
              color="white"
              class="mr-2"
            />
            {{ i18n.t("general.save") }}
          </button>
        </div>
      </div>
      <div v-if="data" class="box">
        <template v-if="data.total_expenses || data.last_expense">
          <div
            v-if="data.total_expenses"
            class="report-box cursor-pointer mb-8"
          >
            <div class="box p-5">
              <div class="flex justify-around">
                <VehicleParameter
                  :active="data.total_expenses !== null"
                  :name="i18n.t('services.eyeservice.total_expenses.name')"
                  icon="DollarSignIcon"
                  :value="data.total_expenses"
                  :format="helper.format_currency"
                />
                <VehicleParameter
                  :active="data.last_expense !== null"
                  :name="i18n.t('services.eyeservice.last_expense.name')"
                  icon="DollarSignIcon"
                  :value="data.last_expense"
                  :format="helper.format_currency"
                />
              </div>
            </div>
          </div>
        </template>
        <div v-else class="font-light text-xl text-gray-500">
          {{ i18n.t("general.no-results") }}
        </div>
      </div>
      <div v-else class="flex justify-center py-6">
        <loading-icon icon="rings" class="w-20 h-20" />
      </div>
      <div class="py-6">
        <div class="text-lg capitalize mb-4">
          {{ i18n.t("services.eyeservice.service_history") }}
        </div>
        <div v-if="!loadingHistory" class="max-h-80 overflow-y-auto">
          <div v-if="historyExpenses">
            <div v-for="h in historyExpenses" :key="h.id">
              <div class="intro-y">
                <div
                  class="box px-4 py-4 mb-3 flex items-center cursor-pointer mx-2 shadow-md hover:shadow-lg hover:bg-gray-200"
                >
                  <div
                    class="w-10 h-10 flex items-center justify-center image-cover rounded-md overflow-hidden"
                  >
                    <DollarSignIcon />
                  </div>
                  <div class="ml-4 mr-auto">
                    <div class="font-medium">
                      {{ dayjs(h.message_datetime).format("DD/MM/YYYY") }}
                    </div>
                    <div class="text-gray-600 text-xs mt-0.5">
                      {{
                        i18n.t("services.eyeservice.type." + h.type + ".date")
                      }}
                    </div>
                  </div>
                  <div class="flex flex-col items-center mr-3">
                    <div
                      class="py-1 px-2 rounded-full text-xs bg-theme-10 text-white cursor-pointer font-medium"
                    >
                      {{
                        i18n.t("services.eyeservice.type." + h.type + ".name")
                      }}
                    </div>
                    <div v-if="h.data != null" class="mt-2">
                      {{ h.data }}
                      {{
                        i18n.t("services.eyeservice.type." + h.type + ".unit")
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="font-light text-xl text-gray-500">
            {{ i18n.t("general.no-results") }}
          </div>
        </div>
        <loading-icon v-else icon="rings" class="w-20 h-20" />
      </div>
    </div>
    <div v-show="activeTab === 'config'" class="">
      <div
        class="flex-cols items-center justify-between p-5 border-2 border-color-gray-500"
      >
        <div v-if="has_eyeservice_maintenance">
          <h3 class="text-lg mb-3">
            {{ i18n.t("services.eyeservice.maintenance_config") }}
          </h3>
          <!-- type -->
          <div class="flex items-center mb-4">
            <label class="w-4/12 mr-4">{{
              i18n.t("services.eyeservice.time_config")
            }}</label>
            <select v-model="maintenance_time_config" class="form-select">
              <option value="">
                {{ i18n.t("services.eyeservice.no_conf") }}
              </option>
              <option v-for="i in 36 / 3" :key="i" :value="i * 3">
                {{ i18n.t("services.eyeservice.months", { months: i * 3 }) }}
              </option>
            </select>
          </div>
          <!-- time_config -->
          <div
            v-if="vehicle.vehicle_type != 'P'"
            class="flex items-center mb-4 capitalize"
          >
            <label class="w-4/12 mr-4">{{
              i18n.t("services.eyeservice.km_config")
            }}</label>
            <select v-model="maintenance_km_config" class="form-select">
              <option value="">
                {{ i18n.t("services.eyeservice.no_conf") }}
              </option>
              <option v-for="i in 100000 / 5000" :key="i" :value="i * 5000">
                {{ i18n.t("services.eyeservice.km", { km: i * 5000 }) }}
              </option>
            </select>
          </div>
          <!-- time_config -->
          <div class="flex items-center mb-4 capitalize">
            <label class="w-4/12 mr-4">{{
              i18n.t("services.eyeservice.hours_config")
            }}</label>
            <select v-model="maintenance_hours_config" class="form-select">
              <option value="">
                {{ i18n.t("services.eyeservice.no_conf") }}
              </option>
              <option v-for="i in 5000 / 250" :key="i" :value="i * 250">
                {{ i18n.t("services.eyeservice.hours", { hours: i * 250 }) }}
              </option>
            </select>
          </div>
        </div>
        <div v-if="has_eyeservice_fridge">
          <h3 class="text-lg mb-3">
            {{ i18n.t("services.eyeservice.fridge_config") }}
          </h3>
          <!-- type -->
          <div class="flex items-center mb-4">
            <label class="w-4/12 mr-4">{{
              i18n.t("services.eyeservice.time_config")
            }}</label>
            <select v-model="fridge_time_config" class="form-select">
              <option value="">
                {{ i18n.t("services.eyeservice.no_conf") }}
              </option>
              <option v-for="i in 36 / 3" :key="i" :value="i * 3">
                {{ i18n.t("services.eyeservice.months", { months: i * 3 }) }}
              </option>
            </select>
          </div>
          <!-- time_config -->
          <div class="flex items-center mb-4 capitalize">
            <label class="w-4/12 mr-4">{{
              i18n.t("services.eyeservice.hours_config")
            }}</label>
            <select v-model="fridge_hours_config" class="form-select">
              <option value="">
                {{ i18n.t("services.eyeservice.no_conf") }}
              </option>
              <option v-for="i in 5000 / 250" :key="i" :value="i * 250">
                {{ i18n.t("services.eyeservice.hours", { hours: i * 250 }) }}
              </option>
            </select>
          </div>
        </div>
        <div v-if="has_eyeservice_recorder">
          <h3 class="text-lg mb-3">
            {{ i18n.t("services.eyeservice.recorder_config") }}
          </h3>
          <div class="flex items-center mb-4">
            <label class="w-4/12 mr-4">{{
              i18n.t("services.eyeservice.time_config")
            }}</label>
            <select v-model="recorder_time_config" class="form-select">
              <option value="">
                {{ i18n.t("services.eyeservice.no_conf") }}
              </option>
              <option v-for="i in 36 / 3" :key="i" :value="i * 3">
                {{ i18n.t("services.eyeservice.months", { months: i * 3 }) }}
              </option>
            </select>
          </div>
        </div>
        <div v-if="has_eyeservice_fgas">
          <h3 class="text-lg mb-3">
            {{ i18n.t("services.eyeservice.fgas_config") }}
          </h3>
          <div class="flex items-center mb-4">
            <label class="w-4/12 mr-4">{{
              i18n.t("services.eyeservice.time_config")
            }}</label>
            <select v-model="fgas_time_config" class="form-select">
              <option value="">
                {{ i18n.t("services.eyeservice.no_conf") }}
              </option>
              <option v-for="i in 36 / 3" :key="i" :value="i * 3">
                {{ i18n.t("services.eyeservice.months", { months: i * 3 }) }}
              </option>
            </select>
          </div>
        </div>
        <!-- save -->
        <div class="flex justify-end">
          <button class="btn btn-secondary mr-2" @click.stop="cancelForm()">
            {{ i18n.t("general.cancel") }}
          </button>

          <button class="btn btn-primary" @click.stop="save_config()">
            <loading-icon
              v-if="loading"
              icon="rings"
              color="white"
              class="mr-2"
            />
            {{ i18n.t("general.save") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { watch, ref, computed, reactive, onUnmounted, onMounted } from "vue";
import { useI18n } from "vue3-i18n";
import { useStore } from "vuex";
import dayjs from "dayjs";
import EventBus from "@/libs/event-bus";
import toast from "@/services/toast";
import { helper } from "@/utils/helper";
import VehicleParameter from "@/components/vehicle/VehicleParameter.vue";

export default {
  components: { VehicleParameter },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const store = useStore();
    const vehicle = computed(() => store.getters["globalMap/selectedVehicle"]);
    const power_data = reactive(ref(null));
    const geo_data = reactive(ref(null));
    const data = reactive(ref(null));
    const history = reactive(ref(null));
    const historyExpenses = reactive(ref(null));
    const loadingHistory = ref(false);
    const loading = ref(false);
    const addActive = ref(false);
    const addExpenseActive = ref(false);
    const addType = ref(0);
    const subtypes = ref([0, 1, 2, 3, 4, 5, 6, 7]);
    const fridge_time_config = ref(12);
    const fridge_hours_config = ref(1500);
    const recorder_time_config = ref(12);
    const fgas_time_config = ref(12);
    const maintenance_hours_config = ref("");
    const maintenance_km_config = ref("");
    const maintenance_time_config = ref("");
    const addDate = ref(dayjs().format("YYYY-MM-DD"));
    const addHours = ref(null);
    const addKm = ref(null);
    const addAmount = ref(null);
    const addDescription = ref("");
    const addSubtype = ref(0);
    const has_eyecold = ref("");
    const has_eyeservice_recorder = ref("");
    const has_eyeservice_fridge = ref("");
    const has_eyeservice_fgas = ref("");
    const has_eyeservice_maintenance = ref("");
    const has_eyeservice_expenses = ref("");
    const has_eyepower = ref("");
    const has_eyegeo = ref("");

    // Tabs
    const activeTab = ref("service");
    // watch service visibility
    watch(
      () => props.active,
      () => {
        if (props.active) initService();
        else destroyService();
      },
    );
    function updateService(service) {
      if (data.value == null) return;
      data.value.service = service;

      if (history.value != null && history.value.list)
        history.value.list.unshift(service);
    }

    onMounted(() => {
      EventBus.on("vehicle:updates:service", updateService);
    });
    onUnmounted(() => {
      EventBus.off("vehicle:updates:service");
    });

    // init service
    async function initService() {
      console.log(
        "vehicle.value.active_agreements",
        vehicle.value.active_agreements,
      );
      if (vehicle.value.active_agreements) {
        has_eyeservice_recorder.value = vehicle.value.active_agreements.find(
          (o) => o.agreement_id == 21,
        );
        has_eyeservice_fridge.value = vehicle.value.active_agreements.find(
          (o) => o.agreement_id == 22,
        );
        has_eyeservice_fgas.value = vehicle.value.active_agreements.find(
          (o) => o.agreement_id == 23,
        );
        has_eyeservice_maintenance.value = vehicle.value.active_agreements.find(
          (o) => o.agreement_id == 28,
        );
        has_eyeservice_expenses.value = vehicle.value.active_agreements.find(
          (o) => o.agreement_id == 29,
        );
        has_eyecold.value = vehicle.value.active_agreements.find(
          (o) => o.agreement_id == 3,
        );
        has_eyepower.value = vehicle.value.active_agreements.find(
          (o) => o.agreement_id == 27,
        );
        has_eyegeo.value = vehicle.value.active_agreements.find(
          (o) => o.agreement_id == 2,
        );
      }

      recorder_time_config.value =
        vehicle.value.configurations.recorder_maintenance_time || 12;
      fgas_time_config.value =
        vehicle.value.configurations.fgas_maintenance_time || 12;
      fridge_time_config.value =
        vehicle.value.configurations.fridge_maintenance_time || 12;
      fridge_hours_config.value =
        vehicle.value.configurations.fridge_maintenance_hours || 1500;
      maintenance_time_config.value =
        vehicle.value.configurations.general_maintenance_time || "";
      maintenance_hours_config.value =
        vehicle.value.configurations.general_maintenance_hours || "";
      maintenance_km_config.value =
        vehicle.value.configurations.general_maintenance_km || "";

      const dataResult = await store.dispatch(
        "eyeservice/getVehicleData",
        vehicle.value.id,
      );
      data.value = dataResult.validated;

      if (has_eyepower.value) {
        const power_dataResult = await store.dispatch(
          "eyepower/getVehicleData",
          vehicle.value.id,
        );
        power_data.value = power_dataResult.validated;
      }

      if (has_eyegeo.value) {
        const geo_dataResult = await store.dispatch(
          "eyegeo/getVehicleData",
          vehicle.value.id,
        );
        geo_data.value = geo_dataResult.validated;
      }
      getHistory();

      if (
        !has_eyeservice_recorder.value &&
        !has_eyeservice_fridge.value &&
        !has_eyeservice_fgas.value
      )
        addType.value = 3;
    }

    async function addNewExpense() {
      addExpenseActive.value = true;
    }

    async function cancelForm() {
      addActive.value = false;
      addExpenseActive.value = false;
    }

    async function addNewMaintenance() {
      addActive.value = true;
    }

    // destroy service
    function destroyService() {}
    // get history
    async function getHistory() {
      if (!props.active) return;
      loadingHistory.value = true;

      const historyResult = await store.dispatch(
        "eyeservice/getVehicleHistory",
        {
          vehicleId: vehicle.value.id,
          dateFrom: null,
          dateTo: null,
        },
      );
      if (historyResult.validated.list.length) {
        history.value = historyResult.validated.list.filter((h) => h.type != 4);
        historyExpenses.value = historyResult.validated.list.filter(
          (h) => h.type == 4,
        );
      }
      loadingHistory.value = false;
    }

    // save
    async function save() {
      loading.value = true;
      let result = null;

      result = await store.dispatch("eyeservice/addMaintenance", {
        vehicleId: vehicle.value.id,
        type: addType.value,
        date: addDate.value,
        subtype: addSubtype.value,
        hours: addType.value == 0 || addType.value == 3 ? addHours.value : null,
        km: addType.value == 3 ? addKm.value : null,
      });
      if (!result.success) {
        toast.error(result.errorMessage);
      }

      if (result.success) {
        addActive.value = false;

        const dataResult = await store.dispatch(
          "eyeservice/getVehicleData",
          vehicle.value.id,
        );
        data.value = dataResult.validated;
        getHistory();
      }

      loading.value = false;
    }

    async function saveExpense() {
      loading.value = true;
      let result = null;

      result = await store.dispatch("eyeservice/addMaintenance", {
        vehicleId: vehicle.value.id,
        type: 4,
        date: addDate.value,
        description: addDescription.value,
        data: addAmount.value,
      });
      if (!result.success) {
        toast.error(result.errorMessage);
      }

      if (result.success) {
        addActive.value = false;

        const dataResult = await store.dispatch(
          "eyeservice/getVehicleData",
          vehicle.value.id,
        );
        data.value = dataResult.validated;
        getHistory();
      }

      loading.value = false;
    }

    async function save_config() {
      loading.value = true;
      let result = null;

      result = await store.dispatch("eyeservice/configMaintenance", {
        vehicleId: vehicle.value.id,
        fridge_time_config: fridge_time_config.value,
        fridge_hours_config: fridge_hours_config.value,
        maintenance_time_config: maintenance_time_config.value,
        maintenance_km_config: maintenance_km_config.value,
        maintenance_hours_config: maintenance_hours_config.value,
        fgas_time_config: fgas_time_config.value,
        recorder_time_config: recorder_time_config.value,
      });
      if (!result.success) {
        toast.error(result.errorMessage);
      }

      if (result.success) {
        const dataResult = await store.dispatch(
          "eyeservice/getVehicleData",
          vehicle.value.id,
        );
        data.value = dataResult.validated;
      }

      loading.value = false;
    }

    return {
      i18n: useI18n(),
      dayjs,
      vehicle,
      activeTab,
      data,
      geo_data,
      power_data,
      fridge_time_config,
      fridge_hours_config,
      maintenance_time_config,
      maintenance_km_config,
      maintenance_hours_config,
      recorder_time_config,
      fgas_time_config,
      history,
      addNewMaintenance,
      loadingHistory,
      loading,
      subtypes,
      addActive,
      addExpenseActive,
      addNewExpense,
      cancelForm,
      addType,
      addDate,
      addAmount,
      addHours,
      addKm,
      addDescription,
      addSubtype,
      save,
      helper,
      saveExpense,
      save_config,
      has_eyeservice_maintenance,
      has_eyeservice_recorder,
      has_eyeservice_fgas,
      has_eyeservice_fridge,
      has_eyeservice_expenses,
    };
  },
};
</script>

<style></style>
