import axios from "../libs/axios";
import { handleSuccess, handleError } from "./response-handler";
import dayjs from "dayjs";
const namespace = "services/eyeservice";
const api = {
  getDashboard: async function () {
    try {
      const response = await axios.get(`${namespace}`);
      return handleSuccess({ namespace, action: "getDashboard" }, response);
    } catch (error) {
      return handleError({ namespace, action: "getDashboard" }, error.response);
    }
  },
  getVehicleData: async function (id) {
    try {
      const response = await axios.get(`${namespace}/${id}`);
      return handleSuccess({ namespace, action: "getVehicleData" }, response);
    } catch (error) {
      return handleError(
        { namespace, action: "getVehicleData" },
        error.response,
      );
    }
  },
  addMaintenance: async function (id, type, date, data, hours, km) {
    try {
      const response = await axios.put(`${namespace}/${id}/add`, {
        type: type,
        date: date,
        data: data,
        hours: hours,
        km: km,
      });
      return handleSuccess({ namespace, action: "addMaintenance" }, response);
    } catch (error) {
      return handleError(
        { namespace, action: "addMaintenance" },
        error.response,
      );
    }
  },
  configMaintenance: async function (
    id,
    fridge_time_config,
    fridge_hours_config,
    maintenance_time_config,
    maintenance_km_config,
    maintenance_hours_config,
    fgas_time_config,
    recorder_time_config,
  ) {
    try {
      const response = await axios.put(`${namespace}/${id}/config`, {
        fridge_time_config: fridge_time_config,
        fridge_hours_config: fridge_hours_config,
        maintenance_time_config: maintenance_time_config,
        maintenance_km_config: maintenance_km_config,
        maintenance_hours_config: maintenance_hours_config,
        fgas_time_config: fgas_time_config,
        recorder_time_config: recorder_time_config,
      });
      return handleSuccess(
        { namespace, action: "configMaintenance" },
        response,
      );
    } catch (error) {
      return handleError(
        { namespace, action: "configMaintenance" },
        error.response,
      );
    }
  },
  getVehicleHistory: async function (id, from, to) {
    try {
      const response = await axios.get(`${namespace}/${id}/history`, {
        params: {
          period_from: dayjs(from).format(),
          period_to: dayjs(to).format(),
        },
      });
      return handleSuccess(
        { namespace, action: "getVehicleHistory" },
        response,
      );
    } catch (error) {
      return handleError(
        { namespace, action: "getVehicleHistory" },
        error.response,
      );
    }
  },
};
export { api as default };
