<template>
  <div
    class="box p-4 mb-3 cursor-pointer"
    :class="{ 'ring-2 ring-reyesol-blue': active }"
    @click="select"
  >
    <!-- card -->
    <div class="flex items-center justify-between">
      <div class="grid grid-cols-4 justify-between w-full">
        <div class="ml-4 mr-auto">
          <div class="font-medium">
            <template v-if="formActive && !activity.id">
              <VueMultiselect
                v-model="activity.driver_id"
                :options="
                  drivers.map((d) => ({
                    name: d.name + ' ' + d.surname,
                    value: d.id,
                  }))
                "
                :close-on-select="true"
                :placeholder="placeholderSelect"
                label="name"
                track-by="name"
                :disabled="sending"
                class="w-full"
              />
            </template>
            <template v-else-if="activity.driver">
              {{ activity.driver.name }} {{ activity.driver.surname }}
            </template>
          </div>
          <div class="text-gray-600 text-xs mt-0.5">Autista</div>
        </div>
        <div class="ml-4">
          <div class="font-medium">
            <template v-if="formActive && !activity.id">
              <VueMultiselect
                v-model="activity.vehicle_id"
                :options="
                  vehicles.map((v) => ({
                    name: v.plate + (v.unit_code ? ' ' + v.unit_code : ''),
                    value: v.id,
                  }))
                "
                :close-on-select="true"
                :placeholder="placeholderSelect"
                label="name"
                track-by="name"
                :disabled="sending"
                class="w-full"
              />
            </template>
            <template v-else-if="activity.vehicle">
              {{ activity.vehicle.plate }} {{ activity.vehicle.unit_code }}
            </template>
          </div>
          <div class="text-gray-600 text-xs mt-0.5">Veicolo</div>
        </div>
        <div
          class="ml-4 mr-auto"
          :class="{ 'col-span-2': formActive && !activity.id }"
        >
          <div class="font-medium">
            <template v-if="formActive && !activity.id">
              <Datepicker
                v-model="activity.period_from"
                multi-calendars="true"
                format="dd/MM/yyyy HH:mm"
                auto-apply
                :close-on-auto-apply="false"
                show-week-numbers
                locale="it-IT"
                :clearable="false"
                class="w-full"
              />
            </template>
            <template v-else>
              {{ dayjs(activity.period_from).format("DD/MM/YYYY") }}
            </template>
          </div>
          <div class="text-gray-600 text-xs mt-0.5">Inizio guida</div>
        </div>
        <div v-if="activity.id" class="ml-4 mr-auto">
          <div v-if="!formActive && activity.period_to" class="font-medium">
            {{ dayjs(activity.period_to).format("DD/MM/YYYY") }}
          </div>
          <div v-else class="font-medium text-reyesol-red">
            <template v-if="formActive && activity.id">
              <Datepicker
                v-model="activity.period_to"
                multi-calendars="true"
                format="dd/MM/yyyy HH:mm"
                auto-apply
                :close-on-auto-apply="false"
                show-week-numbers
                :max-date="new Date()"
                :min-date="activity?.period_from"
                locale="it-IT"
                :clearable="false"
                class="w-full"
              />
            </template>
            <template v-else>In corso</template>
          </div>
          <div class="text-gray-600 text-xs mt-0.5">Fine guida</div>
        </div>
      </div>
      <div class="flex self-start justify-end">
        <button
          v-if="!activity.period_to && !formActive"
          class="btn btn-secondary p-1 mr-1"
          @click.stop="formActive = true"
        >
          <EditIcon class="w-5 h-5" />
        </button>
        <button
          v-if="formActive && !activity.id"
          class="btn btn-primary"
          @click.stop="add()"
        >
          <loading-icon
            v-if="loading"
            icon="rings"
            color="white"
            class="mr-2"
          />
          {{ i18n.t("general.add") }}
        </button>
        <button
          v-if="formActive && activity.id"
          class="btn btn-primary"
          @click.stop="save()"
        >
          <loading-icon
            v-if="loading"
            icon="rings"
            color="white"
            class="mr-2"
          />
          {{ i18n.t("general.save") }}
        </button>
        <button
          v-if="formActive"
          class="btn btn-secondary ml-2"
          @click.stop="cancelForm()"
        >
          {{ i18n.t("general.cancel") }}
        </button>

        <button
          v-if="!formActive"
          class="btn btn-danger p-1"
          @click.stop="deleteEntity()"
        >
          <TrashIcon class="w-5 h-5" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useI18n } from "vue3-i18n";
import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { helper } from "@/utils/helper";
import toast from "@/services/toast";
import EventBus from "@/libs/event-bus";
import dayjs from "dayjs";
import Datepicker from "@vuepic/vue-datepicker";

export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    activity: {
      type: Object,
      default: null,
    },
    drivers: {
      type: Array,
      default: null,
    },
    vehicles: {
      type: Array,
      default: null,
    },
  },
  emits: ["updated", "selected", "canceled"],
  components: { Datepicker },

  setup(props, { emit }) {
    const i18n = useI18n();
    const store = useStore();
    const minDate = ref(null);

    // select
    function select() {
      if (!formActive.value) emit("selected", props.activity);
    }

    // form
    const name_error = ref(false);
    const name_error_array = ref([]);
    const formActive = ref(false);
    if (props.activity && !props.activity.id) showForm();

    function showForm() {
      formActive.value = true;
    }

    function cancelForm() {
      formActive.value = false;
      emit("canceled", props.entity);
    }

    // validation
    const rules = {
      vechile_id: { required },
      driver_id: { required },
      period_from: {},
      period_to: {},
      note: {},
    };

    const v$ = useVuelidate(rules, props.activity);

    // save
    const loading = ref(false);

    async function add() {
      loading.value = true;

      console.log("driver_activities/setActivity", props.activity.vehicle_id);
      const result = await store.dispatch("driver_activities/setActivity", {
        vehicleId:
          typeof props.activity.vehicle_id === "object"
            ? props.activity.vehicle_id.value
            : props.activity.vehicle_id,
        driverId:
          typeof props.activity.driver_id === "object"
            ? props.activity.driver_id.value
            : props.activity.driver_id,
        from: dayjs(props.activity.period_from).format(),
      });
      if (!result.success) {
        toast.error(result.errorMessage);
      }

      emit("updated", props.activity);

      formActive.value = false;

      loading.value = false;
    }

    async function save() {
      loading.value = true;

      const result = await store.dispatch("driver_activities/setActivity", {
        vehicleId:
          typeof props.activity.vehicle_id === "object"
            ? props.activity.vehicle_id.value
            : props.activity.vehicle_id,
        to: dayjs(props.activity.period_to).format(),
      });
      if (!result.success) {
        toast.error(result.errorMessage);
      }

      emit("updated", props.activity);

      formActive.value = false;

      loading.value = false;
    }

    // delete
    async function deleteEntity() {
      EventBus.emit("confirm-dialog:show", {
        title: `${i18n.t("drivers.confirm-delete-title")}?`,
        text: i18n.t("drivers.confirm-delete-text"),
        callback: async (confirm) => {
          if (confirm) {
            const result = await store.dispatch(
              "driver_activities/delete",
              props.activity.id,
            );
            if (result.success) emit("updated", result.validated);
          }
        },
      });
    }

    // utils
    const colors = store.getters["main/colors"];

    return {
      i18n,
      name_error,
      name_error_array,
      // select
      select,

      // form
      v$,
      formActive,
      showForm,
      cancelForm,
      minDate,
      // save
      loading,
      add,
      save,

      // delete
      deleteEntity,

      // utils
      h: helper,
      colors,
      dayjs,
    };
  },
};
</script>

<style></style>
