<template>
  <div class="relative inline-flex w-fit text-center">
    <slot></slot>
    <div v-if="alarm || warning" class="badge-reyesol-alarm-counter">
      <span v-if="warning" class="warning">{{ warning }}</span>
      <span v-if="alarm" class="alarm">{{ alarm }}</span>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    service: {
      type: String,
      default: "",
    },
    warning: {
      type: Number,
      default: 0,
    },
    alarm: {
      type: Number,
      default: 0,
    },
  },

  setup() {
    const none = "";
    return {
      none,
    };
  },
});
</script>
