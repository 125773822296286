<template>
  <div
    class="box p-4 mb-3 cursor-pointer"
    :class="{ 'ring-2 ring-reyesol-blue': active }"
    @click="select"
  >
    <!-- card -->
    <div v-if="!formActive" class="flex items-center justify-between">
      <div class="flex">
        <div class="rounded-md mr-2">
          <SearchIcon v-if="active" class="w-10 h-10" />
        </div>
        <div class="flex items-center">
          <div class="flex-1 mr-auto">
            <div class="font-medium">{{ type.name }}</div>
            <div class="ml-2 text-gray-600 text-xs mt-0.5">
              <div v-if="type.repeat_after_hour != null">
                {{
                  i18n.t("services.eyeservice.hours", {
                    hours: type.repeat_after_hour,
                  })
                }}
              </div>
              <div v-if="type.repeat_after_km != null">
                {{
                  i18n.t("services.eyeservice.km", {
                    km: type.repeat_after_km,
                  })
                }}
              </div>
              <div v-if="type.repeat_after_period != null">
                {{
                  i18n.t("services.eyeservice.months", {
                    months: type.repeat_after_period,
                  })
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex">
        <button class="btn btn-secondary p-1 mr-1" @click.stop="showForm">
          <EditIcon class="w-5 h-5" />
        </button>
        <button class="btn btn-danger p-1" @click.stop="deleteEntity(type)">
          <TrashIcon class="w-5 h-5" />
        </button>
      </div>
    </div>

    <!-- form -->
    <div v-else>
      <!-- name -->
      <div class="flex items-center mb-4">
        <label class="w-2/12 mr-4 capitalize">{{ i18n.t("types.name") }}</label>
        <validated-input
          class="w-10/12"
          :has-error="v$.name.$error"
          :errors="v$.name.$errors"
        >
          <input v-model="type.name" type="text" class="form-control" />
        </validated-input>
      </div>

      <!-- repeat_after_km -->
      <div class="flex items-center mb-4 capitalize">
        <label class="w-2/12 mr-4">{{ i18n.t("types.repeat_after_km") }}</label>
        <validated-input
          class="w-10/12"
          :has-error="v$.repeat_after_km.$error"
          :errors="v$.repeat_after_km.$errors"
        >
          <select v-model="type.repeat_after_km" class="form-select">
            <option :value="null">
              {{ i18n.t("services.eyeservice.no_conf") }}
            </option>
            <option v-for="i in 100000 / 5000" :key="i" :value="i * 5000">
              {{ i18n.t("services.eyeservice.km", { km: i * 5000 }) }}
            </option>
          </select>
        </validated-input>
      </div>

      <!-- repeat_after_hour -->
      <div class="flex items-center mb-4 capitalize">
        <label class="w-2/12 mr-4">{{
          i18n.t("types.repeat_after_hour")
        }}</label>
        <validated-input
          class="w-10/12"
          :has-error="v$.repeat_after_hour.$error"
          :errors="v$.repeat_after_hour.$errors"
        >
          <select v-model="type.repeat_after_hour" class="form-select">
            <option :value="null">
              {{ i18n.t("services.eyeservice.no_conf") }}
            </option>
            <option v-for="i in 5000 / 250" :key="i" :value="i * 250">
              {{ i18n.t("services.eyeservice.hours", { hours: i * 250 }) }}
            </option>
          </select>
        </validated-input>
      </div>

      <!-- repeat_after_period -->
      <div class="flex items-center mb-4 capitalize">
        <label class="w-2/12 mr-4">{{
          i18n.t("types.repeat_after_period")
        }}</label>
        <validated-input
          class="w-10/12"
          :has-error="v$.repeat_after_period.$error"
          :errors="v$.repeat_after_period.$errors"
        >
          <select v-model="type.repeat_after_period" class="form-select">
            <option :value="null">
              {{ i18n.t("services.eyeservice.no_conf") }}
            </option>
            <option v-for="i in 36 / 3" :key="i" :value="i * 3">
              {{ i18n.t("services.eyeservice.months", { months: i * 3 }) }}
            </option>
          </select>
        </validated-input>
      </div>

      <!-- note -->
      <div class="flex items-center mb-4 capitalize">
        <label class="w-2/12 mr-4">{{ i18n.t("types.note") }}</label>
        <validated-input
          class="w-10/12"
          :has-error="v$.note.$error"
          :errors="v$.note.$errors"
        >
          <input v-model="type.note" type="text" class="form-control" />
        </validated-input>
      </div>

      <!-- save -->
      <div class="flex justify-end">
        <button class="btn btn-secondary mr-2" @click.stop="cancelForm()">
          {{ i18n.t("general.cancel") }}
        </button>

        <button class="btn btn-primary" @click.stop="save()">
          <loading-icon
            v-if="loading"
            icon="rings"
            color="white"
            class="mr-2"
          />
          {{ i18n.t("general.save") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useI18n } from "vue3-i18n";
import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { helper } from "@/utils/helper";
import toast from "@/services/toast";
import EventBus from "@/libs/event-bus";

export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    type: {
      type: Object,
      required: true,
    },
  },

  emits: ["updated", "selected", "canceled"],

  setup(props, { emit }) {
    const i18n = useI18n();
    const store = useStore();

    // select
    function select() {
      if (!formActive.value) emit("selected", props.type);
    }

    // form
    const name_error = ref(false);
    const name_error_array = ref([]);
    const formActive = ref(false);
    if (!props.type.id) showForm();

    function showForm() {
      formActive.value = true;
    }

    function cancelForm() {
      formActive.value = false;
      emit("canceled", props.entity);
    }

    // validation
    const rules = {
      name: { required },
      repeat_after_km: {},
      repeat_after_hour: {},
      repeat_after_period: {},
      note: {},
    };

    const v$ = useVuelidate(rules, props.type);

    // save
    const loading = ref(false);
    async function save() {
      loading.value = true;
      v$.value.$touch();
      if (v$.value.$error) {
        loading.value = false;
        return;
      }

      let result = null;

      if ("id" in props.type) {
        result = await store.dispatch("types/update", props.type);
      } else {
        result = await store.dispatch("types/store", props.type);
      }

      if (!result.success) {
        if (result.data["name"] != undefined) {
          name_error.value = true;
          name_error_array.value = result.data["name"];
        }

        for (const key in result.data) {
          toast.error(result.data[key]);
        }
        toast.error(result.errorMessage);
      }

      if (result.success) {
        formActive.value = false;
        emit("updated", result.validated);
      }

      loading.value = false;
    }

    // delete
    async function deleteEntity(type) {
      EventBus.emit("confirm-dialog:show", {
        title: `${i18n.t("types.confirm-delete-title")} ${type.code}?`,
        text: i18n.t("types.confirm-delete-text"),
        callback: async (confirm) => {
          if (confirm) {
            const result = await store.dispatch("types/delete", props.type.id);
            if (result.success) emit("updated", result.validated);
          }
        },
      });
    }

    // utils
    const colors = store.getters["main/colors"];

    return {
      i18n,
      name_error,
      name_error_array,
      // select
      select,

      // form
      v$,
      formActive,
      showForm,
      cancelForm,

      // save
      loading,
      save,

      // delete
      deleteEntity,

      // utils
      h: helper,
      colors,
    };
  },
};
</script>

<style></style>
