<template>
  <div class="mt-2 box flex flex-col cursor-pointer">
    <div class="flex items-center border-b-2 border-theme-7 mb-2">
      <div class="pl-4">
        <ShieldIcon class="mr-1" />
        <span
          @click="handleClick"
          class="font-bold flex text-base hover:text-reyesol-blue"
          >{{ hub.name }}
        </span>
        <div class="text-gray-600 font-thin text-xs">
          {{ hub.address || "Unknown address" }}
          {{ hub.city || "Unknown city" }}
          {{ hub.zip_code || "Unknown zip code" }}
        </div>
      </div>
      <div class="ml-auto mr-5 text-xs">
        <div class="px-2 rounded-full bg-gray-400 text-gray-600">
          {{ hub.code }}
        </div>
      </div>
    </div>
    <div class="pl-4 flex-grow flex items-center">
      <TruckIcon class="mr-1" />
      <div v-if="hubs.length == 0">{{ i18n.t("hub.empty") }}</div>
      <div v-else>{{ hubs.data.length }} {{ i18n.t("hub.vehicles") }}</div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue3-i18n";
import { useStore } from "vuex";
import { ref } from "vue";
import { ShieldIcon, TruckIcon } from "@zhuowenli/vue-feather-icons";
import EventBus from "@/libs/event-bus";
import dayjs from "dayjs";

export default {
  components: {
    ShieldIcon,
    TruckIcon,
  },
  props: {
    hub: {
      type: Object,
      default: null,
    },
  },
  emits: ["done"],
  setup(props) {
    const i18n = useI18n();
    const hubs = ref([]);
    const store = useStore();

    async function get_vehicle() {
      hubs.value = await store.dispatch("hubs/getVehicles", props.hub.id);
    }

    function handleClick() {
      EventBus.emit("hub:select", props.hub);
    }
    get_vehicle();
    return {
      dayjs,
      i18n,
      handleClick,
      hubs,
    };
  },
};
</script>

<style></style>
