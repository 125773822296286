<template>
  <div v-if="vehicle" id="service-eyeshare">
    <div
      v-if="customerAgreements.length && customers.length && agreements.length"
    >
      <!-- new agreement -->
      <button
        class="btn btn-primary w-full mb-3"
        @click="addNewCustomerAgreement"
      >
        <div class="w-10 h-10 rounded-md flex items-center justify-center">
          <PlusIcon />
        </div>
        <div class="ml-4 mr-auto">
          <div class="font-medium">{{ i18n.t("services.eyeshare.add") }}</div>
        </div>
      </button>

      <!-- customerAgreements -->
      <AgreementCard
        v-for="a in customerAgreements"
        :key="a.id"
        :agreement="a"
        :agreements="agreements"
        :user-agreements="userAgreements"
        :customers="customers"
        @updated="updateCustomerAgreements"
        @canceled="handleCustomerAgreementCancel"
      ></AgreementCard>
    </div>
    <div v-else class="font-light text-xl text-gray-500">
      {{ i18n.t("services.eyeshare.no-results") }},
      <a
        href="javascript:;"
        class="text-theme-1"
        @click="addNewCustomerAgreement"
        >{{ i18n.t("services.eyeshare.create-first") }}</a
      >
    </div>
  </div>
</template>

<script>
import { watch, ref, computed } from "vue";
import { useI18n } from "vue3-i18n";
import { useStore } from "vuex";
import dayjs from "dayjs";
import AgreementCard from "../agreements/AgreementCard.vue";

export default {
  components: { AgreementCard },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const i18n = useI18n();
    const store = useStore();
    const user = store.getters["auth/user"];
    const vehicle = computed(() => store.getters["globalMap/selectedVehicle"]);

    // watch service visibility
    watch(
      () => props.active,
      () => {
        if (props.active) initService();
        else destroyService();
      },
    );

    // init service
    async function initService() {
      fetchCustomers();
      fetchAgreements();
      getUserAgreements(vehicle.value);
      getCustomerAgreements(vehicle.value);
    }

    // destroy service
    function destroyService() {}

    // new agreement
    function addNewCustomerAgreement() {
      getUserAgreements(vehicle.value);
      if (customerAgreements.value.find((ca) => ca.id === undefined)) return;
      customerAgreements.value = [
        {
          vehicle_id: vehicle.value.id,
          customer_id: null,
          agreement_ids: [],
          active_from: null,
          active_to: null,
        },
        ...customerAgreements.value,
      ];
    }

    // user agreements
    const userAgreements = ref([]);

    function getUserAgreements(vehicle) {
      userAgreements.value = vehicle.active_agreements.filter(
        (a) =>
          a.customer_id == user.customer_id &&
          a.code !== "system" &&
          a.code !== "eyeshare",
      );
    }

    // customer agreements
    const customerAgreements = ref([]);

    function getCustomerAgreements(vehicle) {
      customerAgreements.value = vehicle.active_agreements.filter(
        (a) => a.customer_id !== user.customer_id,
      );
    }

    async function updateCustomerAgreements() {
      await store.dispatch("globalMap/getVehicle", vehicle.value.id);

      getUserAgreements(vehicle.value);
      getCustomerAgreements(vehicle.value);
    }

    function handleCustomerAgreementCancel() {
      customerAgreements.value = customerAgreements.value.filter((a) => a.id);
    }

    // agreements
    const agreements = ref([]);
    async function fetchAgreements() {
      const result = await store.dispatch("agreements/getCollection");
      agreements.value = result.validated.filter(
        (a) => a.code != "system" && a.code !== "eyeshare",
      );
      userAgreements.value.map((a) => {
        a.code = agreements.value.find((ae) => ae.id == a.agreement_id).code;
      });
    }

    // customers
    const customers = ref([]);
    async function fetchCustomers() {
      const result = await store.dispatch("customers/getCollection");
      customers.value = result.validated;
    }

    return {
      i18n,
      dayjs,
      vehicle,
      addNewCustomerAgreement,
      updateCustomerAgreements,
      handleCustomerAgreementCancel,
      userAgreements,
      customerAgreements,
      agreements,
      customers,
    };
  },
};
</script>

<style></style>
