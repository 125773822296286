<template>
  <div
    v-if="is_fridge"
    class="flex flex-row mt-2 border border-2 rounded-full my-1 -mx-1 overflow-hidden justify-center shadow-sm"
    :class="
      overall_status == 1 ? 'bg-reyesol-fridge-on' : 'bg-reyesol-fridge-off'
    "
  >
    <Tippy
      tag="img"
      :src="require(`@/assets/images/cold.svg`)"
      class="h-5 mx-1 text-blue-900 self-center"
      :content="overall_status == 1 ? 'Frigo On' : 'Frigo Off'"
    >
    </Tippy>
    <p
      v-if="overall_status != null && overall_status == 1"
      class="hidden md:inline-block xl:hidden py-1 px-auto text-xs text-black font-medium"
    >
      ON
    </p>
    <p
      v-if="overall_status != null && overall_status == 0"
      class="hidden md:inline-block xl:hidden py-1 px-auto text-xs text-black font-medium"
    >
      OFF
    </p>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { helper } from "@/utils/helper";

import Tippy from "@/global-components/tippy/Main.vue";

export default defineComponent({
  components: {
    Tippy,
  },
  props: {
    is_fridge: {
      type: Boolean,
      default: null,
    },
    overall_status: {
      type: [Number, String],
      default: 0,
    },
  },

  setup() {
    return {
      helper,
    };
  },
});
</script>
