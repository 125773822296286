<template>
  <span>
    <Tippy
      v-if="datetime"
      :content="dayjs.utc(datetime).local().format('DD/MM/YYYY HH:mm:ss')"
    >
      {{ convertedDateAgo }}
    </Tippy>
    <Tippy v-else :content="i18n.t('services.no_data')"> - </Tippy>
  </span>
</template>
<script>
import dayjs from "dayjs";
import { ref, onMounted, onUnmounted } from "vue";

export default {
  name: "DateAgo",
  props: {
    datetime: {
      type: [String, Object],
      required: true,
    },
    locale: {
      type: String,
      default: "it",
    },
    refresh: {
      type: [Number, Boolean],
      default: 5,
    },
  },
  setup(props) {
    const convertedDateAgo = ref("");
    const intervalId = ref("");

    function reloadTime() {
      console.log("Reload time");
      if (props.datetime)
        convertedDateAgo.value = dayjs()
          .locale(props.locale)
          .to(dayjs.utc(props.datetime));
      else convertedDateAgo.value = "-";
    }

    onMounted(() => {
      reloadTime();
      if (props.refresh) {
        const refreshTime = props.refresh === true ? 60 : props.refresh;
        intervalId.value = setInterval(reloadTime, refreshTime * 1000);
      }
    });

    onUnmounted(() => {
      if (intervalId.value) clearInterval(intervalId.value);
    });
    return {
      convertedDateAgo,
      dayjs,
    };
  },
};
</script>
