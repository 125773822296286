import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { useI18n } from "vue3-i18n";

dayjs.extend(duration);

const helpers = {
  distance(lat1, lon1, lat2, lon2, unit = "K") {
    if (lat1 == lat2 && lon1 == lon2) {
      return 0;
    } else {
      const radlat1 = (Math.PI * lat1) / 180;
      const radlat2 = (Math.PI * lat2) / 180;
      const theta = lon1 - lon2;
      const radtheta = (Math.PI * theta) / 180;
      let dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = (dist * 180) / Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit == "K") {
        dist = dist * 1.609344;
      }
      if (unit == "N") {
        dist = dist * 0.8684;
      }
      return dist;
    }
  },
  cutText(text, length) {
    if (text.split(" ").length > 1) {
      const string = text.substring(0, length);
      const splitText = string.split(" ");
      splitText.pop();
      return splitText.join(" ") + "...";
    } else {
      return text;
    }
  },
  formatDate(date, format) {
    return dayjs(date).format(format);
  },
  capitalizeFirstLetter(string) {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    } else {
      return "";
    }
  },
  onlyNumber(string) {
    if (string) {
      return string.replace(/\D/g, "");
    } else {
      return "";
    }
  },
  formatCurrency(number) {
    if (number) {
      const formattedNumber = number.toString().replace(/\D/g, "");
      const rest = formattedNumber.length % 3;
      let currency = formattedNumber.substr(0, rest);
      const thousand = formattedNumber.substr(rest).match(/\d{3}/g);
      let separator;

      if (thousand) {
        separator = rest ? "." : "";
        currency += separator + thousand.join(".");
      }

      return currency;
    } else {
      return "";
    }
  },
  isset(obj) {
    if (obj !== null && obj !== undefined) {
      if (typeof obj === "object" || Array.isArray(obj)) {
        return Object.keys(obj).length;
      } else {
        return obj.toString().length;
      }
    }

    return false;
  },
  toRaw(obj) {
    return JSON.parse(JSON.stringify(obj));
  },
  randomNumbers(from, to, length) {
    const numbers = [0];
    for (let i = 1; i < length; i++) {
      numbers.push(Math.ceil(Math.random() * (from - to) + to));
    }

    return numbers;
  },
  format_temp(temp, unit = "°") {
    return (
      (temp < 0 ? "-" : "+") +
      (Math.round(Math.abs(temp) * 10) / 10).toFixed(1).padStart(4, "0") +
      unit
    );
  },
  format_km(km, unit = "km", spacing = " ") {
    return Math.round(Math.abs(km)) + spacing + unit;
  },
  format_hours(km, unit = "h", spacing = " ") {
    return Math.round(Math.abs(km)) + spacing + unit;
  },
  format_currency(amount, unit = " €") {
    return (Math.round(Math.abs(amount) * 100) / 100).toFixed(2) + unit;
  },
  format_volt(temp, unit = " V") {
    return (
      (Math.round(Math.abs(temp) * 10) / 10).toFixed(1).padStart(4, "0") + unit
    );
  },
  format_compartment_operation_mode(mode) {
    const i18n = useI18n();
    const modes = [];
    if (mode == 0) modes.push(i18n.t("services.eyecold.operation_mode.off"));
    if (mode == 1)
      modes.push(i18n.t("services.eyecold.operation_mode.heating"));
    if (mode == 2)
      modes.push(i18n.t("services.eyecold.operation_mode.cooling"));
    if (mode == 3) modes.push(i18n.t("services.eyecold.operation_mode.idle"));
    if (mode == 4)
      modes.push(i18n.t("services.eyecold.operation_mode.defrost"));
    if (mode == 5)
      modes.push(i18n.t("services.eyecold.operation_mode.pretrip"));

    return modes.join(", ");
  },
  format_compartment_operation_status(mode) {
    const i18n = useI18n();
    const modes = [];
    if (mode == 0) modes.push(i18n.t("services.eyecold.operation_status.off"));
    if ((mode & 1) == 1)
      modes.push(i18n.t("services.eyecold.operation_status.available"));
    if ((mode & 2) == 2)
      modes.push(i18n.t("services.eyecold.operation_status.on"));
    if ((mode & 4) == 4)
      modes.push(i18n.t("services.eyecold.operation_status.defrosting"));
    if ((mode & 8) == 8)
      modes.push(i18n.t("services.eyecold.operation_status.pretrip"));

    return modes.join(", ");
  },
  format_operating_mode(mode) {
    const i18n = useI18n();
    const modes = [];
    if (mode == 0) modes.push(i18n.t("services.eyecold.operation_status.off"));
    if ((mode & 1) == 1)
      modes.push(i18n.t("services.eyecold.operation_status.available"));
    if ((mode & 2) == 2)
      modes.push(i18n.t("services.eyecold.operation_status.on"));
    if ((mode & 4) == 4)
      modes.push(i18n.t("services.eyecold.operation_status.defrosting"));
    if ((mode & 8) == 8)
      modes.push(i18n.t("services.eyecold.operation_status.pretrip"));

    return modes.join(", ");
  },
  alertDescription(alert, alerts) {
    const obj = alerts.find((o) => o.id == alert.alert_id);
    return obj.description;
  },
  alertSeverity(alert, alerts) {
    const obj = alerts.find((o) => o.id == alert.alert_id);
    return obj.severity;
  },
  process_vehicle_alerts(vehicle, alerts) {
    const alert_warning = [];
    const alert_alarm = [];
    if (vehicle.active_alerts) {
      vehicle.active_alerts.forEach((element) => {
        const severity = this.alertSeverity(element, alerts);
        if (severity == 1)
          alert_warning.push(this.alertDescription(element, alerts));
        if (severity == 2)
          alert_alarm.push(this.alertDescription(element, alerts));
      });
    } else if (vehicle.alerts) {
      vehicle.alerts.forEach((element) => {
        const severity = this.alertSeverity(element, alerts);
        if (severity == 1)
          alert_warning.push(this.alertDescription(element, alerts));
        if (severity == 2)
          alert_alarm.push(this.alertDescription(element, alerts));
      });
    }
    return {
      warning: alert_warning,
      alarm: alert_alarm,
    };
  },
  convert_vehicle_status(status) {
    switch (status) {
      case 0:
        return "standby";
      case 1:
        return "connected";
      // Backward compatibility TBR
      case 3: // powersafe
        return "standby";
      case 4: // movement
        return "connected";
      case 2:
      default:
        return "disconnected";
    }
  },
};

const install = (app) => {
  app.config.globalProperties.$h = helpers;
};

export { install as default, helpers as helper };
