<template>
  <teleport to="body">
    <div id="general-notification" class="toastify-content hidden flex">
      <BellIcon class="text-theme-1" />
      <div class="ml-4 mr-4">
        <div data-role="title" class="font-medium"></div>
        <div data-role="text" class="text-gray-600 mt-1"></div>
      </div>
    </div>
    <div id="info-notification" class="toastify-content hidden flex">
      <InfoIcon class="text-theme-1" />
      <div class="ml-4 mr-4">
        <div data-role="title" class="font-medium"></div>
        <div data-role="text" class="text-gray-600 mt-1"></div>
      </div>
    </div>
    <div id="success-notification" class="toastify-content hidden flex">
      <CheckCircleIcon class="text-theme-10" />
      <div class="ml-4 mr-4">
        <div data-role="title" class="font-medium"></div>
        <div data-role="text" class="text-gray-600 mt-1"></div>
      </div>
    </div>
    <div id="warning-notification" class="toastify-content hidden flex">
      <AlertCircleIcon class="  " />
      <div class="ml-4 mr-4">
        <div data-role="title" class="font-medium"></div>
        <div data-role="text" class="text-gray-600 mt-1"></div>
      </div>
    </div>
    <div id="error-notification" class="toastify-content hidden flex">
      <XCircleIcon class="text-theme-5" />
      <div class="ml-4 mr-4">
        <div data-role="title" class="font-medium"></div>
        <div data-role="text" class="text-gray-600 mt-1"></div>
      </div>
    </div>
    <div id="report-notification" class="toastify-content hidden flex">
      <FileTextIcon class="text-theme-1" />
      <div class="ml-4 mr-4">
        <div data-role="title" class="font-medium"></div>
        <div data-role="text" class="text-gray-600 mt-1"></div>
      </div>
    </div>
    <div id="user-notification" class="toastify-content hidden flex">
      <UserIcon class="text-theme-1" />
      <div class="ml-4 mr-4">
        <div data-role="title" class="font-medium"></div>
        <div data-role="text" class="text-gray-600 mt-1"></div>
      </div>
    </div>
    <div id="alarm-notification" class="toastify-content hidden flex">
      <AlertCircleIcon class="text-theme-5" />
      <div class="ml-4 mr-4">
        <div data-role="title" class="font-medium"></div>
        <div data-role="text" class="text-gray-600 mt-1"></div>
      </div>
    </div>
    <div id="split-buttons-notification" class="toastify-content hidden flex">
      <div class="sm:mr-40">
        <div class="font-medium" data-role="title"></div>
        <div class="text-gray-600 mt-1" data-role="text"></div>
      </div>
      <div
        class="absolute top-0 bottom-0 right-0 flex flex-col border-l border-gray-200 dark:border-dark-5"
      >
        <a
          data-action="notification"
          class="flex-1 flex items-center justify-center px-6 font-medium text-theme-17 dark:text-gray-500 border-b border-gray-200 dark:border-dark-5"
          data-role="action_label"
          @click="on_action_click"
        ></a>
        <a
          data-dismiss="notification"
          class="flex-1 flex items-center justify-center px-6 font-medium text-gray-600"
          data-role="dismiss_label"
          @click="on_dismiss_click"
        ></a>
      </div>
    </div>
    <div id="buttons-below-notification" class="toastify-content hidden flex">
      <FileTextIcon />
      <div class="ml-4 mr-5 sm:mr-20">
        <div class="font-medium" data-role="title"></div>
        <div class="text-gray-600 mt-1" data-role="text"></div>
        <div class="mt-2.5">
          <a
            data-action="notification"
            class="btn btn-primary py-1 px-2 mr-2"
            data-role="action_label"
            @click="on_action_click"
          ></a>
          <a
            data-action-secondary="notification"
            class="btn btn-outline-secondary py-1 px-2"
            data-role="action_secondary_label"
            @click="on_action_secondary_click"
          ></a>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import EventBus from "../../libs/event-bus";
import Toastify from "toastify-js";
import { ref, onUnmounted, onMounted } from "vue";

export default {
  setup() {
    onMounted(() => {
      EventBus.on("notification:show", show);
    });
    onUnmounted(() => {
      EventBus.off("notification:show");
    });
    const action_click = ref(null);
    const action_secondary_click = ref(null);
    const dismiss_click = ref(null);

    function on_action_click() {
      if (action_click.value instanceof Function) {
        action_click.value();
      }
    }
    function on_action_secondary_click() {
      if (action_secondary_click.value instanceof Function) {
        action_secondary_click.value();
      }
    }
    function on_dismiss_click() {
      if (dismiss_click.value instanceof Function) {
        dismiss_click.value();
      }
    }

    function show({
      type = "general",
      title = "",
      text = "",
      action_label = "",
      action_secondary_label = "",
      dismiss_label = "",
      callback = () => {},
      action = () => {},
      action_secondary = () => {},
      dismiss = () => {
        this.hideToast();
      },
      duration = 5000,
      showClose = true,
    }) {
      const node = cash(`#${type}-notification`).clone();
      node.attr("id", "#$new-notification");
      node.find('[data-role="title"]').html(title);
      node.find('[data-role="text"]').html(text);
      if (node.find('[data-role="action_label"]').length > 0)
        node.find('[data-role="action_label"]').html(action_label);
      if (node.find('[data-role="action_secondary_label"]').length > 0)
        node
          .find('[data-role="action_secondary_label"]')
          .html(action_secondary_label);
      if (node.find('[data-role="dismiss_label"]').length > 0)
        node.find('[data-role="dismiss_label"]').html(dismiss_label);

      const notificationInstance = Toastify({
        node: node.removeClass("hidden")[0],
        duration: duration,
        newWindow: true,
        close: showClose,
        gravity: "top",
        position: "right",
        stopOnFocus: true,
        onClick: callback,
      }).showToast();

      cash(notificationInstance.toastElement)
        .find('[data-action="notification"]')
        .on("click", action);
      cash(notificationInstance.toastElement)
        .find('[data-action-secondary="notification"]')
        .on("click", action_secondary);
      cash(notificationInstance.toastElement)
        .find('[data-dismiss="notification"]')
        .on("click", dismiss);
    }

    return {
      on_action_click,
      on_action_secondary_click,
      on_dismiss_click,
    };
  },
};
</script>
