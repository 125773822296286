<template>
  <div id="devices">
    <search-title
      :placeholder="i18n.t('devices.search-by-name')"
      @search="updateSearchQuery"
      >{{ i18n.t("navigation.devices") }}
      <div
        v-if="devices && devices.length > 0"
        class="text-xs inline md:block text-gray-600 w-auto"
      >
        <span class="hidden md:visible">Visualizzati </span>
        {{ devices.length }} dispositivi
      </div>
      <div v-else class="text-xs text-gray-600 w-auto">
        {{ i18n.t("devices.empty") }}
      </div>
    </search-title>
    <div
      class="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-2 border-2 rounded-lg p-4"
    >
      <div v-for="vehicle in devices" :key="vehicle.id" class="box mr-2 p-4">
        <div class="mb-2 grid place-items-center">
          <img
            :src="
              require(
                `@/assets/images/vehicles/${vehicle.vehicle_type}-primary.svg`,
              )
            "
            class="block h-6 opacity-95"
          />
        </div>
        <div class="rounded-full px-2 bg-gray-300">{{ vehicle.plate }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted } from "vue";
import { useI18n } from "vue3-i18n";
import { useStore } from "@/store";
import dayjs from "dayjs";
import { useCollectionSearch } from "@/composables/collection-search";

export default {
  setup() {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const utc = require("dayjs/plugin/utc");
    dayjs.extend(utc);
    const i18n = useI18n();
    const store = useStore();

    const {
      updateSearchQuery,
      collectionToFilter,
      filteredCollection: devices,
    } = useCollectionSearch(["unit_code", "plate", "model", "serial"]);

    collectionToFilter.value = [];

    async function fetchDevices() {
      const response = await store.dispatch("vehicles/getCollection");
      collectionToFilter.value = response.validated.filter(
        (v) => v.active == 2,
      );
    }

    fetchDevices();

    onMounted(async () => {
      await fetchDevices();
    });

    return {
      i18n,
      dayjs,

      // fetch devices
      devices,

      updateSearchQuery,
    };
  },
};
</script>
