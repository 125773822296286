<template>
  <div
    class="rounded-full py-1 px-4 text-white font-bold text-center select-none"
    :class="`bg-reyesol-${connection_type} w-auto`"
  >
    <template v-if="popover">
      <Tippy
        tag="div"
        class="tooltip w-full"
        :content="i18n.t(`services.system.status.${connection_type}`)"
        :alt="i18n.t(`services.system.status.${connection_type}`)"
      >
        <WifiOffIcon v-if="type == 'disconnected'" height="100%" />
        <WifiIcon v-else height="100%" />
      </Tippy>
    </template>
    <template v-else>
      <WifiOffIcon v-if="type == 'disconnected'" height="100%" />
      <WifiIcon v-else height="100%" />
    </template>
  </div>
</template>

<script>
import { WifiIcon, WifiOffIcon } from "@zhuowenli/vue-feather-icons";
import { defineComponent, ref, watch } from "vue";
import { useI18n } from "vue3-i18n";
import Tippy from "@/global-components/tippy/Main.vue";
import { helper } from "@/utils/helper";

export default defineComponent({
  props: {
    type: {
      type: String,
      default: "",
    },
    status: {
      type: Number,
      default: null,
    },
    popover: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const i18n = useI18n();
    const connection_type = ref();

    function convert_status(status) {
      connection_type.value = helper.convert_vehicle_status(status);
    }

    if (props.status != null) {
      convert_status(props.status);
    } else {
      connection_type.value = props.type;
    }

    // watch vehicle status
    watch(
      () => props.status,
      () => convert_status(props.status),
    );

    return {
      i18n,
      connection_type,
    };
  },
  components: { WifiOffIcon, WifiIcon, Tippy },
});
</script>
