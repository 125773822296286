<template>
  <Transition name="modal">
    <div ref="dropoverid">
      <div
        class="modal-mask"
        :class="
          show == false
            ? 'hidden'
            : 'visible' && fullscreen == true
            ? ''
            : 'absolute'
        "
      >
        <div class="modal-wrapper">
          <div class="modal-container rounded-lg">
            <div class="modal-header">
              <slot name="header"></slot>
            </div>

            <div class="modal-body">
              <slot name="body"></slot>
            </div>

            <div class="modal-footer">
              <slot name="footer">
                <button
                  class="modal-default-button btn btn-primary"
                  @click="$emit('close')"
                >
                  Conferma
                </button>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    services: {
      type: [Array],
      default: () => [],
    },
  },
  emits: ["close"],
  setup() {},
};
</script>
<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 10px 10px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 4px 0;
}

.modal-footer {
  margin: 10px 0;
}

.modal-default-button {
  float: center;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.visible {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-active,
.modal-leave-active {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
