<template>
  <div
    v-if="is_general"
    class="flex flex-row mt-2 border border-2 rounded-full my-1 -mx-1 overflow-hidden justify-center shadow-sm"
    :class="
      general_status == 1 ? 'bg-reyesol-general-on' : 'bg-reyesol-general-off'
    "
  >
    <Tippy
      tag="img"
      :src="require(`@/assets/images/zap-on.svg`)"
      class="h-5 mx-1 text-blue-900 self-center"
      :content="
        general_status == 1
          ? i18n.t(`services.eyepower.general_status.on`)
          : i18n.t(`services.eyepower.general_status.off`)
      "
    >
    </Tippy>
    <p
      v-if="general_status != null && general_status == 1"
      class="hidden md:inline-block xl:hidden py-1 px-auto text-xs text-black font-medium"
    >
      {{ i18n.t(`services.eyepower.general_status.on`) }}
    </p>
    <p
      v-if="general_status != null && general_status == 0"
      class="hidden md:inline-block xl:hidden py-1 px-auto text-xs text-black font-medium"
    >
      {{ i18n.t(`services.eyepower.general_status.off`) }}
    </p>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { helper } from "@/utils/helper";
import { useI18n } from "vue3-i18n";

import Tippy from "@/global-components/tippy/Main.vue";

export default defineComponent({
  components: {
    Tippy,
  },
  props: {
    is_general: {
      type: Boolean,
      default: null,
    },
    general_status: {
      type: [Number, String],
      default: 0,
    },
  },

  setup() {
    const i18n = useI18n();
    return {
      helper,
      i18n,
    };
  },
});
</script>
