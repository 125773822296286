<template>
  <div
    class="box p-4 mb-3 cursor-pointer fleet-drop"
    :class="{ 'ring-2 ring-reyesol-blue': active }"
    @click="select"
  >
    <!-- card -->
    <div v-if="!formActive">
      <div class="flex items-center justify-between">
        <div class="grid lg:grid-cols-2 w-full gap-4">
          <!-- customer -->
          <div>
            <div class="text-xs font-bold capitalize mb-1">
              {{ i18n.t("services.eyeshare.customer_id") }}
            </div>
            <div>{{ getCustomerById(agreement.customer_id)?.name }}</div>
          </div>

          <!-- agreement -->
          <div>
            <div class="text-xs font-bold capitalize mb-1">
              {{ i18n.t("services.eyeshare.agreement_id") }}
            </div>
            <div>{{ getAgreementById(agreement.agreement_id)?.code }}</div>
          </div>

          <!-- range -->
          <div class="lg:col-span-2">
            <div class="text-xs font-bold capitalize mb-1">
              {{ i18n.t("services.eyeshare.active_range") }}
            </div>
            <div>
              {{
                `${dayjs(agreement.active_from).format("DD/MM/YYYY")} - ${dayjs(
                  agreement.active_to,
                ).format("DD/MM/YYYY")}`
              }}
            </div>
          </div>
        </div>
        <div class="flex">
          <button class="btn btn-secondary p-1 mr-1" @click.stop="showForm">
            <EditIcon class="w-5 h-5" />
          </button>
          <button class="btn btn-danger p-1" @click.stop="deleteEntity(form)">
            <TrashIcon class="w-5 h-5" />
          </button>
        </div>
      </div>
    </div>

    <!-- form -->
    <div v-else>
      <div class="grid gap-4 mb-4">
        <!-- customer_id -->
        <div>
          <label class="block mb-1 capitalize">{{
            i18n.t("services.eyeshare.customer_id")
          }}</label>
          <validated-input
            :has-error="v$.customer_id.$error"
            :errors="v$.customer_id.$errors"
          >
            <VueMultiselect
              v-model="form.customer_id"
              :options="
                customers.map((c) => ({
                  name: c.name,
                  value: c.id,
                }))
              "
              :close-on-select="true"
              :placeholder="i18n.t('services.eyeshare.customer_id-placeholder')"
              label="name"
              track-by="name"
            />
          </validated-input>
        </div>

        <!-- agreement_ids -->
        <div>
          <label class="block mb-1 capitalize">{{
            i18n.t("services.eyeshare.agreement_id")
          }}</label>
          <validated-input
            :has-error="v$.agreement_ids.$error"
            :errors="v$.agreement_ids.$errors"
          >
            <!-- create multi select -->
            <VueMultiselect
              v-if="isCreate"
              v-model="form.agreement_ids"
              :options="
                agreements.map((a) => ({
                  name: a.code,
                  value: a.id,
                }))
              "
              :multiple="true"
              :close-on-select="false"
              :placeholder="
                i18n.t('services.eyeshare.agreement_ids-placeholder')
              "
              label="name"
              track-by="name"
            />

            <!-- edit single select -->
            <VueMultiselect
              v-else
              v-model="form.agreement_ids"
              :options="
                agreements.map((a) => ({
                  name: a.code,
                  value: a.id,
                }))
              "
              :close-on-select="true"
              :placeholder="
                i18n.t('services.eyeshare.agreement_id-placeholder')
              "
              label="name"
              track-by="name"
            />
          </validated-input>
        </div>

        <!-- active_range -->
        <div>
          <label class="block mb-1 capitalize">{{
            i18n.t("services.eyeshare.active_range")
          }}</label>
          <validated-input
            :has-error="v$.active_range.$error"
            :errors="v$.active_range.$errors"
          >
            <Litepicker
              v-if="!changingRange"
              v-model="form.active_range"
              :options="{
                format: 'DD/MM/YYYY',
                autoApply: false,
                singleMode: false,
                numberOfColumns: 2,
                numberOfMonths: 2,
                showWeekNumbers: true,
                minDate: minDate,
                lang: 'it-IT',
                dropdowns: {
                  minYear: 2021,
                  maxYear: 2030,
                  months: true,
                  years: true,
                },
              }"
              class="form-control w-full"
            />
          </validated-input>
        </div>
      </div>

      <!-- save -->
      <div class="flex justify-end">
        <button class="btn btn-secondary mr-2" @click="cancelForm()">
          {{ i18n.t("general.cancel") }}
        </button>

        <button class="btn btn-primary" @click="save()">
          <loading-icon
            v-if="loading"
            icon="rings"
            color="white"
            class="mr-2"
          />
          {{ i18n.t("general.save") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, nextTick, ref, watch } from "vue";
import { useI18n } from "vue3-i18n";
import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import EventBus from "@/libs/event-bus";
import _ from "lodash";
import dayjs from "dayjs";

export default {
  components: {},

  props: {
    active: {
      type: Boolean,
      default: false,
    },
    agreement: {
      type: Object,
      required: true,
    },
    userAgreements: {
      type: Array,
      required: true,
    },
    agreements: {
      type: Array,
      required: true,
    },
    customers: {
      type: Array,
      required: true,
    },
  },

  emits: ["updated", "updated:vehicles", "selected", "canceled"],

  setup(props, { emit }) {
    const i18n = useI18n();
    const store = useStore();

    const isCreate = computed(() => !props.agreement.id);

    // select
    function select() {
      if (!formActive.value) emit("selected", props.agreement);
    }

    // form
    const formActive = ref(false);
    const formBk = ref(null);
    const minDate = ref(dayjs());
    const maxDate = ref(dayjs().add(1, "day"));
    const changingRange = ref(false);
    const form = ref({ ...props.agreement, active_range: getRange() });

    if (!props.agreement.id) showForm();

    function showForm() {
      formActive.value = true;
      formBk.value = _.cloneDeep(form.value);
      setupSelectValues();
      setRange();
    }

    function cancelForm() {
      formActive.value = false;
      form.value = _.cloneDeep(formBk.value);
      emit("canceled", form.value);
    }

    // setup select values
    function setupSelectValues() {
      const customerId = props.agreement.customer_id;
      form.value.customer_id = customerId
        ? {
            name: getCustomerById(customerId)?.name,
            value: customerId,
          }
        : null;

      if (!props.agreement.id) {
        form.value.agreement_ids = props.userAgreements.map((ua) => ({
          name: ua.code,
          value: ua.agreement_id,
        }));
      } else {
        const agreementId = props.agreement.agreement_id;
        if (agreementId) {
          form.value.agreement_ids = {
            name: getAgreementById(agreementId)?.code,
            value: agreementId,
          };
        }
      }
    }

    // range
    watch(form, () => setRange(), { deep: true });

    function setRange() {
      let userAgreement = null;

      for (let i = 0; i < props.userAgreements.length; i++) {
        const a = props.userAgreements[i];

        if (isCreate.value) {
          if (
            !form.value.agreement_ids.find((ag) => ag.value === a.agreement_id)
          ) {
            continue;
          }
        } else {
          if (form.value.agreement_ids?.value != a.agreement_id) continue;
        }

        if (!userAgreement) {
          userAgreement = a;
          continue;
        }

        if (a.active_to < userAgreement.active_to) userAgreement = a;
      }

      if (userAgreement) {
        changingRange.value = true;
        maxDate.value = dayjs(userAgreement.active_to);
        nextTick(() => {
          changingRange.value = false;
        });
      }
    }

    function getRange() {
      let range = `${minDate.value.format(
        "DD/MM/YYYY",
      )} - ${maxDate.value.format("DD/MM/YYYY")}`;
      if (props.agreement.active_from && props.agreement.active_to) {
        range = `${dayjs(props.agreement.active_from).format(
          "DD/MM/YYYY",
        )} - ${dayjs(props.agreement.active_to).format("DD/MM/YYYY")}`;
      }
      return range;
    }

    // validation
    const rules = {
      vehicle_id: { required },
      customer_id: { required },
      agreement_ids: { required },
      active_range: { required },
    };

    const v$ = useVuelidate(rules, form);

    // save
    const loading = ref(false);
    async function save() {
      loading.value = true;
      v$.value.$touch();
      if (v$.value.$error) {
        loading.value = false;
        return;
      }

      const dates = form.value.active_range.split(" - ");
      form.value.active_from = dayjs(dates[0], "DD/MM/YYYY").format();
      form.value.active_to = dayjs(dates[1], "DD/MM/YYYY").format();

      if (isCreate.value) {
        const promises = [];
        for (let i = 0; i < form.value.agreement_ids.length; i++) {
          const agreement_id = form.value.agreement_ids[i].value;
          const customer_id = form.value.customer_id.value;
          const data = { ...form.value, customer_id, agreement_id };
          delete data.agreement_ids;
          promises.push(store.dispatch("agreements/store", data));
        }
        await Promise.all(promises);
      } else {
        const agreement_id = form.value.agreement_ids.value;
        const customer_id = form.value.customer_id.value;
        const data = { ...form.value, customer_id, agreement_id };
        delete data.agreement_ids;
        await store.dispatch("agreements/update", data);
      }

      formActive.value = false;
      emit("updated");

      loading.value = false;
    }

    // delete
    async function deleteEntity(agreement) {
      EventBus.emit("confirm-dialog:show", {
        title: `${i18n.t("services.eyeshare.confirm-delete-title")}?`,
        text: i18n.t("services.eyeshare.confirm-delete-text"),
        callback: async (confirm) => {
          if (confirm) {
            const result = await store.dispatch(
              "agreements/delete",
              agreement.id,
            );
            if (result.success) emit("updated", result.validated);
          }
        },
      });
    }

    // utils
    function getCustomerById(customerId) {
      return props.customers.find((c) => c.id == customerId);
    }

    function getAgreementById(agreementId) {
      return props.agreements.find((a) => a.id == agreementId);
    }

    return {
      i18n,
      dayjs,
      isCreate,

      // select
      select,

      // form
      form,
      v$,
      formActive,
      showForm,
      cancelForm,
      changingRange,
      minDate,
      maxDate,

      // save
      loading,
      save,

      // delete
      deleteEntity,

      // utils
      getCustomerById,
      getAgreementById,
    };
  },
};
</script>

<style></style>
