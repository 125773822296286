import axios from "../libs/axios";
import { handleSuccess, handleError } from "./response-handler";
import { getResource } from "./rest-helper";
import dayjs from "dayjs";
const namespace = "driver_activities";
const api = {
  ...getResource(namespace),
  getVehicleData: async function (id) {
    try {
      const response = await axios.get(`${namespace}/${id}/vehicle`);
      return handleSuccess({ namespace, action: "getVehicleData" }, response);
    } catch (error) {
      return handleError(
        { namespace, action: "getVehicleData" },
        error.response,
      );
    }
  },
  setActivity: async function (vehicleId, driverId, from, to) {
    try {
      const response = await axios.put(`${namespace}/${vehicleId}/set`, {
        driver_id: driverId,
        period_from: from,
        period_to: to,
      });
      return handleSuccess({ namespace, action: "setActivity" }, response);
    } catch (error) {
      return handleError({ namespace, action: "setActivity" }, error.response);
    }
  },
  getVehicleHistory: async function (id, from, to) {
    try {
      const response = await axios.get(`${namespace}/${id}/history`, {
        params: {
          period_from: dayjs(from).format(),
          period_to: dayjs(to).format(),
        },
      });
      return handleSuccess(
        { namespace, action: "getVehicleHistory" },
        response,
      );
    } catch (error) {
      return handleError(
        { namespace, action: "getVehicleHistory" },
        error.response,
      );
    }
  },
};
export { api as default };
