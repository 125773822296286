<template>
  <div v-if="moving != null" class="flex w-fit">
    <FastForwardIcon class="self-center" />
    <div class="badge-reyesol-temperatures-list">
      <span v-if="moving != null" class="temp">
        <template v-if="moving">
          {{ i18n.t("services.eyegeo.status.moving") }}
        </template>
        <template v-else>
          {{ i18n.t("services.eyegeo.status.stopped") }}
        </template>
      </span>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue3-i18n";

import { FastForwardIcon } from "@zhuowenli/vue-feather-icons";

export default defineComponent({
  components: {
    FastForwardIcon,
  },
  props: {
    moving: {
      type: Boolean,
      default: null,
    },
  },

  setup() {
    const i18n = useI18n();
    return { i18n };
  },
});
</script>
