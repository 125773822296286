<template>
  <div v-if="vehicle" id="service-eyecar">
    <div
      v-if="data && data.position && data.position.km !== null"
      class="box p-3 text-center"
    >
      <VehicleParameter
        :active="data.position.km !== null"
        :name="i18n.t('services.eyecar.km.name')"
        icon="MapIcon"
        :extraColumn="true"
        :value="helper.format_km(data.position.km)"
      >
      </VehicleParameter>
    </div>

    <div class="box p-3 mt-4 text-center justify-items-center">
      <VehicleParameter
        :name="i18n.t('services.eyecar.driver.name')"
        :extraColumn="true"
        :icon="
          data && data.activity && data.activity.driver
            ? 'UserCheckIcon'
            : 'UserXIcon'
        "
        :setclass="
          () =>
            data && data.activity && data.activity.driver
              ? 'bg-reyesol-green'
              : 'bg-reyesol-red'
        "
        :value="
          data && data.activity && data.activity.driver
            ? data.activity.driver.name + ' ' + data.activity.driver.surname
            : i18n.t('services.eyecar.driver.none')
        "
      >
        <template
          v-if="data && data.activity && data.activity.driver"
          v-slot:extra
        >
          <div class="dropdown">
            <button
              class="dropdown-toggle btn btn-sm btn-warning p-1 mt-2"
              aria-expanded="false"
            >
              <StopCircleIcon class="w-5 h-5" />
              {{ i18n.t("services.eyecar.activity.end") }}
            </button>
            <div class="dropdown-menu w-80">
              <div class="dropdown-menu__content box p-4">
                <CmdDatetime
                  :initial-value="new Date()"
                  :name="i18n.t('services.eyecar.activity.end')"
                  :description="
                    i18n.t('services.eyecar.activity.cmd.end_description')
                  "
                  :autosend="true"
                  :min-date="minDate"
                  :max-date="new Date()"
                  @send="(v) => sendEndCommand(v)"
                />
              </div>
            </div>
          </div> </template
        ><template v-else v-slot:extra>
          <div class="dropdown">
            <button
              class="dropdown-toggle btn btn-sm btn-warning p-1 mt-2"
              aria-expanded="false"
            >
              <PlayCircleIcon class="w-5 h-5" />
              {{ i18n.t("services.eyecar.activity.start") }}
            </button>
            <div class="dropdown-menu w-80">
              <div class="dropdown-menu__content box p-4">
                <CmdDatetimeSelect
                  :initial-value="new Date()"
                  :name="i18n.t('services.eyecar.activity.start')"
                  :placeholderSelect="
                    i18n.t('services.eyecar.activity.driver.placeholder')
                  "
                  :description="
                    i18n.t('services.eyecar.activity.cmd.start_description')
                  "
                  :options="
                    drivers_list.map((d) => ({
                      key: d.id,
                      label: d.name + ' ' + d.surname,
                    }))
                  "
                  :autosend="true"
                  :min-date="minDate"
                  :max-date="new Date()"
                  @send="(d, f) => sendStartCommand(d, f)"
                />
              </div>
            </div>
          </div>
        </template>
      </VehicleParameter>
    </div>

    <div class="py-6">
      <div class="text-lg capitalize mb-4">
        {{ i18n.t("services.eyecar.drivers_history") }}
        <Eyepicker v-model="daterange" />
      </div>
      <div v-if="!loadingHistory" class="max-h-80 overflow-y-auto">
        <div v-if="history && history.list">
          <div v-for="h in history.list.activity" :key="h.id">
            <div v-if="h" class="intro-y">
              <div
                class="box px-4 py-4 mb-3 flex items-center cursor-pointer mx-2 shadow-md hover:shadow-lg hover:bg-gray-200"
                :class="{
                  'bg-gray-200': selectedEvent && selectedEvent.id === h.id,
                }"
              >
                <div class="ml-4 mr-auto">
                  <div class="font-medium">
                    <DateAgo :datetime="h.period_from"></DateAgo>
                  </div>
                  <div class="text-gray-600 text-xs mt-0.5">Inizio guida</div>
                </div>
                <div class="ml-4 mr-auto">
                  <div v-if="h.period_to" class="font-medium">
                    <DateAgo :datetime="h.period_to"></DateAgo>
                  </div>
                  <div v-else class="font-medium text-reyesol-red">
                    In corso
                  </div>
                  <div class="text-gray-600 text-xs mt-0.5">Fine guida</div>
                </div>

                <VehicleParameter
                  :active="h.driver !== null"
                  :name="i18n.t('services.eyecar.driver.name')"
                  :icon="UserCheckIcon"
                  :value="
                    h.driver
                      ? h.driver.name + ' ' + h.driver.surname
                      : i18n.t('services.eyecar.driver.none')
                  "
                >
                </VehicleParameter>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="font-light text-xl text-gray-500">
          {{ i18n.t("general.no-results") }}
        </div>
      </div>
      <loading-icon v-else icon="rings" class="w-20 h-20" />
      <div class="btn-reyesol-report" @click="requestReport">
        <FileTextIcon /> {{ i18n.t("general.request-report") }}
      </div>
    </div>
  </div>
</template>

<script>
import { watch, ref, computed, reactive, onUnmounted, onMounted } from "vue";
import { useI18n } from "vue3-i18n";
import { useStore } from "vuex";
import dayjs from "dayjs";
import EventBus from "@/libs/event-bus";
import { useDaterange } from "@/composables/daterange";
import toast from "@/services/toast";
import { helper } from "@/utils/helper";
import VehicleParameter from "@/components/vehicle/VehicleParameter.vue";
import CmdDatetime from "@/components/commands/CmdDatetime.vue";
import CmdDatetimeSelect from "@/components/commands/CmdDatetimeSelect.vue";
//import CmdRange from "@/components/commands/CmdRange.vue";
/*
        <div
          v-show="data.position.km_dirty"
          class="inline p-1 text-xs text-reyesol-red h-6 w-6"
        >
          <loading-icon icon="oval" />
        </div>

        <template v-slot:extra>
          <div
            v-if="
              vehicle.configurations &&
              vehicle.configurations.eyecar_cmd_enabled &&
              vehicle.configurations.eyecar_cmd_enabled == 'true'
            "
            class="dropdown"
          >
            <button
              class="dropdown-toggle btn btn-sm btn-warning p-1 mt-2"
              aria-expanded="false"
            >
              <span class="pr-1">{{
                i18n.t("services.eyecar.cmd.button")
              }}</span>
              <SlidersIcon class="w-5 h-5" />
            </button>
            <div class="dropdown-menu w-80">
              <div class="dropdown-menu__content box p-4">
                <CmdRange
                  :initial-value="data.position.km"
                  :name="i18n.t('services.eyecar.cmd.km')"
                  :description="i18n.t('services.eyecar.cmd.km_description')"
                  @send="(v) => sendKmCommand(v)"
                />
              </div>
            </div>
          </div>
          <div v-else>
            <button
              class="btn btn-sm border-gray-500 p-1 mt-2"
              aria-expanded="false"
            >
              <span class="pr-1">{{ i18n.t("services.eyecontrol.name") }}</span>
              <LockIcon class="w-5 h-5" />
            </button>
          </div>
        </template>
*/
export default {
  components: {
    VehicleParameter,
    CmdDatetime,
    CmdDatetimeSelect,
    //CmdRange
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const vehicle = computed(() => store.getters["globalMap/selectedVehicle"]);
    const data = reactive(ref(null));
    const driver_activities_data = reactive(ref(null));
    const history = reactive(ref(null));
    const loadingHistory = ref(false);
    const loading = ref(false);
    const addActive = ref(false);
    const addExpenseActive = ref(false);
    const addType = ref(0);
    const subtypes = ref([0, 1, 2, 3, 4, 5, 6, 7]);
    const fridge_time_config = ref(12);
    const fridge_hours_config = ref(1500);
    const recorder_time_config = ref(12);
    const fgas_time_config = ref(12);
    const maintenance_km_config = ref(1500);
    const maintenance_time_config = ref(12);
    const addDate = ref(dayjs().format("YYYY-MM-DD"));
    const addData = ref(null);
    const selectedEvent = ref(null);
    const addDescription = ref("");
    const drivers_list = ref([]);
    const minDate = ref(null);
    const {
      daterange,
      from: historyFrom,
      to: historyTo,
    } = useDaterange({
      onChange: getHistory,
    });

    // Tabs
    const activeTab = ref("service");
    // watch service visibility
    watch(
      () => props.active,
      () => {
        if (props.active) initService();
        else destroyService();
      },
    );

    function updatePosition(position) {
      if (data.value == null) return;
      data.value.position = position;
    }

    onMounted(() => {
      EventBus.on("vehicle:updates:position", updatePosition);
    });
    onUnmounted(() => {
      EventBus.off("vehicle:updates:position");
    });

    // requestReport service
    async function requestReport() {
      emit("report-request", ["drivers.report"], "drivers", ["drivers.report"]);
    }

    // init service
    async function initService() {
      data.value = null;
      const dataResult = await store.dispatch(
        "driver_activities/getVehicleData",
        vehicle.value.id,
      );

      data.value = dataResult.validated;
      if (data.value && data.value.activity)
        minDate.value = data.value.activity.period_from;

      const response = await store.dispatch("drivers/getCollection");
      console.log("Response: ", response);
      drivers_list.value = response.validated;
      getHistory();
    }

    async function addNewExpense() {
      addExpenseActive.value = true;
    }

    async function cancelForm() {
      addActive.value = false;
      addExpenseActive.value = false;
    }

    async function addNewMaintenance() {
      addActive.value = true;
    }

    // destroy service
    function destroyService() {}
    // get history
    async function getHistory() {
      if (!props.active) return;
      loadingHistory.value = true;

      const historyResult = await store.dispatch(
        "driver_activities/getVehicleHistory",
        {
          vehicleId: vehicle.value.id,
          dateFrom: dayjs(historyFrom.value).format(),
          dateTo: dayjs().isSame(dayjs(historyTo.value), "day")
            ? dayjs().format()
            : dayjs(historyTo.value).format(),
        },
      );
      history.value = historyResult.validated;
      loadingHistory.value = false;
    }

    // save
    async function save() {
      loading.value = true;
      let result = null;

      result = await store.dispatch("driver/addDriver", {
        vehicleId: vehicle.value.id,
        driverId: driver.value,
        preiodFrom: addDate.value,
      });
      if (!result.success) {
        toast.error(result.errorMessage);
      }

      if (result.success) {
        addActive.value = false;

        const dataResult = await store.dispatch(
          "driver/getVehicleData",
          vehicle.value.id,
        );
        data.value = dataResult.validated;
      }

      loading.value = false;
    }

    async function saveExpense() {
      loading.value = true;
      let result = null;

      result = await store.dispatch("eyeservice/addMaintenance", {
        vehicleId: vehicle.value.id,
        type: 4,
        date: addDate.value,
        description: addDescription.value,
        data: addData.value,
      });
      if (!result.success) {
        toast.error(result.errorMessage);
      }

      if (result.success) {
        addActive.value = false;

        const dataResult = await store.dispatch(
          "eyeservice/getVehicleData",
          vehicle.value.id,
        );
        data.value = dataResult.validated;
      }

      loading.value = false;
    }

    async function sendKmCommand(v) {
      loading.value = true;
      console.log(v);
      loading.value = false;
    }

    async function sendEndCommand(v) {
      loading.value = true;
      let result = null;

      result = await store.dispatch("driver_activities/setActivity", {
        vehicleId: vehicle.value.id,
        to: dayjs(v).format(),
      });
      if (!result.success) {
        toast.error(result.errorMessage);
      }

      if (result.success) {
        initService();
      }

      loading.value = false;
    }

    async function sendStartCommand(driver, from) {
      loading.value = true;
      let result = null;
      console.log("driver", driver.value);
      result = await store.dispatch("driver_activities/setActivity", {
        vehicleId: vehicle.value.id,
        driverId: driver.value,
        from: dayjs(from).format(),
      });
      if (!result.success) {
        toast.error(result.errorMessage);
      }

      if (result.success) {
        initService();
      }

      loading.value = false;
    }

    async function save_config() {
      loading.value = true;
      let result = null;

      result = await store.dispatch("eyeservice/configMaintenance", {
        vehicleId: vehicle.value.id,
        fridge_time_config: fridge_time_config.value,
        fridge_hours_config: fridge_hours_config.value,
        maintenance_time_config: maintenance_time_config.value,
        maintenance_km_config: maintenance_km_config.value,
        fgas_time_config: fgas_time_config.value,
        recorder_time_config: recorder_time_config.value,
      });
      if (!result.success) {
        toast.error(result.errorMessage);
      }

      if (result.success) {
        const dataResult = await store.dispatch(
          "eyeservice/getVehicleData",
          vehicle.value.id,
        );
        data.value = dataResult.validated;
      }

      loading.value = false;
    }

    return {
      i18n: useI18n(),
      dayjs,
      vehicle,
      activeTab,
      data,
      minDate,
      driver_activities_data,
      fridge_time_config,
      fridge_hours_config,
      maintenance_time_config,
      maintenance_km_config,
      recorder_time_config,
      fgas_time_config,
      history,
      addNewMaintenance,
      loadingHistory,
      loading,
      subtypes,
      addActive,
      addExpenseActive,
      addNewExpense,
      cancelForm,
      selectedEvent,
      addType,
      addDate,
      addData,
      addDescription,
      daterange,
      save,
      helper,
      requestReport,
      saveExpense,
      save_config,
      sendEndCommand,
      sendKmCommand,
      drivers_list,
      sendStartCommand,
    };
  },
};
</script>

<style></style>
