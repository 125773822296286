<template>
  <div
    class="box p-4 mb-3 cursor-pointer"
    :class="{ 'ring-2 ring-reyesol-blue': active }"
    @click="select"
  >
    <!-- card -->
    <div class="flex items-center justify-between">
      <div class="grid grid-cols-4 gap-y-4 justify-between w-full">
        <div class="ml-4 mr-auto">
          <div class="font-medium">
            <template v-if="formActive">
              <select
                v-model="activity.type_id"
                :disabled="sending"
                class="form-select"
              >
                <option v-for="d in types" :key="d.id" :value="d.id">
                  {{ d.name }}
                </option>
              </select>
            </template>
            <template v-else-if="activity.type">
              {{ activity.type.name }}
            </template>
          </div>
          <div class="text-gray-600 text-xs mt-0.5">Categoria</div>
        </div>
        <div class="ml-4 mr-auto">
          <div class="font-medium">
            <template v-if="formActive">
              <select
                v-model="activity.vehicle_id"
                :disabled="sending"
                class="form-select"
              >
                <option v-for="v in vehicles" :key="v.id" :value="v.id">
                  {{ v.plate }} {{ v.serial_number }}
                </option>
              </select>
            </template>
            <template v-else-if="activity.vehicle">
              {{ activity.vehicle.plate }} {{ activity.vehicle.vehicle_serial }}
            </template>
          </div>
          <div class="text-gray-600 text-xs mt-0.5">Veicolo</div>
        </div>
        <div class="ml-4 mr-auto">
          <div class="font-medium">
            <template v-if="formActive">
              <Datepicker
                v-model="activity.date"
                multi-calendars="true"
                format="dd/MM/yyyy HH:mm"
                auto-apply
                :close-on-auto-apply="false"
                show-week-numbers
                locale="it-IT"
                :clearable="false"
                class="w-full"
              />
            </template>
            <template v-else>
              {{ dayjs(activity.date).format("DD/MM/YYYY") }}
            </template>
          </div>
          <div class="text-gray-600 text-xs mt-0.5">Data</div>
        </div>
        <div class="ml-4 mr-auto">
          <div class="font-medium text-reyesol-red">
            <template v-if="formActive">
              <input
                v-model="activity.amount"
                type="numeric"
                class="w-full form-control"
              />
            </template>
            <template v-else>{{ h.format_currency(activity.amount) }}</template>
          </div>
          <div class="text-gray-600 text-xs mt-0.5">Costo</div>
        </div>
        <div class="ml-4 mr-auto">
          <div class="font-medium">
            <template v-if="formActive">
              <input
                v-model="activity.supplier"
                type="numeric"
                class="w-full form-control"
              />
            </template>
            <template v-else>{{ activity.supplier || "N/A" }}</template>
          </div>
          <div class="text-gray-600 text-xs mt-0.5">Fornitore</div>
        </div>
        <div
          v-if="
            activity.type &&
            activity.type.repeat_after_km &&
            (activity.km || formActive)
          "
          class="ml-4 mr-auto"
        >
          <div class="font-medium">
            <template v-if="formActive">
              <input
                v-model="activity.km"
                type="numeric"
                class="w-full form-control"
              />
            </template>
            <template v-else-if="activity.km">
              {{ h.format_km(activity.km) }}
              <div class="text-xs text-reyesol-green">
                attuali
                {{ h.format_km(Number(activity.vehicle.km)) }}
              </div>
            </template>
          </div>
          <div class="text-gray-600 text-xs mt-0.5">Km rilevati</div>
        </div>
        <div
          v-if="
            activity.type &&
            !formActive &&
            activity.km &&
            activity.type.repeat_after_km
          "
          class="ml-4 mr-auto"
        >
          <div class="font-medium text-reyesol-red">
            {{
              h.format_km(
                Number(activity.km) + Number(activity.type.repeat_after_km),
              )
            }}
            <div class="text-xs text-reyesol-green">
              {{
                Number(activity.km) +
                  Number(activity.type.repeat_after_km) -
                  Number(activity.vehicle.km) >
                0
                  ? "mancano"
                  : "scaduto da"
              }}

              {{
                h.format_km(
                  Math.abs(
                    Number(activity.km) +
                      Number(activity.type.repeat_after_km) -
                      Number(activity.vehicle.km),
                  ),
                )
              }}
            </div>
          </div>
          <div class="text-gray-600 text-xs mt-0.5">Prossimo rinnovo</div>
        </div>
        <div
          v-if="
            activity.type && !formActive && activity.type.repeat_after_period
          "
          class="ml-4 mr-auto"
        >
          <div class="font-medium text-reyesol-red">
            {{
              dayjs(activity.date)
                .add(activity.type.repeat_after_period, "month")
                .format("DD/MM/YYYY")
            }}
          </div>
          <div class="text-xs text-reyesol-green">
            {{
              dayjs(activity.date)
                .add(activity.type.repeat_after_period, "month")
                .diff(dayjs(), "days") > 0
                ? "mancano"
                : "scaduto da"
            }}

            {{
              Math.abs(
                dayjs(activity.date)
                  .add(activity.type.repeat_after_period, "month")
                  .diff(dayjs(), "days"),
              )
            }}
            giorni
          </div>
          <div class="text-gray-600 text-xs mt-0.5">Prossimo rinnovo</div>
        </div>
        <div
          v-if="
            activity.type &&
            (activity.hour || (formActive && activity.type.repeat_after_hours))
          "
          class="ml-4 mr-auto"
        >
          <div class="font-medium">
            <template v-if="formActive">
              <input
                v-model="activity.hour"
                type="numeric"
                class="w-full form-control"
              />
            </template>
            <template v-else>
              {{ h.format_hours(activity.hour) }}
            </template>
          </div>
          <div class="text-gray-600 text-xs mt-0.5">Ore rilevate</div>
        </div>
        <div
          v-if="
            activity.type && activity.hour && activity.type.repeat_after_hours
          "
          class="ml-4 mr-auto"
        >
          <div class="font-medium text-reyesol-red">
            {{
              Number(activity.hour) +
                Number(activity.type.repeat_after_hours) -
                Number(activity.vehicle.hours) >
              0
                ? "mancano"
                : "scaduto da"
            }}

            {{
              h.format_hours(
                Math.abs(
                  Number(activity.hour) +
                    Number(activity.type.repeat_after_hours) -
                    Number(activity.vehicle.hours),
                ),
              )
            }}
          </div>
          <div class="text-gray-600 text-xs mt-0.5">Prossimo rinnovo</div>
        </div>
      </div>
      <div class="flex self-start">
        <button
          v-if="!formActive"
          class="btn btn-secondary p-1 mr-1"
          @click.stop="formActive = true"
        >
          <EditIcon class="w-5 h-5" />
        </button>
        <button
          v-if="formActive && !activity.id"
          class="btn btn-primary"
          @click.stop="add()"
        >
          <loading-icon
            v-if="loading"
            icon="rings"
            color="white"
            class="mr-2"
          />
          {{ i18n.t("general.add") }}
        </button>
        <button
          v-if="formActive && activity.id"
          class="btn btn-primary ml-2"
          @click.stop="save()"
        >
          <loading-icon
            v-if="loading"
            icon="rings"
            color="white"
            class="mr-2"
          />
          {{ i18n.t("general.save") }}
        </button>
        <button
          v-if="formActive"
          class="btn btn-secondary ml-2"
          @click.stop="cancelForm()"
        >
          {{ i18n.t("general.cancel") }}
        </button>

        <button
          v-if="!formActive"
          class="btn btn-danger p-1"
          @click.stop="deleteEntity()"
        >
          <TrashIcon class="w-5 h-5" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import { useI18n } from "vue3-i18n";
import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { helper } from "@/utils/helper";
import toast from "@/services/toast";
import EventBus from "@/libs/event-bus";
import dayjs from "dayjs";
import Datepicker from "@vuepic/vue-datepicker";

export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    activity: {
      type: Object,
      default: null,
    },
    types: {
      type: Array,
      default: null,
    },
    vehicles: {
      type: Array,
      default: null,
    },
  },
  emits: ["updated", "selected", "canceled"],
  components: { Datepicker },

  setup(props, { emit }) {
    const i18n = useI18n();
    const store = useStore();
    const minDate = ref(null);

    watch(
      () => props.activity.type_id,
      () =>
        (props.activity.type = props.types.find(
          (t) => t.id == props.activity.type_id,
        )),
    );

    // select
    function select() {
      if (!formActive.value) emit("selected", props.activity);
    }

    // form
    const name_error = ref(false);
    const name_error_array = ref([]);
    const formActive = ref(false);
    if (props.activity && !props.activity.id) showForm();

    function showForm() {
      formActive.value = true;
    }

    function cancelForm() {
      formActive.value = false;
      emit("canceled", props.entity);
    }

    // validation
    const rules = {
      vechile_id: { required },
      activity_id: { required },
      period_from: {},
      period_to: {},
      note: {},
    };

    const v$ = useVuelidate(rules, props.activity);

    // save
    const loading = ref(false);

    async function add() {
      loading.value = true;

      const result = await store.dispatch("type_activities/setActivity", {
        id: props.activity.id,
        vehicleId: props.activity.vehicle_id,
        typeId: props.activity.type_id,
        date: dayjs(props.activity.date).format(),
        km: props.activity.km,
        supplier: props.activity.supplier,
        hour: props.activity.hour,
        amount: props.activity.amount,
      });
      if (!result.success) {
        toast.error(result.errorMessage);
      }

      emit("updated", props.activity);

      formActive.value = false;

      loading.value = false;
    }

    async function save() {
      loading.value = true;

      const result = await store.dispatch("type_activities/setActivity", {
        id: props.activity.id,
        vehicleId: props.activity.vehicle_id,
        typeId: props.activity.type_id,
        date: dayjs(props.activity.date).format(),
        km: props.activity.km,
        supplier: props.activity.supplier,
        hour: props.activity.hour,
        amount: props.activity.amount,
      });
      if (!result.success) {
        toast.error(result.errorMessage);
      }

      emit("updated", props.activity);

      formActive.value = false;

      loading.value = false;
    }

    // delete
    async function deleteEntity() {
      EventBus.emit("confirm-dialog:show", {
        title: `${i18n.t("types.confirm-delete-title")}?`,
        text: i18n.t("types.confirm-delete-text"),
        callback: async (confirm) => {
          if (confirm) {
            const result = await store.dispatch(
              "type_activities/delete",
              props.activity.id,
            );
            if (result.success) emit("updated", result.validated);
          }
        },
      });
    }

    // utils
    const colors = store.getters["main/colors"];

    return {
      i18n,
      name_error,
      name_error_array,
      // select
      select,

      // form
      v$,
      formActive,
      showForm,
      cancelForm,
      minDate,
      // save
      loading,
      add,
      save,

      // delete
      deleteEntity,

      // utils
      h: helper,
      colors,
      dayjs,
    };
  },
};
</script>

<style></style>
