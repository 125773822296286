<template>
  <div v-if="vehicle" id="service-eyecold">
    <div v-if="data">
      <div class="flex flex-wrap justify-between items-baseline mb-4">
        <div class="text-lg capitalize">
          {{ i18n.t("services.eyecold.last_status") }}
        </div>
        <div class="text-right">
          <div>
            {{ i18n.t("services.eyecold.last_temperature") }}
            <DateAgo
              v-if="data.temperature"
              :datetime="data.temperature.message_datetime"
            ></DateAgo>
            <span v-else>
              {{ i18n.t("services.no_data") }}
            </span>
          </div>
          <div>
            {{ i18n.t("services.eyecold.last_fridge") }}
            <DateAgo
              v-if="data.fridge"
              :datetime="data.fridge.message_datetime"
            ></DateAgo>
            <span v-else>
              {{ i18n.t("services.no_data") }}
            </span>
          </div>
        </div>
      </div>

      <div class="report-box mb-8">
        <div class="box p-5">
          <div class="">
            <div class="flex flex-wrap justify-around">
              <VehicleParameter
                :name="i18n.t('services.eyecold.odl_num')"
                icon="TagIcon"
                :value="
                  vehicle.configurations &&
                  vehicle.configurations.eyecold_fridge_odl_num != null
                    ? vehicle.configurations.eyecold_fridge_odl_num
                    : i18n.t('services.no_data')
                "
              >
                <template v-slot:extra>
                  <div class="dropdown">
                    <button
                      class="dropdown-toggle btn btn-sm btn-warning p-1 ml-2"
                      aria-expanded="false"
                    >
                      <SlidersIcon class="w-3 h-3" />
                    </button>
                    <div class="dropdown-menu w-80">
                      <div class="dropdown-menu__content box p-4">
                        <CmdText
                          :initial-value="
                            vehicle.configurations.eyecold_fridge_odl_num
                          "
                          :name="i18n.t('services.eyecold.cmd.odl_num')"
                          :description="
                            i18n.t('services.eyecold.cmd.odl_num_description')
                          "
                          :ask="false"
                          @send="
                            (v) =>
                              sendFridgeCommand('odl_num', {
                                value: v,
                              })
                          "
                        />
                      </div>
                    </div>
                  </div>
                </template>
              </VehicleParameter>
              <VehicleParameter
                :name="i18n.t('services.eyecold.odl_t1')"
                icon="ThermometerIcon"
                :value="
                  vehicle.configurations &&
                  vehicle.configurations.eyecold_fridge_odl_t1 != null
                    ? vehicle.configurations.eyecold_fridge_odl_t1 + '° C'
                    : i18n.t('services.no_data')
                "
              >
                <template v-slot:extra>
                  <div class="dropdown">
                    <button
                      class="dropdown-toggle btn btn-sm btn-warning p-1 ml-2"
                      aria-expanded="false"
                    >
                      <SlidersIcon class="w-3 h-3" />
                    </button>
                    <div class="dropdown-menu w-80">
                      <div class="dropdown-menu__content box p-4">
                        <CmdRange
                          :initial-value="
                            vehicle.configurations.eyecold_fridge_odl_t1 || 0
                          "
                          :can-null="true"
                          :min="-35"
                          :max="35"
                          :step="1"
                          unit="°c"
                          :name="i18n.t('services.eyecold.cmd.odl_t1')"
                          :description="
                            i18n.t('services.eyecold.cmd.odl_t1_description')
                          "
                          :ask="false"
                          @send="
                            (v) =>
                              sendFridgeCommand('odl_t1', {
                                value: v,
                              })
                          "
                        />
                      </div>
                    </div>
                  </div>
                </template>
              </VehicleParameter>
            </div>
          </div>
        </div>
      </div>

      <div v-if="data.fridge" class="report-box mb-8">
        <div class="box p-5">
          <!-- Battery, fridge hours, alarms -->
          <div class="flex flex-wrap justify-around">
            <VehicleParameter
              :active="data.fridge.current_sensor !== null"
              :name="i18n.t('services.eyecold.current_sensor')"
              icon="BatteryChargingIcon"
              :value="data.fridge.current_sensor"
              :setclass="
                (val) =>
                  voltage_alert
                    ? 'bg-reyesol-current-alarm-range'
                    : voltage_warning
                    ? 'bg-reyesol-current-warning-range'
                    : 'bg-reyesol-current-in-range'
              "
              :format="helper.format_volt"
            />

            <VehicleParameter
              :active="data.fridge.alarm_status !== null"
              :name="i18n.t('services.eyecold.alarm_status.name')"
              icon="AlertTriangleIcon"
              :setclass="
                () =>
                  data.fridge.alarm_status
                    ? data.fridge.alarm_status == 2
                      ? 'bg-reyesol-alert-alarm'
                      : 'bg-reyesol-alert-warning'
                    : 'bg-reyesol-gray'
              "
              :value="
                data.fridge.alarm_status
                  ? data.fridge.alarm_status == 2
                    ? i18n.t('services.eyecold.alarm_status.stopped')
                    : i18n.t('services.eyecold.alarm_status.operative')
                  : i18n.t('services.eyecold.alarm_status.none')
              "
            />

            <VehicleParameter
              :name="i18n.t('services.eyecold.overall_status.name')"
              icon="PowerIcon"
              :setclass="
                () =>
                  data.fridge.overall_status
                    ? 'bg-reyesol-fridge-on'
                    : 'bg-reyesol-fridge-off'
              "
              :value="
                data.fridge.overall_status
                  ? i18n.t('services.eyecold.overall_status.on')
                  : i18n.t('services.eyecold.overall_status.off')
              "
            />

            <template v-if="false">
              <div
                v-if="
                  vehicle.configurations &&
                  vehicle.configurations.eyecold_cmd_enabled &&
                  vehicle.configurations.eyecold_cmd_enabled == 'true'
                "
                class="dropdown"
              >
                <template v-if="vehicle.status == 1">
                  <button
                    class="dropdown-toggle btn btn-sm btn-warning p-1 mt-2"
                    aria-expanded="false"
                  >
                    <span class="pr-1">{{
                      i18n.t("services.eyecontrol.name")
                    }}</span>
                    <SlidersIcon class="w-5 h-5" />
                  </button>
                  <div class="dropdown-menu w-80">
                    <div class="dropdown-menu__content box p-4">
                      <CmdOnOff
                        :initial-value="data.fridge.overall_status"
                        :name="i18n.t('services.eyecold.cmd.status_name')"
                        :description="
                          i18n.t('services.eyecold.cmd.status_description')
                        "
                        @send="(v) => sendFridgeCommand('overall_status', v)"
                      />
                    </div>
                  </div>
                </template>
                <template v-else-if="vehicle.status == 0">
                  <button
                    class="btn btn-sm border-gray-500 p-1 mt-2"
                    aria-expanded="false"
                  >
                    <span class="pr-1">{{
                      i18n.t("services.eyecontrol.name")
                    }}</span>
                    <WifiOffIcon class="w-5 h-5" />
                  </button>
                </template>
              </div>
              <div v-else>
                <button
                  class="btn btn-sm border-gray-500 p-1 mt-2"
                  aria-expanded="false"
                >
                  <span class="pr-1">{{
                    i18n.t("services.eyecontrol.name")
                  }}</span>
                  <LockIcon class="w-5 h-5" />
                </button>
              </div>
            </template>

            <VehicleParameter
              :active="data.fridge.operating_mode !== null"
              :name="i18n.t('services.eyecold.operating_mode.name')"
              icon="WindIcon"
              :extraColumn="true"
              :value="
                data.fridge.operating_mode
                  ? i18n.t('services.eyecold.operating_mode.continuous')
                  : i18n.t('services.eyecold.operating_mode.startstop')
              "
            >
              <div
                v-show="data.fridge.operating_mode_dirty"
                class="inline p-1 text-xs text-reyesol-red h-6 w-6"
              >
                <loading-icon icon="oval" />
              </div>

              <template v-slot:extra>
                <div
                  v-if="
                    vehicle.configurations &&
                    vehicle.configurations.eyecold_cmd_enabled &&
                    vehicle.configurations.eyecold_cmd_enabled == 'true'
                  "
                  class="dropdown"
                >
                  <template v-if="vehicle.status == 1">
                    <button
                      class="dropdown-toggle btn btn-sm btn-warning p-1 mt-2"
                      aria-expanded="false"
                    >
                      <span class="pr-1">{{
                        i18n.t("services.eyecontrol.name")
                      }}</span>
                      <SlidersIcon class="w-5 h-5" />
                    </button>
                    <div class="dropdown-menu w-80">
                      <div class="dropdown-menu__content box p-4">
                        <CmdSelect
                          :options="operating_modes"
                          :initial-value="data.fridge.operating_mode"
                          :name="i18n.t('services.eyecold.cmd.operating_mode')"
                          :description="
                            i18n.t(
                              'services.eyecold.cmd.operating_mode_description',
                            )
                          "
                          @send="(v) => sendFridgeCommand('operating_mode', v)"
                        />
                      </div>
                    </div>
                  </template>
                  <template v-else-if="vehicle.status == 0">
                    <button
                      class="btn btn-sm border-gray-500 p-1 mt-2"
                      aria-expanded="false"
                    >
                      <span class="pr-1">{{
                        i18n.t("services.eyecontrol.name")
                      }}</span>
                      <WifiOffIcon class="w-5 h-5" />
                    </button>
                  </template>
                </div>
                <div v-else>
                  <button
                    class="btn btn-sm border-gray-500 p-1 mt-2"
                    aria-expanded="false"
                  >
                    <span class="pr-1">{{
                      i18n.t("services.eyecontrol.name")
                    }}</span>
                    <LockIcon class="w-5 h-5" />
                  </button>
                </div>
              </template>
            </VehicleParameter>

            <VehicleParameter
              :active="data.fridge.engine_status !== null"
              :name="i18n.t('services.eyecold.engine_status.name')"
              icon="RefreshCwIcon"
              :setclass="
                () =>
                  data.fridge.engine_status
                    ? 'bg-reyesol-fridge-on'
                    : 'bg-reyesol-fridge-off'
              "
              :value="
                data.fridge.engine_status
                  ? i18n.t('services.eyecold.engine_status.electric')
                  : i18n.t('services.eyecold.engine_status.fuel')
              "
            />

            <VehicleParameter
              :active="data.fridge.operating_mode_status !== null"
              :name="i18n.t('services.eyecold.operation_status.name')"
              :icon="
                data.fridge.operating_mode_status ? 'ZapIcon' : 'ZapOffIcon'
              "
              :setclass="
                () =>
                  data.fridge.operating_mode_status
                    ? 'bg-reyesol-fridge-on'
                    : 'bg-reyesol-fridge-off'
              "
              :value="data.fridge.operating_mode_status"
              :format="helper.format_operating_mode"
            />
          </div>
          <div
            v-if="
              data.fridge.hour_meter_electric != null ||
              data.fridge.hour_meter_fuel != null
            "
            class="pt-4 mt-4 border-t border-gray-400"
          >
            <div class="flex flex-wrap justify-around">
              <div
                v-if="data.fridge.hour_meter_electric != null"
                class="text-md text-gray-600 text-center"
              >
                {{ i18n.t("services.eyecold.hour_meter_electric") }}:
                {{ data.fridge.hour_meter_electric }}
              </div>
              <div
                v-if="data.fridge.hour_meter_fuel != null"
                class="text-md text-gray-600 text-center"
              >
                {{ i18n.t("services.eyecold.hour_meter_fuel") }}:
                {{ data.fridge.hour_meter_fuel }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="
          data.fridge &&
          data.fridge.setpoint_t1 != null &&
          vehicle.configurations &&
          vehicle.configurations.fridge_compartments_number >= 1
        "
        class="report-box mb-8"
      >
        <div class="box p-5">
          <!-- setpoints -->
          <div class="flex flex-wrap justify-around">
            <VehicleParameter
              :active="data.temperature.T1 !== null"
              :name="i18n.t('services.eyecold.temperature') + ' 1'"
              icon="ThermometerIcon"
              :setclass="
                () =>
                  tempInRange(1, data.temperature.T1)
                    ? 'bg-reyesol-temp-in-range'
                    : 'bg-reyesol-temp-off-range'
              "
              :value="data.temperature.T1"
              :format="helper.format_temp"
            />

            <VehicleParameter
              :active="
                vehicle.configurations &&
                vehicle.configurations.fridge_compartments_number >= 1 &&
                vehicle.configurations.fridge_temperature_sensors_number - 1 >=
                  vehicle.configurations.fridge_compartments_number &&
                data.temperature.T2 != null
              "
              :name="i18n.t('services.eyecold.temperature') + ' 2'"
              icon="ThermometerIcon"
              :setclass="
                () =>
                  tempInRange(1, data.temperature.T2)
                    ? 'bg-reyesol-temp-in-range'
                    : 'bg-reyesol-temp-off-range'
              "
              :value="data.temperature.T2"
              :format="helper.format_temp"
            />
            <VehicleParameter
              :active="
                vehicle.configurations &&
                vehicle.configurations.fridge_compartments_number >= 1 &&
                vehicle.configurations.fridge_temperature_sensors_number - 2 >=
                  vehicle.configurations.fridge_compartments_number &&
                data.temperature.T3 != null
              "
              :name="i18n.t('services.eyecold.temperature') + ' 3'"
              icon="ThermometerIcon"
              :setclass="
                () =>
                  tempInRange(1, data.temperature.T3)
                    ? 'bg-reyesol-temp-in-range'
                    : 'bg-reyesol-temp-off-range'
              "
              :value="data.temperature.T3"
              :format="helper.format_temp"
            />

            <VehicleParameter
              :active="
                vehicle.configurations &&
                vehicle.configurations.fridge_compartments_number >= 1 &&
                vehicle.configurations.fridge_temperature_sensors_number - 3 >=
                  vehicle.configurations.fridge_compartments_number &&
                data.temperature.T4 != null
              "
              :name="i18n.t('services.eyecold.temperature') + ' 4'"
              icon="ThermometerIcon"
              :setclass="
                () =>
                  tempInRange(1, data.temperature.T4)
                    ? 'bg-reyesol-temp-in-range'
                    : 'bg-reyesol-temp-off-range'
              "
              :value="data.temperature.T4"
              :format="helper.format_temp"
            />
            <VehicleParameter
              :active="
                data.fridge.operation_mode_c1 != null &&
                data.fridge.operation_mode_c1 < 6
              "
              :name="i18n.t('services.eyecold.operation_mode.name')"
              icon="HelpCircleIcon"
              :value="data.fridge.operation_mode_c1"
              :format="helper.format_compartment_operation_mode"
            />

            <VehicleParameter
              :active="data.fridge.setpoint_t1 !== null"
              :name="i18n.t('services.eyecold.setpoint') + ' 1'"
              icon="TargetIcon"
              :value="data.fridge.setpoint_t1"
              :format="helper.format_temp"
              :extraColumn="true"
            >
              <div
                v-show="data.fridge.setpoint_t1_dirty"
                class="inline p-1 text-xs text-theme-24 h-6 w-6"
              >
                <loading-icon icon="oval" />
              </div>
              <template v-slot:extra>
                <div
                  v-if="
                    vehicle.configurations &&
                    vehicle.configurations.eyecold_cmd_enabled &&
                    vehicle.configurations.eyecold_cmd_enabled == 'true'
                  "
                  class="dropdown"
                >
                  <template v-if="vehicle.status == 1">
                    <button
                      class="dropdown-toggle btn btn-sm btn-warning p-1 mt-2"
                      aria-expanded="false"
                    >
                      <span class="pr-1">{{
                        i18n.t("services.eyecontrol.name")
                      }}</span>
                      <SlidersIcon class="w-5 h-5" />
                    </button>
                    <div class="dropdown-menu w-80">
                      <div class="dropdown-menu__content box p-4">
                        <CmdRange
                          :initial-value="data.fridge.setpoint_t1"
                          :min="-35"
                          :max="35"
                          :step="1"
                          unit="°c"
                          :name="i18n.t('services.eyecold.cmd.setpoint_name')"
                          :description="
                            i18n.t('services.eyecold.cmd.setpoint_description')
                          "
                          @send="
                            (v) =>
                              sendFridgeCommand('setpoint', {
                                compartment: 1,
                                value: v,
                              })
                          "
                        />
                      </div>
                    </div>
                  </template>
                  <template v-else-if="vehicle.status == 0">
                    <button
                      class="btn btn-sm border-gray-500 p-1 mt-2"
                      aria-expanded="false"
                    >
                      <span class="pr-1">{{
                        i18n.t("services.eyecontrol.name")
                      }}</span>
                      <WifiOffIcon class="w-5 h-5" />
                    </button>
                  </template>
                </div>
                <div v-else>
                  <button
                    class="btn btn-sm border-gray-500 p-1 mt-2"
                    aria-expanded="false"
                  >
                    <span class="pr-1">{{
                      i18n.t("services.eyecontrol.name")
                    }}</span>
                    <LockIcon class="w-5 h-5" />
                  </button>
                </div>
              </template>
            </VehicleParameter>
          </div>
          <div class="pt-4 mt-4 border-t border-gray-400">
            <div class="flex justify-around">
              <div class="text-md text-gray-600 text-center">
                {{ i18n.t("services.eyecold.compartment") }}: 1
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="
          data.fridge &&
          data.fridge.setpoint_t2 != null &&
          vehicle.configurations &&
          vehicle.configurations.fridge_compartments_number >= 2
        "
        class="report-box mb-8"
      >
        <div class="box p-5">
          <!-- setpoints -->
          <div class="flex justify-around">
            <VehicleParameter
              :active="
                vehicle.configurations &&
                vehicle.configurations.fridge_compartments_number >= 2 &&
                vehicle.configurations.fridge_temperature_sensors_number >= 2 &&
                vehicle.configurations.fridge_temperature_sensors_number ==
                  vehicle.configurations.fridge_compartments_number &&
                data.temperature.T2 != null
              "
              :name="i18n.t('services.eyecold.temperature') + ' 2'"
              icon="ThermometerIcon"
              :setclass="
                () =>
                  tempInRange(2, data.temperature.T2)
                    ? 'bg-reyesol-temp-in-range'
                    : 'bg-reyesol-temp-off-range'
              "
              :value="data.temperature.T2"
              :format="helper.format_temp"
            />
            <VehicleParameter
              :active="
                vehicle.configurations &&
                vehicle.configurations.fridge_compartments_number >= 2 &&
                vehicle.configurations.fridge_temperature_sensors_number >= 3 &&
                vehicle.configurations.fridge_temperature_sensors_number - 1 ==
                  vehicle.configurations.fridge_compartments_number &&
                data.temperature.T3 != null
              "
              :name="i18n.t('services.eyecold.temperature') + ' 3'"
              icon="ThermometerIcon"
              :setclass="
                () =>
                  tempInRange(2, data.temperature.T3)
                    ? 'bg-reyesol-temp-in-range'
                    : 'bg-reyesol-temp-off-range'
              "
              :value="data.temperature.T3"
              :format="helper.format_temp"
            />

            <VehicleParameter
              :active="
                vehicle.configurations &&
                vehicle.configurations.fridge_compartments_number == 2 &&
                vehicle.configurations.fridge_temperature_sensors_number == 4 &&
                data.temperature.T4 != null
              "
              :name="i18n.t('services.eyecold.temperature') + ' 4'"
              icon="ThermometerIcon"
              :setclass="
                () =>
                  tempInRange(2, data.temperature.T4)
                    ? 'bg-reyesol-temp-in-range'
                    : 'bg-reyesol-temp-off-range'
              "
              :value="data.temperature.T4"
              :format="helper.format_temp"
            />
            <VehicleParameter
              :active="
                data.fridge.operation_mode_c2 != null &&
                data.fridge.operation_mode_c2 < 6
              "
              :name="i18n.t('services.eyecold.operation_mode.name')"
              icon="HelpCircleIcon"
              :value="data.fridge.operation_mode_c2"
              :format="helper.format_compartment_operation_mode"
            />

            <VehicleParameter
              :active="data.fridge.setpoint_t2 !== null"
              :name="i18n.t('services.eyecold.setpoint') + ' 2'"
              icon="TargetIcon"
              :value="data.fridge.setpoint_t2"
              :format="helper.format_temp"
              :extraColumn="true"
            >
              <div
                v-show="data.fridge.setpoint_t2_dirty"
                class="inline p-1 text-xs text-theme-24 h-6 w-6"
              >
                <loading-icon icon="oval" />
              </div>
              <template v-slot:extra>
                <div
                  v-if="
                    vehicle.configurations &&
                    vehicle.configurations.eyecold_cmd_enabled &&
                    vehicle.configurations.eyecold_cmd_enabled == 'true'
                  "
                  class="dropdown"
                >
                  <template v-if="vehicle.status == 1">
                    <button
                      class="dropdown-toggle btn btn-sm btn-warning p-1 mt-2"
                      aria-expanded="false"
                    >
                      <span class="pr-1">{{
                        i18n.t("services.eyecontrol.name")
                      }}</span>
                      <SlidersIcon class="w-5 h-5" />
                    </button>
                    <div class="dropdown-menu w-80">
                      <div class="dropdown-menu__content box p-4">
                        <CmdRange
                          :initial-value="data.fridge.setpoint_t2"
                          :min="-35"
                          :max="35"
                          :step="1"
                          unit="°c"
                          :name="i18n.t('services.eyecold.cmd.setpoint_name')"
                          :description="
                            i18n.t('services.eyecold.cmd.setpoint_description')
                          "
                          @send="
                            (v) =>
                              sendFridgeCommand('setpoint', {
                                compartment: 2,
                                value: v,
                              })
                          "
                        />
                      </div>
                    </div>
                  </template>
                  <template v-else-if="vehicle.status == 0">
                    <button
                      class="btn btn-sm border-gray-500 p-1 mt-2"
                      aria-expanded="false"
                    >
                      <span class="pr-1">{{
                        i18n.t("services.eyecontrol.name")
                      }}</span>
                      <WifiOffIcon class="w-5 h-5" />
                    </button>
                  </template>
                </div>
                <div v-else>
                  <button
                    class="btn btn-sm border-gray-500 p-1 mt-2"
                    aria-expanded="false"
                  >
                    <span class="pr-1">{{
                      i18n.t("services.eyecontrol.name")
                    }}</span>
                    <LockIcon class="w-5 h-5" />
                  </button>
                </div>
              </template>
            </VehicleParameter>
          </div>
          <div class="pt-4 mt-4 border-t border-gray-400">
            <div class="flex justify-around">
              <div class="text-md text-gray-600 text-center">
                {{ i18n.t("services.eyecold.compartment") }}: 2
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="
          data.fridge &&
          data.fridge.setpoint_t3 != null &&
          vehicle.configurations &&
          vehicle.configurations.fridge_compartments_number >= 3
        "
        class="report-box mb-8"
      >
        <div class="box p-5">
          <!-- setpoints -->
          <div class="flex justify-around">
            <VehicleParameter
              :active="
                vehicle.configurations &&
                vehicle.configurations.fridge_compartments_number >= 3 &&
                vehicle.configurations.fridge_temperature_sensors_number >= 3 &&
                vehicle.configurations.fridge_temperature_sensors_number ==
                  vehicle.configurations.fridge_compartments_number &&
                data.temperature.T3 != null
              "
              :name="i18n.t('services.eyecold.temperature') + ' 3'"
              icon="ThermometerIcon"
              :setclass="
                () =>
                  tempInRange(3, data.temperature.T3)
                    ? 'bg-reyesol-temp-in-range'
                    : 'bg-reyesol-temp-off-range'
              "
              :value="data.temperature.T3"
              :format="helper.format_temp"
            />

            <VehicleParameter
              :active="
                vehicle.configurations &&
                vehicle.configurations.fridge_compartments_number == 3 &&
                vehicle.configurations.fridge_temperature_sensors_number > 3 &&
                vehicle.configurations.fridge_temperature_sensors_number - 1 ==
                  vehicle.configurations.fridge_compartments_number &&
                data.temperature.T4 != null
              "
              :name="i18n.t('services.eyecold.temperature') + ' 4'"
              icon="ThermometerIcon"
              :setclass="
                () =>
                  tempInRange(3, data.temperature.T4)
                    ? 'bg-reyesol-temp-in-range'
                    : 'bg-reyesol-temp-off-range'
              "
              :value="data.temperature.T4"
              :format="helper.format_temp"
            />
            <VehicleParameter
              :active="
                data.fridge.operation_mode_c3 != null &&
                data.fridge.operation_mode_c3 < 6
              "
              :name="i18n.t('services.eyecold.operation_mode.name')"
              icon="HelpCircleIcon"
              :value="data.fridge.operation_mode_c3"
              :format="helper.format_compartment_operation_mode"
            />

            <VehicleParameter
              :active="data.fridge.setpoint_t3 !== null"
              :name="i18n.t('services.eyecold.setpoint') + ' 2'"
              icon="TargetIcon"
              :value="data.fridge.setpoint_t3"
              :format="helper.format_temp"
              :extraColumn="true"
            >
              <div
                v-show="data.fridge.setpoint_t3_dirty"
                class="inline p-1 text-xs text-theme-24 h-6 w-6"
              >
                <loading-icon icon="oval" />
              </div>
              <template v-slot:extra>
                <div
                  v-if="
                    vehicle.configurations &&
                    vehicle.configurations.eyecold_cmd_enabled &&
                    vehicle.configurations.eyecold_cmd_enabled == 'true'
                  "
                  class="dropdown"
                >
                  <template v-if="vehicle.status == 1">
                    <button
                      class="dropdown-toggle btn btn-sm btn-warning p-1 mt-2"
                      aria-expanded="false"
                    >
                      <span class="pr-1">{{
                        i18n.t("services.eyecontrol.name")
                      }}</span>
                      <SlidersIcon class="w-5 h-5" />
                    </button>
                    <div class="dropdown-menu w-80">
                      <div class="dropdown-menu__content box p-4">
                        <CmdRange
                          :initial-value="data.fridge.setpoint_t3"
                          :min="-35"
                          :max="35"
                          :step="1"
                          unit="°c"
                          :name="i18n.t('services.eyecold.cmd.setpoint_name')"
                          :description="
                            i18n.t('services.eyecold.cmd.setpoint_description')
                          "
                          @send="
                            (v) =>
                              sendFridgeCommand('setpoint', {
                                compartment: 3,
                                value: v,
                              })
                          "
                        />
                      </div>
                    </div>
                  </template>
                  <template v-else-if="vehicle.status == 0">
                    <button
                      class="btn btn-sm border-gray-500 p-1 mt-2"
                      aria-expanded="false"
                    >
                      <span class="pr-1">{{
                        i18n.t("services.eyecontrol.name")
                      }}</span>
                      <WifiOffIcon class="w-5 h-5" />
                    </button>
                  </template>
                </div>
                <div v-else>
                  <button
                    class="btn btn-sm border-gray-500 p-1 mt-2"
                    aria-expanded="false"
                  >
                    <span class="pr-1">{{
                      i18n.t("services.eyecontrol.name")
                    }}</span>
                    <LockIcon class="w-5 h-5" />
                  </button>
                </div>
              </template>
            </VehicleParameter>

            <VehicleParameter
              :active="data.fridge.operation_status_c3 !== null"
              :name="i18n.t('services.eyecold.operation_status.name')"
              :icon="data.fridge.operation_status_c3 ? 'ZapIcon' : 'ZapOffIcon'"
              :setclass="
                () =>
                  data.fridge.operation_status_c3
                    ? 'bg-reyesol-fridge-on'
                    : 'bg-reyesol-fridge-off'
              "
              :value="data.fridge.operation_status_c3"
              :format="helper.format_compartment_operation_status"
            />
          </div>
          <div class="pt-4 mt-4 border-t border-gray-400">
            <div class="flex justify-around">
              <div class="text-md text-gray-600 text-center">
                {{ i18n.t("services.eyecold.compartment") }}: 3
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="
          data.fridge &&
          data.fridge.setpoint_t1 == null &&
          data.fridge.setpoint_t2 == null &&
          data.fridge.setpoint_t3 == null &&
          data.temperature &&
          (data.temperature.T1 != null ||
            data.temperature.T2 != null ||
            data.temperature.T3 != null ||
            data.temperature.T4 != null)
        "
        class="report-box mb-8"
      >
        <div class="box p-5">
          <!-- setpoints -->
          <div class="flex justify-around">
            <VehicleParameter
              :active="data.temperature.T1 != null"
              :name="i18n.t('services.eyecold.temperature') + ' 1'"
              icon="ThermometerIcon"
              :setclass="
                () =>
                  tempInRange(1, data.temperature.T1)
                    ? 'bg-reyesol-temp-in-range'
                    : 'bg-reyesol-temp-off-range'
              "
              :value="data.temperature.T1"
              :format="helper.format_temp"
            />
            <VehicleParameter
              :active="
                vehicle.configurations.fridge_temperature_sensors_number > 1 &&
                data.temperature.T2 != null
              "
              :name="i18n.t('services.eyecold.temperature') + ' 2'"
              icon="ThermometerIcon"
              :setclass="
                () =>
                  tempInRange(1, data.temperature.T2)
                    ? 'bg-reyesol-temp-in-range'
                    : 'bg-reyesol-temp-off-range'
              "
              :value="data.temperature.T2"
              :format="helper.format_temp"
            />
            <VehicleParameter
              :active="
                vehicle.configurations.fridge_temperature_sensors_number > 2 &&
                data.temperature.T3 != null
              "
              :name="i18n.t('services.eyecold.temperature') + ' 3'"
              icon="ThermometerIcon"
              :setclass="
                () =>
                  tempInRange(1, data.temperature.T3)
                    ? 'bg-reyesol-temp-in-range'
                    : 'bg-reyesol-temp-off-range'
              "
              :value="data.temperature.T3"
              :format="helper.format_temp"
            />
            <VehicleParameter
              :active="
                vehicle.configurations.fridge_temperature_sensors_number > 3 &&
                data.temperature.T4 != null
              "
              :name="i18n.t('services.eyecold.temperature') + ' 4'"
              icon="ThermometerIcon"
              :setclass="
                () =>
                  tempInRange(1, data.temperature.T4)
                    ? 'bg-reyesol-temp-in-range'
                    : 'bg-reyesol-temp-off-range'
              "
              :value="data.temperature.T4"
              :format="helper.format_temp"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-else class="flex justify-center py-6">
      <loading-icon icon="rings" />
    </div>

    <div class="py-6">
      <div class="text-lg capitalize mb-4">
        {{ i18n.t("services.eyecold.temperatures_history") }}
        <Eyepicker v-model="daterange" />
      </div>
      <div v-if="!loadingHistory">
        <div
          v-if="
            history &&
            history.list &&
            history.list.temperature &&
            history.list.temperature.length > 0 &&
            history.list.fridge &&
            history.list.fridge.length > 0
          "
        >
          <TemperatureChart
            :active="active"
            :height="200"
            :width="200"
            :temperature="history.list.temperature"
            :fridge="history.list.fridge"
          />
        </div>
        <div v-else class="font-light text-xl text-gray-500">
          {{ i18n.t("general.no-results") }}
        </div>
        <div class="grid gap-2 grid-cols-2 mt-3">
          <div class="btn-reyesol-report" @click="requestReport">
            <FileTextIcon />
            <span class="truncate">{{ i18n.t("general.request-report") }}</span>
          </div>
          <div
            class="btn-reyesol-report-temp"
            @click="requestTemperatureReport"
          >
            <FileTextIcon />
            <span class="truncate">{{
              i18n.t("general.temperature-report")
            }}</span>
          </div>
        </div>
      </div>
      <div v-else class="flex justify-center py-6">
        <loading-icon icon="rings" class="w-20 h-20" />
      </div>
    </div>
  </div>
</template>

<script>
import TemperatureChart from "@/components/temperature-chart/TemperatureChart.vue";
import CmdOnOff from "@/components/commands/CmdOnOff.vue";
import CmdRange from "@/components/commands/CmdRange.vue";
import CmdSelect from "@/components/commands/CmdSelect.vue";
import CmdText from "@/components/commands/CmdText.vue";
import { watch, ref, computed, reactive, onUnmounted, onMounted } from "vue";
import { useI18n } from "vue3-i18n";
import { useStore } from "vuex";
import { useDaterange } from "@/composables/daterange";
import dayjs from "dayjs";
import { helper } from "@/utils/helper";
import EventBus from "@/libs/event-bus";
import VehicleParameter from "@/components/vehicle/VehicleParameter.vue";

export default {
  components: {
    TemperatureChart,
    CmdOnOff,
    CmdRange,
    CmdSelect,
    CmdText,
    VehicleParameter,
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const i18n = useI18n();
    const store = useStore();
    const vehicle = computed(() => store.getters["globalMap/selectedVehicle"]);
    const data = reactive(ref(null));
    const history = reactive(ref(null));
    const loadingHistory = ref(false);
    const voltage_warning = ref(false);
    const voltage_alert = ref(false);
    const {
      daterange,
      from: historyFrom,
      to: historyTo,
    } = useDaterange({
      onChange: getHistory,
    });
    const operating_modes = [
      { key: "1", label: "Start & Stop" },
      { key: "0", label: "Continuo" },
    ];

    function updateTemps(temp) {
      if (data.value == null) return;
      data.value.temperature = temp;

      if (
        dayjs().isSame(dayjs(historyTo.value), "day") &&
        history.value != null &&
        history.value.list &&
        history.value.list.temperature.length
      )
        history.value.list.temperature.unshift(temp);
    }
    function updateFridge(fridge) {
      if (data.value == null) return;
      data.value.fridge = fridge;

      if (
        dayjs().isSame(dayjs(historyTo.value), "day") &&
        history.value != null &&
        history.value.list &&
        history.value.list.fridge.length
      )
        history.value.list.fridge.unshift(fridge);
    }

    onMounted(() => {
      EventBus.on("vehicle:updates:temperature", updateTemps);
      EventBus.on("vehicle:updates:fridge", updateFridge);
    });
    onUnmounted(() => {
      EventBus.off("vehicle:updates:temperature");
      EventBus.off("vehicle:updates:fridge");
    });

    // watch service visibility
    watch(
      () => props.active,
      () => {
        if (props.active) initService();
        else destroyService();
      },
    );

    // init service
    async function initService() {
      const dataResult = await store.dispatch(
        "eyecold/getVehicleData",
        vehicle.value.id,
      );
      data.value = dataResult.validated;

      voltage_alert.value = vehicle.value.alerts.find((a) => a.alert_id == 36);
      voltage_warning.value = vehicle.value.alerts.find((a) => a.alert_id == 6);
      getHistory();
    }

    // destroy service
    function destroyService() {}

    async function requestReport() {
      emit(
        "report-request",
        []
          .concat(
            store.getters["globalMap/selectedVehicleSensors"].eyecold,
            store.getters[
              "globalMap/selectedVehicleSensors"
            ].eyecold_fridge.filter(
              (v) =>
                v == "eyecold_fridge.overall_status" ||
                v == "eyecold_fridge.alarm_status",
            ),
          )
          .flat(1),
        "master",
        []
          .concat(
            store.getters["globalMap/selectedVehicleSensors"].eyecold,
            store.getters[
              "globalMap/selectedVehicleSensors"
            ].eyecold_fridge.filter(
              (v) =>
                v == "eyecold_fridge.setpoint_t1" ||
                v == "eyecold_fridge.setpoint_t2" ||
                v == "eyecold_fridge.setpoint_t3" ||
                v == "eyecold_fridge.overall_status" ||
                v == "eyecold_fridge.alarm_status",
            ),
            store.getters["globalMap/selectedVehicleSensors"].eyedoor,
            store.getters["globalMap/selectedVehicleSensors"].eyecontrol,
          )
          .flat(1),
      );
    }

    // Temperature check
    function tempInRange(sensor, value, range = 5) {
      if (value === null) return false;
      if (vehicle.value.configurations.fridge_compartments_number == 1) {
        if (data.value.fridge.setpoint_t1 !== null) {
          if (
            value < data.value.fridge.setpoint_t1 - range ||
            value > data.value.fridge.setpoint_t1 + range
          )
            return false;
        }
      } else if (vehicle.value.configurations.fridge_compartments_number == 2) {
        if (
          sensor ==
          vehicle.value.configurations.fridge_temperature_sensors_number
        ) {
          if (data.value.fridge.setpoint_t2 !== null) {
            if (
              value < data.value.fridge.setpoint_t2 - range ||
              value > data.value.fridge.setpoint_t2 + range
            )
              return false;
          }
        } else {
          if (data.value.fridge.setpoint_t1 !== null) {
            if (
              value < data.value.fridge.setpoint_t1 - range ||
              value > data.value.fridge.setpoint_t1 + range
            )
              return false;
          }
        }
      } else if (vehicle.value.configurations.fridge_compartments_number >= 3) {
        if (
          sensor ==
          vehicle.value.configurations.fridge_temperature_sensors_number
        ) {
          if (data.value.fridge.setpoint_t3 !== null) {
            if (
              value < data.value.fridge.setpoint_t3 - range ||
              value > data.value.fridge.setpoint_t3 + range
            )
              return false;
          }
        } else if (
          sensor ==
          vehicle.value.configurations.fridge_temperature_sensors_number - 1
        ) {
          if (data.value.fridge.setpoint_t2 !== null) {
            if (
              value < data.value.fridge.setpoint_t2 - range ||
              value > data.value.fridge.setpoint_t2 + range
            )
              return false;
          }
        } else {
          if (data.value.fridge.setpoint_t1 !== null) {
            if (
              value < data.value.fridge.setpoint_t1 - range ||
              value > data.value.fridge.setpoint_t1 + range
            )
              return false;
          }
        }
      }
      return true;
    }

    // Temperature report request
    async function requestTemperatureReport() {
      emit(
        "report-request",
        [].concat(
          store.getters["globalMap/selectedVehicleSensors"].eyecold,
          store.getters["globalMap/selectedVehicleSensors"].eyedoor,
        ),
        "temp",
        Object.values(store.getters["globalMap/selectedVehicleSensors"]).flat(
          1,
        ),
      );
    }

    // get history
    async function getHistory() {
      if (!props.active) return;
      loadingHistory.value = true;

      const historyResult = await store.dispatch("eyecold/getVehicleHistory", {
        vehicleId: vehicle.value.id,
        dateFrom: dayjs(historyFrom.value).format(),
        dateTo: dayjs().isSame(dayjs(historyTo.value), "day")
          ? dayjs().format()
          : dayjs(historyTo.value).format(),
      });
      history.value = historyResult.validated;

      loadingHistory.value = false;
    }
    // fridge commands
    async function sendFridgeCommand(command, cmdData) {
      switch (command) {
        case "setpoint":
          {
            const result = await store.dispatch("eyecold/setSetpoint", {
              vehicleId: vehicle.value.id,
              compartment: cmdData.compartment,
              value: cmdData.value,
            });
            if (result.success) {
              switch (cmdData.compartment) {
                case 1:
                  data.value.fridge.setpoint_t1_dirty = true;
                  data.value.fridge.setpoint_t1 = cmdData.value;
                  break;
                case 2:
                  data.value.fridge.setpoint_t2_dirty = true;
                  data.value.fridge.setpoint_t2 = cmdData.value;
                  break;
                case 3:
                  data.value.fridge.setpoint_t3_dirty = true;
                  data.value.fridge.setpoint_t3 = cmdData.value;
                  break;
              }
              EventBus.emit("notification:show", {
                type: "info",
                title: i18n.t("services.eyecontrol.command_submitted"),
              });
            }
          }
          break;
        case "overall_status":
          {
            const result = await store.dispatch("eyecold/setOverallStatus", {
              vehicleId: vehicle.value.id,
              compartment: cmdData.compartment,
              value: cmdData.value,
            });
            if (result.success) {
              data.value.fridge.overall_status_dirty = true;
              data.value.fridge.overall_status = cmdData.value;
              EventBus.emit("notification:show", {
                type: "info",
                title: i18n.t("services.eyecontrol.command_submitted"),
              });
            }
          }
          break;

        case "operating_mode":
          {
            const result = await store.dispatch("eyecold/setOperatingMode", {
              vehicleId: vehicle.value.id,
              mode: cmdData,
            });
            if (result.success) {
              data.value.fridge.operating_mode_dirty = true;
              data.value.fridge.operating_mode = cmdData;
              EventBus.emit("notification:show", {
                type: "info",
                title: i18n.t("services.eyecontrol.command_submitted"),
              });
            }
          }
          break;
        case "odl_num":
          {
            const result = await store.dispatch("eyecold/setOdlNum", {
              vehicleId: vehicle.value.id,
              value: cmdData.value,
            });
            if (result.success) {
              vehicle.value.configurations.eyecold_fridge_odl_num =
                cmdData.value;
              EventBus.emit("notification:show", {
                type: "info",
                title: i18n.t("services.eyecontrol.command_submitted"),
              });
            }
          }
          break;
        case "odl_t1":
          {
            const result = await store.dispatch("eyecold/setOdlT1", {
              vehicleId: vehicle.value.id,
              value: cmdData.value,
            });
            if (result.success) {
              vehicle.value.configurations.eyecold_fridge_odl_t1 =
                cmdData.value;
              EventBus.emit("notification:show", {
                type: "info",
                title: i18n.t("services.eyecontrol.command_submitted"),
              });
            }
          }
          break;
      }
    }

    return {
      i18n,
      dayjs,
      vehicle,
      data,
      helper,
      history,
      loadingHistory,
      daterange,
      requestReport,
      requestTemperatureReport,
      sendFridgeCommand,
      operating_modes,
      tempInRange,
      voltage_alert,
      voltage_warning,
    };
  },
};
</script>

<style></style>
