import axios from "../libs/axios";
import { handleSuccess, handleError } from "./response-handler";
import { getResource } from "./rest-helper";
const namespace = "dashboard";
const api = {
  ...getResource(namespace),
  getDashboardData: async function () {
    try {
      const response = await axios.get(`${namespace}`);
      return handleSuccess({ namespace, action: "getDashboardData" }, response);
    } catch (error) {
      return handleError(
        { namespace, action: "getDashboardData" },
        error.response,
      );
    }
  },
};
export { api as default };
