<template>
  <div
    class="p-4 rounded-lg border-dashed border-2"
    :class="{
      [dropKey]: true,
      'border-reyesol-blue': canDrop,
    }"
    :data-uniqid="uid"
  >
    <slot />
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from "vue";
import EventBus from "@/libs/event-bus";
import uniqid from "uniqid";

export default {
  props: {
    dropKey: {
      type: String,
      required: true,
    },
  },

  emits: ["dropped"],

  setup(_, { emit }) {
    const uid = uniqid();
    const canDrop = ref(false);

    onMounted(() => {
      EventBus.on("draggable:can", ({ dropUid }) => {
        if (uid == dropUid) canDrop.value = true;
      });

      EventBus.on("draggable:cannot", () => {
        canDrop.value = false;
      });

      EventBus.on("draggable:drop", ({ dropUid, cardId }) => {
        if (uid == dropUid) emit("dropped", cardId);
      });
    });
    onUnmounted(() => {
      EventBus.off("draggable:can");
      EventBus.off("draggable:cannot");
      EventBus.off("draggable:drop");
    });

    return {
      uid,
      canDrop,
    };
  },
};
</script>

<style></style>
