import axios from "@/libs/axios";
import { handleSuccess, handleError } from "./response-handler";
const namespace = "notifications";
const api = {
  getAll: async function (page = null) {
    try {
      const params = page ? { page } : {};
      const response = await axios.get(`${namespace}`, { params });
      return handleSuccess({ namespace, action: "getAll" }, response);
    } catch (error) {
      return handleError({ namespace, action: "getAll" }, error.response);
    }
  },
  getUnreaded: async function () {
    try {
      const response = await axios.get(`${namespace}/unreaded`);
      return handleSuccess({ namespace, action: "getUnreaded" }, response);
    } catch (error) {
      return handleError({ namespace, action: "getUnreaded" }, error.response);
    }
  },
  getNotification: async function (id) {
    try {
      const response = await axios.get(`${namespace}/${id}`);
      return handleSuccess({ namespace, action: "getNotification" }, response);
    } catch (error) {
      return handleError(
        { namespace, action: "getNotification" },
        error.response,
      );
    }
  },
  markNotification: async function (id) {
    try {
      const response = await axios.put(`${namespace}/${id}`);
      return handleSuccess({ namespace, action: "getNotification" }, response);
    } catch (error) {
      return handleError(
        { namespace, action: "getNotification" },
        error.response,
      );
    }
  },
};
export { api as default };
