import i18n from "../i18n";
const state = () => {
  return {
    menu: [
      {
        icon: "HomeIcon",
        pageName: "dashboard",
        title: i18n.t("navigation.dashboard"),
      },
      // {
      //   icon: "ServerIcon",
      //   pageName: "services-dashboard",
      //   title: i18n.t("navigation.services"),
      //   subMenu: [
      //     {
      //       icon: "XCircleIcon",
      //       pageName: "eyegeo-dashboard",
      //       title: i18n.t("services.eyegeo.name")
      //     },
      //     {
      //       icon: "XCircleIcon",
      //       pageName: "eyecold-dashboard",
      //       title: "Eyecold"
      //     },
      //     {
      //       icon: "XCircleIcon",
      //       pageName: "eyeway-dashboard",
      //       title: "Eyeway"
      //     },
      //     {
      //       icon: "XCircleIcon",
      //       pageName: "carbontax-dashboard",
      //       title: "Carbontax"
      //     },
      //     {
      //       icon: "XCircleIcon",
      //       pageName: "eyefuel-dashboard",
      //       title: "Eyefuel"
      //     },
      //     {
      //       icon: "XCircleIcon",
      //       pageName: "eyelink-dashboard",
      //       title: "Eyelink"
      //     },
      //     {
      //       icon: "XCircleIcon",
      //       pageName: "eyepharma-dashboard",
      //       title: "Eyepharma"
      //     },
      //     {
      //       icon: "XCircleIcon",
      //       pageName: "eyeservice-dashboard",
      //       title: "Eyeservice"
      //     },
      //     {
      //       icon: "XCircleIcon",
      //       pageName: "eyeshare-dashboard",
      //       title: "Eyeshare"
      //     },
      //     {
      //       icon: "XCircleIcon",
      //       pageName: "f-gas-dashboard",
      //       title: "F-Gas"
      //     },
      //     {
      //       icon: "XCircleIcon",
      //       pageName: "geofencing-dashboard",
      //       title: "Geofencing"
      //     }
      //   ]
      // },
      {
        icon: "ShieldIcon",
        pageName: "hubs-index",
        title: i18n.t("navigation.hubs"),
      },
      {
        icon: "TagIcon",
        pageName: "pois-index",
        title: i18n.t("navigation.pois"),
      },
      {
        icon: "FlagIcon",
        pageName: "fleets-index",
        title: i18n.t("navigation.fleets"),
      },
      {
        icon: "DollarSignIcon",
        pageName: "tcos-index",
        title: i18n.t("navigation.tcos"),
      },
      {
        icon: "UsersIcon",
        pageName: "drivers-activity-index",
        title: i18n.t("navigation.drivers"),
      },
    ],
  };
};

// getters
const getters = {
  menu: (state) => state.menu,
};

// actions
const actions = {};

// mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
