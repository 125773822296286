<template>
  <div class="rounded-2xl">
    <div class="relative h-[34rem] md:h-screen w-full">
      <div ref="mapEl" class="absolute z-10 w-full h-full"></div>
      <div
        class="absolute right-1 md:left-1/2 md:transform md:-translate-x-1/2 z-20 top-3 w-4/5 flex flex-col items-center justify-center"
      >
        <!-- select mode  -->
        <div
          v-show="selectModeUniqid"
          class="w-full flex items-center justify-center mb-2"
        >
          <h2
            class="text-xl bg-theme-20 text-white h-full px-8 py-1 rounded-l-full"
          >
            {{ i18n.t("map.select-point") }}
          </h2>
          <button
            v-if="selectedPoint"
            class="btn btn-success rounded-none"
            @click="confirmSelection"
          >
            <CheckIcon class="h-4 w-4" />
          </button>
          <button
            class="btn btn-danger rounded-l-none rounded-r-full"
            @click="deactivateSelectMode"
          >
            <XIcon class="h-4 w-4" />
          </button>
        </div>

        <!-- search bar -->
        <div v-if="showSearch" class="w-full flex-row md:flex">
          <div
            v-if="Array.isArray(showSearch) && showSearch.length > 1"
            class="flex-1 md:flex-none w-full md:w-32 mr-1 mb-0"
          >
            <select v-model="searchType" class="form-select">
              <option value="geocoding">
                {{ i18n.t("map.option-geocoding") }}
              </option>
              <option value="vehicles">
                {{ i18n.t("map.option-vehicles") }}
              </option>
            </select>
          </div>
          <geocoding-input
            v-if="searchType == 'geocoding'"
            :placeholder="i18n.t('map.search-geocoding')"
            class="flex-1"
            @point="handlePointSearch"
          />
          <vehicles-input
            v-if="searchType == 'vehicles'"
            :placeholder="i18n.t('map.search-vehicles')"
            class="flex-1"
            @vehicle="handleVehicleSearch"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, nextTick, onUnmounted } from "vue";
import { useI18n } from "vue3-i18n";
import { MapController, getMarkerIcon } from "@/services/map";
import { geoReverseSearch } from "@/services/geocoder";
import EventBus from "@/libs/event-bus";
import dayjs from "dayjs";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  props: {
    showSearch: {
      type: [Boolean, Array],
      default: false,
    },
    coordinates: {
      type: Object,
      default: function () {
        return { lat: 43.373, lng: 10.393 };
      },
    },
    zoom: { type: Number, default: 6 },
  },
  emits: [
    "init",
    "point:search",
    "marker:click",
    "circle:init",
    "circle:click",
    "path:click",
    "pathmarker:click",
  ],
  setup(props, { emit }) {
    const i18n = useI18n();
    const mapEl = ref(null);
    const map = ref(null);
    const markers = ref([]);
    const markersCluster = ref(null);
    const circles = ref([]);
    const paths = ref([]);
    const store = useStore();
    const router = useRouter();
    onMounted(() => {
      EventBus.on("map:select", activateSelectMode);
      EventBus.on("popup:select", onPopupClick);
      EventBus.on("poi:select", onPoiClick);
      EventBus.on("hub:select", onHubClick);
      emit("init", controller);
      initMap(props.coordinates, props.zoom);
      bindEvents();
    });

    onUnmounted(() => {
      EventBus.off("map:select");
      EventBus.off("popup:select");
      EventBus.off("poi:select");
      EventBus.off("hub:select");
    });

    // Leaflet globals
    const L = window.L;

    // init controller
    const controller = new MapController(controllerNotifier);
    function controllerNotifier(type, data, group) {
      switch (type) {
        case "map:init":
          goToLocation(43.373, 10.393, 6);
          map.value.invalidateSize();
          break;
        case "map:resize":
          nextTick(() => {
            if (map.value) map.value.invalidateSize();
          });
          break;
        case "markers:fly":
          flyToMarker(data, group);
          break;
        case "date:fly":
          flyToDate(data, group);
          break;
        case "markers:count":
          return countMarkers(group);
        case "markers:add:single":
          addMarker(data);
          break;
        case "markers:remove:single":
          removeMarker(data, group);
          break;
        case "markers:clear":
          clearMarkers(group);
          break;
        case "circles:fly":
          flyToCircle(data, group);
          break;
        case "circles:add:single":
          addCircle(data);
          break;
        case "circles:remove:single":
          removeCircle(data, group);
          break;
        case "circles:clear":
          clearCircles(group);
          break;
        case "path:fly":
          flyToPath(data, group);
          break;
        case "path:add:single":
          addPath(data);
          break;
        case "path:remove:single":
          removePath(data, group);
          break;
        case "path:clear":
          clearPaths(group);
          break;
        case "group:clear":
          clearGroup(group);
          break;
      }
    }
    function normalize(lat, lang) {
      return [parseFloat(lat), parseFloat(lang)];
    }
    function normalizeOne(latlang) {
      return parseFloat(latlang);
    }
    // init map
    function initMap(coordinates, zoom) {
      const store = useStore();
      const customer = store.getters["auth/customer"];

      map.value = L.map(mapEl.value, {
        center: [coordinates.lat, coordinates.lng],
        zoom: zoom,
        scrollWheelZoom: false,
        zoomAnimation: false,
      });

      map.value.on("click", () => {
        map.value.scrollWheelZoom.enable();
      });
      map.value.on("mouseout", () => {
        map.value.scrollWheelZoom.disable();
      });

      map.value.on("zoomend", function () {
        const currentZoom = map.value.getZoom();
        markers.value.map((m) => {
          /*if (m.group == "service") {
            m.mInstance.setOpacity(currentZoom >= 12 ? 1 : 0);
          }*/
          console.log("Current zoom:", currentZoom, m);
        });
      });
      let tile_server;
      switch (store.getters["main/language"]) {
        case "it":
          tile_server = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
          //tile_server = "https://openstreetmaptileserver.viaggiaresicuri.it/osm_tiles/{z}/{x}/{y}.png?";
          break;
        case "en":
        case "uk":
          tile_server = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
          break;
        case "de":
          tile_server = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
          break;
        case "es":
          tile_server = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
          break;
        case "fr":
          tile_server = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
          break;
        default:
          tile_server = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
      }
      const osm = L.tileLayer(tile_server, {
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      }).addTo(map.value);
      if (customer.configurations.maps_view_satellite == 1) {
        const satellite = L.tileLayer(
          "https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}",
          {
            maxZoom: 20,
            subdomains: ["mt0", "mt1", "mt2", "mt3"],
          },
        );
        const baseMaps = {
          Standard: osm,
          Satellite: satellite,
        };

        L.control.layers(baseMaps).addTo(map.value);
      }

      markersCluster.value = L.markerClusterGroup({
        iconCreateFunction: function (cluster) {
          const clusterIcon = L.divIcon({
            className: "marker-cluster-icon",
            html: `<b>${cluster.getChildCount()}</b>`,
          });
          return clusterIcon;
        },
      });
      map.value.addLayer(markersCluster.value);
    }

    // handlers
    function bindEvents() {
      map.value.on("click", mapClickHandler);
    }

    function mapClickHandler(ev) {
      if (selectModeUniqid.value) select(ev);
    }

    function markerClickHandler(ev, marker) {
      emit("marker:click", { ev, marker });
    }

    function circleClickHandler(ev, circle) {
      emit("circle:click", { ev, circle });
    }

    function circleInitHandler(instance, circle, map) {
      emit("circle:init", { instance, circle, map });
    }

    function pathClickHandler(ev, path) {
      emit("path:click", { ev, path });
    }

    function pathMarkerClickHandler(ev, path) {
      emit("pathmarker:click", { ev, path });
    }

    // markers
    function flyToDate(datetime, markerGroup = null) {
      let last_minutes = null;
      const req_dayjs = dayjs(datetime);
      let i = 0;
      let markers_list;

      if (markerGroup)
        markers_list = markers.value.filter((m) => m.group == markerGroup);
      else markers_list = markers.value;

      markers_list.sort((a, b) => dayjs(a.datetime) - dayjs(b.datetime));

      for (i = 0; i < markers_list.length; i++) {
        const m = markers_list[i];
        const marker_dayjs = dayjs(m.datetime);

        if (
          m.group == markerGroup &&
          m.datetime != null &&
          marker_dayjs.isSame(req_dayjs)
        ) {
          break;
        } else {
          const minutes = Math.abs(marker_dayjs.diff(req_dayjs, "minutes"));

          if (last_minutes != null && minutes > last_minutes) {
            i--;
            break;
          }
          last_minutes = minutes;
        }
      }

      if (i === markers_list.length) {
        // get last
        if (i > 0) i--;
        else return;
      }
      const marker = markers_list[i];
      flyToMarker(marker.id, markerGroup);
    }

    // check
    function countMarkers(markerGroup = null) {
      if (markerGroup == null) return markers.value.length;
      return markers.value.reduce(
        (total, m) => (m.group == markerGroup ? total + 1 : total),
        0,
      );
    }

    // markers
    function flyToMarker(markerId, markerGroup = null) {
      if (markerGroup) markerId = `${markerGroup}:${markerId}`;
      markers.value.map((m) => {
        if (m.group == markerGroup && m.mInstance)
          m.mInstance.setIcon(getMarkerIcon(m.icon));
      });
      const i = markers.value.findIndex((m) => m.uid == markerId);
      if (i === -1) return;
      const marker = markers.value[i];
      if (marker.mInstance) marker.mInstance.setIcon(getMarkerIcon());
      goToLocation(
        normalizeOne(marker.latitude),
        normalizeOne(marker.longitude),
        15,
      );
    }

    function addMarker(marker) {
      marker.uid = getUid(marker);

      if (
        markers.value.findIndex((m) => m.uid == marker.uid) !== -1 ||
        marker.id == null ||
        marker.latitude == null ||
        marker.longitude == null
      )
        return;

      const instance = createMarker(marker);
      instance.on("click", (ev) => markerClickHandler(ev, marker));
      if (marker.group == "pins") markersCluster.value.addLayer(instance);
      else instance.addTo(map.value);
      markers.value.push({ ...marker, instance });
    }

    function removeMarker(markerId, markerGroup = null) {
      if (markerGroup) markerId = `${markerGroup}:${markerId}`;

      const i = markers.value.findIndex((m) => m.uid == markerId);
      if (i === -1) return;
      if (markers.value[i].instance) markers.value[i].instance.remove();
      markers.value.splice(i, 1);
    }

    function clearMarkers(group = null) {
      if (group == null) {
        markersCluster.value && markersCluster.value.clearLayers();
        markers.value.forEach(function (m) {
          if (m.instance) m.instance.remove();
        });
        markers.value = [];
        return;
      }
      markers.value.forEach((m) => {
        if (m.group == group && m.instance) {
          markersCluster.value && markersCluster.value.removeLayer(m.instance);
          m.instance.remove();
        }
      });
      markers.value = markers.value.filter((m) => m.group != group);
    }

    function createMarker(m) {
      const markerIcon =
        "icon" in m
          ? "color" in m
            ? getMarkerIcon(m.icon, m.color, m.additionalData)
            : getMarkerIcon(m.icon, null, m.additionalData)
          : getMarkerIcon(null, null, m.additionalData);

      const instance = L.marker(normalize(m.latitude, m.longitude), {
        icon: markerIcon,
      });

      if ("popup" in m) {
        const popup = L.popup({
          offset: [-12.5, -35],
          minWidth: 300,
        })
          .setLatLng(normalize(m.latitude, m.longitude))
          .setContent(m.popup.content);

        instance.bindPopup(popup);
        instance.on("popupopen", () => {});
        instance.on("mouseover", () => {
          if (!popup.isOpen()) instance.openPopup();
        });
        //instance.on("mouseout", () => instance.closePopup());
      }

      return instance;
    }

    // circles
    function flyToCircle(circleId, circleGroup = null) {
      if (circleGroup) circleId = `${circleGroup}:${circleId}`;

      const i = circles.value.findIndex((m) => m.uid == circleId);
      if (i === -1) return;
      const circle = circles.value[i];
      goToLocation(
        normalizeOne(circle.latitude),
        normalizeOne(circle.longitude),
      );
    }

    function addCircle(circle) {
      circle.uid = getUid(circle);

      if (
        circles.value.findIndex((c) => c.uid == circle.uid) !== -1 ||
        circle.id == null ||
        circle.latitude == null ||
        circle.longitude == null
      )
        return;

      const instance = createCircle(circle);
      circleInitHandler(instance, circle, map);
      instance.on("click", (ev) => circleClickHandler(ev, circle));
      instance.addTo(map.value);

      if ("popup" in circle) {
        const popup = L.popup({
          offset: [0, 0],
          minWidth: 100,
        })
          .setLatLng(normalize(circle.latitude, circle.longitude))
          .setContent(circle.popup.content);

        instance.bindPopup(popup);
        instance.on("popupopen", () => {});
        instance.on("mouseover", () => {
          if (!popup.isOpen()) instance.openPopup();
        });
        //instance.on("mouseout", () => instance.closePopup());
      }

      circles.value.push({ ...circle, instance });
    }

    function removeCircle(circleId, circleGroup = null) {
      if (circleGroup) circleId = `${circleGroup}:${circleId}`;

      const i = circles.value.findIndex((c) => c.uid == circleId);
      if (i === -1) return;
      circles.value[i].instance.remove();
      circles.value.splice(i, 1);
    }

    function clearCircles(group = null) {
      if (group == null) {
        circles.value.forEach((c) => c.instance.remove());
        circles.value = [];
        return;
      }

      circles.value.forEach((c) =>
        c.group == group ? c.instance.remove() : null,
      );
      circles.value = circles.value.filter((c) => c.group != group);
    }

    function createCircle(c) {
      return L.circle(normalize(c.latitude, c.longitude), {
        radius: c.radius,
        color: c.color,
        fillColor: c.color,
        fillOpacity: 0.5,
      });
    }

    // paths
    function flyToPath(pathId, pathGroup = null) {
      if (pathGroup) pathId = `${pathGroup}:${pathId}`;

      const i = paths.value.findIndex((m) => m.uid == pathId);
      if (i === -1) return;
      const path = paths.value[i];
      map.value.fitBounds(path.instance.getBounds(), true);
    }

    function addPath(path) {
      path.uid = getUid(path);
      console.log("addPath", path.markers.length);
      path.markers = path.markers.filter(
        (m) => Math.round(m.latitude) && Math.round(m.longitude),
      );
      console.log("addPath after", path.markers.length, path.markers);

      if (
        paths.value.findIndex((p) => p.uid == path.uid) !== -1 ||
        path.id == null ||
        path.markers.length == 0
      )
        return;

      const markersInstances = [];

      for (let i = 0; i < path.markers.length; i++) {
        const marker = path.markers[i];
        marker.uid = getUid(marker);
        const mInstance = createMarker(marker);
        markersInstances.push(mInstance);
        mInstance.on("click", (ev) => pathMarkerClickHandler(ev, marker));
        mInstance.addTo(map.value);
        markers.value.push({ ...marker, mInstance });
      }

      const instance = createPolyline(path);
      instance.addTo(map.value);

      instance.on("click", (ev) => pathClickHandler(ev, path));
      instance.addTo(map.value);

      paths.value.push({ ...path, instance, markersInstances });
    }

    function removePath(pathId, pathGroup = null) {
      if (pathGroup) pathId = `${pathGroup}:${pathId}`;

      const i = paths.value.findIndex((c) => c.uid == pathId);
      if (i === -1) return;
      paths.value[i].instance.remove();
      paths.value[i].markersInstances.forEach(function (mI) {
        if (mI) {
          mI.remove();
        }
      });
      paths.value.splice(i, 1);
    }

    function clearPaths(group = null) {
      if (group == null) {
        paths.value.forEach((p) => {
          p.instance.remove();
          p.markersInstances.forEach(function (mI) {
            if (mI) {
              mI.remove();
            }
          });
        });
        paths.value = [];
      }

      paths.value.forEach((p) => {
        if (p.group == group) {
          p.instance.remove();
          p.markersInstances.forEach(function (mI) {
            if (mI) {
              mI.remove();
            }
          });
        }
      });
      paths.value = paths.value.filter((p) => p.group != group);
    }

    function createPolyline(p) {
      const opts = {};
      const defaults = {
        isAnt: true,
        dashOffset: 0,
        opacity: 0.75,
        smoothFactor: 10,
        noClip: true,
        delay: 3000,
        dashArray: [30, 30],
        weight: 10,
        color: "#0094da",
        pulseColor: "#FFFFFF",
        paused: false,
        reverse: false,
        hardwareAccelerated: true,
      };
      Object.assign(opts, defaults, p);
      if (opts.isAnt)
        return L.polyline.antPath(
          [...p.markers.map((m) => normalize(m.latitude, m.longitude))],
          opts,
        );
      else
        return L.polyline(
          [...p.markers.map((m) => normalize(m.latitude, m.longitude))],
          opts,
        );
    }

    // groups
    function clearGroup(group) {
      clearMarkers(group);
      clearCircles(group);
      clearPaths(group);
    }

    // utils
    function getUid(element) {
      if (element.id && element.group) return `${element.group}:${element.id}`;
      if (element.id) return element.id;
      return null;
    }

    function goToLocation(lat, lng, zoom = 15) {
      if (lat == null || lng == null) return;
      map.value.flyTo([lat, lng], zoom, { animate: true });
    }

    // search
    const searchType = ref(
      Array.isArray(props.showSearch) && props.showSearch.length
        ? props.showSearch[0]
        : "geocoding",
    );

    function handlePointSearch(place) {
      goToLocation(
        normalizeOne(place.latitude),
        normalizeOne(place.longitude),
        15,
      );
      emit("point:search", { place });
    }

    function handleVehicleSearch(pin) {
      goToLocation(normalizeOne(pin.latitude), normalizeOne(pin.longitude), 15);
      const marker = markers.value.find(
        (m) => m.uid == `pins:${pin.vehicle_id}`,
      );
      if (marker) emit("marker:click", { marker });
    }

    // select mode
    const selectModeUniqid = ref(null);
    const selectedPoint = ref(null);
    const selectedMarker = ref(null);

    function activateSelectMode(uniqid) {
      selectModeUniqid.value = uniqid;
    }

    function deactivateSelectMode() {
      selectModeUniqid.value = null;
      selectedPoint.value = null;
      if (selectedMarker.value) selectedMarker.value.remove();
      selectedMarker.value = null;
    }

    async function select(ev) {
      const lat = ev.latlng.lat;
      const lng = ev.latlng.lng;

      const result = await geoReverseSearch(lat, lng);
      if (!result) return;

      selectedPoint.value = {
        address: result.address.road ?? "###",
        city: result.address.city ?? result.address.village ?? "###",
        zip_code: result.address.postcode ?? "###",
        latitude: lat,
        longitude: lng,
      };

      if (selectedMarker.value) selectedMarker.value.remove();
      selectedMarker.value = createMarker({
        latitude: lat,
        longitude: lng,
        popup: {
          content: `<b>${lat}<br>${lng}</b><br>${result.display_name}`,
        },
      });
      selectedMarker.value.addTo(map.value);
    }

    function confirmSelection() {
      EventBus.emit("map:point:selected", {
        uniqid: selectModeUniqid.value,
        point: {
          ...selectedPoint.value,
        },
      });
      selectModeUniqid.value = null;
      selectedPoint.value = null;
      selectedMarker.value.remove();
      selectedMarker.value = null;
    }

    function onPopupClick(vehicle) {
      router.push({ path: "/global-map", query: { v: vehicle } });
    }

    function onPoiClick(poi) {
      router.push({ path: "/global-map", query: { p: poi.id } });
      store.dispatch("pois/setExternalSelected", poi);
    }

    function onHubClick(hub) {
      router.push({ path: "/global-map", query: { h: hub.id } });
      store.dispatch("hubs/setExternalSelected", hub);
    }

    return {
      i18n,
      mapEl,
      map,
      searchType,
      handlePointSearch,
      handleVehicleSearch,
      selectedPoint,
      selectModeUniqid,
      deactivateSelectMode,
      select,
      confirmSelection,
      onPopupClick,
      onPoiClick,
      onHubClick,
    };
  },
};
</script>

<style lang="scss">
.marker-cluster-icon {
  display: flex !important;
  align-items: center;
  justify-content: center;
  background-image: url("../../assets/images/map-marker-region.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 4rem !important;
  height: 4rem !important;

  b {
    color: white;
  }
}

.marker-vehicle-icon {
  background-color: transparent;
  border: none;

  .marker-vehicle-icon-svg {
    @apply h-8 rounded-sm p-2 shadow flex items-center;

    svg {
      @apply h-6 w-6;
    }
  }
}
</style>
