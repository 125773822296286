<template>
  <div class="relative">
    <input
      v-model="vehiclesQuery"
      type="text"
      class="form-control w-full"
      :placeholder="placeholder"
      @input="vehiclesSearch()"
      @focus="handleFocus()"
      @blur="handleBlur()"
      @keypress.enter="selectVehicle()"
      @keydown.up="goToNextResult(-1)"
      @keydown.down="goToNextResult(1)"
    />
    <div
      v-show="focused && vehiclesQuery != '' && !vehiclesResults.length"
      class="absolute intro-y bg-white rounded-md border border-gray-200 top-2"
    >
      <div class="text-gray-500 cursor-pointer rounded-md p-2 bg-white">
        {{ i18n.t("general.no-results") }}
      </div>
    </div>
    <div
      v-show="vehiclesResults.length"
      class="absolute intro-y bg-white rounded-md border border-gray-200 top-2"
      @mouseleave="goToResult()"
    >
      <div
        v-for="(r, i) in vehiclesResults"
        :key="r.id"
        class="text-gray-500 cursor-pointer rounded-md p-2 bg-white"
        :class="{
          'bg-theme-20': activeResultIndex == i,
        }"
        :style="{
          color: activeResultIndex == i ? 'black' : 'rgba(160, 174, 192)',
        }"
        @click="selectVehicle(r.id)"
        @mouseover="goToResult(i)"
      >
        <span class="font-bold mr-2">
          {{ r.plate }}
        </span>
        <span class="text-xs italic">
          {{ dayjs(r.gps_datetime).format("DD/MM/YYYY HH:mm") }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue3-i18n";
import { useFleetFilter } from "@/composables/filters";
import dayjs from "dayjs";
export default {
  props: {
    placeholder: {
      type: String,
      default: "",
    },
  },

  emits: ["vehicle"],

  setup(_, { emit }) {
    const i18n = useI18n();
    const store = useStore();
    const focused = ref(false);
    const vehiclesQuery = ref("");
    const vehiclesResults = ref([]);
    const { filterCollection: filterVehicles } = useFleetFilter();

    async function vehiclesSearch() {
      if (vehiclesQuery.value == "") {
        vehiclesResults.value = [];
        return;
      }

      let vehicles = await store.getters["globalMap/pins"];
      if (!vehicles.length) {
        vehicles = (await store.dispatch("globalMap/getPins")).validated;
      }
      vehiclesResults.value = filterVehicles(vehicles)
        .filter((v) => {
          return v.plate
            .toLowerCase()
            .includes(vehiclesQuery.value.toLowerCase());
        })
        .slice(0, 9);
    }

    // focus handling
    function handleFocus() {
      vehiclesSearch();
      focused.value = true;
    }

    function handleBlur() {
      cancelSelect();
      focused.value = false;
    }

    // result handling
    const activeResultIndex = ref(null);

    function goToNextResult(indexIncrement) {
      if (!vehiclesResults.value.length) return;
      if (activeResultIndex.value == null) {
        if (indexIncrement > 0) return (activeResultIndex.value = 0);
        if (indexIncrement < 0)
          return (activeResultIndex.value = vehiclesResults.value.length - 1);
      }

      if (indexIncrement > 0)
        return (activeResultIndex.value =
          activeResultIndex.value < vehiclesResults.value.length - 1
            ? activeResultIndex.value + 1
            : 0);
      if (indexIncrement < 0)
        return (activeResultIndex.value =
          activeResultIndex.value > 0
            ? activeResultIndex.value - 1
            : vehiclesResults.value.length - 1);
    }

    function goToResult(vehiclesResultIndex) {
      activeResultIndex.value = vehiclesResultIndex ?? null;
    }

    function selectVehicle(vehiclesResult) {
      let g = vehiclesResult;

      if (vehiclesResult == null) {
        if (vehiclesResults.value.length == 0) return;
        if (activeResultIndex.value == null) {
          g = vehiclesResults.value[0];
        } else {
          g = vehiclesResults.value[activeResultIndex.value];
        }
      }

      emit("vehicle", g);
      activeResultIndex.value = null;
      vehiclesResults.value = [];
    }

    function cancelSelect() {
      if (activeResultIndex.value == null) vehiclesResults.value = [];
    }

    return {
      i18n,
      dayjs,
      vehiclesQuery,
      vehiclesResults,
      vehiclesSearch,
      handleFocus,
      handleBlur,
      focused,
      selectVehicle,
      cancelSelect,
      activeResultIndex,
      goToNextResult,
      goToResult,
    };
  },
};
</script>

<style></style>
