import axios from "@/libs/axios";
import { handleSuccess, handleError } from "./response-handler";
import { getResource } from "./rest-helper";

const namespace = "reports";
const api = {
  ...getResource(namespace),
  getUnreaded: async function () {
    try {
      const response = await axios.get(`${namespace}/unreaded`);
      return handleSuccess({ namespace, action: "getUnreaded" }, response);
    } catch (error) {
      return handleError({ namespace, action: "getUnreaded" }, error.response);
    }
  },
  getReport: async function (id) {
    try {
      const response = await axios.get(`${namespace}/${id}`);
      return handleSuccess({ namespace, action: "getReport" }, response);
    } catch (error) {
      return handleError({ namespace, action: "getReport" }, error.response);
    }
  },
  getJson: async function (id) {
    try {
      const response = await axios.get(`${namespace}/${id}/json`, {
        responseType: "blob",
      });
      return response.data;
    } catch (error) {
      return handleError({ namespace, action: "getJson" }, error.response);
    }
  },
  downloadReport: async function (id) {
    try {
      const response = await axios.get(`${namespace}/${id}/download`, {
        responseType: "arraybuffer",
      });
      return response.data;
    } catch (error) {
      return handleError(
        { namespace, action: "downloadReport" },
        error.response,
      );
    }
  },
  linkReport: async function (id, hash) {
    try {
      window.open(
        axios.defaults.baseURL + `${namespace}/${id}/link/${hash}`,
        "_blank",
      );
      return { success: true };
    } catch (error) {
      return handleError(
        { namespace, action: "downloadReport" },
        error.response,
      );
    }
  },
  markReport: async function (id) {
    try {
      const response = await axios.put(`${namespace}/${id}`);
      return handleSuccess({ namespace, action: "markReport" }, response);
    } catch (error) {
      return handleError({ namespace, action: "markReport" }, error.response);
    }
  },
  markAll: async function () {
    try {
      const response = await axios.put(`${namespace}/markall`);
      return handleSuccess({ namespace, action: "markAll" }, response);
    } catch (error) {
      return handleError({ namespace, action: "markAll" }, error.response);
    }
  },
  requestReport: async function (vehicleId, services, from, to, type) {
    try {
      const response = await axios.post(`${namespace}`, {
        vehicle_id: vehicleId,
        services: services,
        period_from: from,
        period_to: to,
        type: type,
      });
      return handleSuccess({ namespace, action: "requestReport" }, response);
    } catch (error) {
      return handleError(
        { namespace, action: "requestReport" },
        error.response,
      );
    }
  },
};
export { api as default };
