<template>
  <validated-input :has-error="v$.$error" :errors="v$.$errors" class="mb-3">
    <input
      v-model="internalValue"
      type="text"
      :disabled="disabled"
      class="form-control"
      @input="handleInput"
    />
  </validated-input>
</template>

<script>
import { computed, ref, watch } from "vue";
import useVuelidate from "@vuelidate/core";
import { required, email, numeric } from "@vuelidate/validators";
export default {
  props: ["modelValue", "channelId", "channels", "disabled", "required"],
  emits: ["input", "update:modelValue"],
  setup(props, { emit }) {
    const internalValue = ref(props.modelValue);
    watch(
      () => props.modelValue,
      (val) => (internalValue.value = val),
    );
    function handleInput() {
      emit("update:modelValue", internalValue.value);
      emit("input", internalValue.value);
    }
    const validationRules = computed(() => {
      const destinationValidators = props.required ? { required } : {};
      if (props.channels) {
        switch (props.channelId) {
          case props.channels.find((c) => c.code === "email").id:
            destinationValidators.email = email;
            break;
          case props.channels.find((c) => c.code === "sms").id:
            destinationValidators.numeric = numeric;
            break;
        }
      }
      return destinationValidators;
    });

    const v$ = useVuelidate(validationRules, internalValue);
    return { internalValue, handleInput, v$ };
  },
};
</script>

<style></style>
