<template>
  <div id="reports">
    <div class="flex justify-between items-center py-2a">
      <simple-title>{{ i18n.t("general.reports") }}</simple-title>

      <div class="flex items-center">
        <div
          v-if="reports && reports.length > 0"
          class="hidden md:block mx-auto text-gray-600"
        >
          Visualizzati {{ reports.length }} report
        </div>
        <h2 v-else>
          {{ i18n.t("reports.empty") }}
        </h2>
      </div>

      <div class="text-theme-1 cursor-pointer" @click="markAllAsRead()">
        {{ i18n.t("general.mark-all-as-read") }}
      </div>
    </div>

    <div>
      <div
        v-for="n in reports"
        :key="n.id"
        class="box p-6 mb-6 relative cursor-pointer"
        :class="{ 'bg-red-200': n.hash == null }"
      >
        <div
          v-if="!n.read_at"
          class="absolute -top-2 -right-2 px-2 py-1 rounded-md bg-theme-24 text-white"
        >
          {{ i18n.t("general.unreaded") }}
        </div>
        <div class="flex justify-between items-center">
          <div class="flex items-center flex-1" @click="openReport(n)">
            <div class="flex items-center mr-6">
              <component
                :is="getIcon(n)"
                :class="{
                  'text-theme-22': !n.ready,
                  'text-theme-10': n.ready == 1,
                  'text-theme-24': n.ready == 2,
                }"
                icon="oval"
              />
            </div>
            <div class="col-span-4 flex-col">
              <div class="flex mb-2">
                <div class="text-lg font-bold uppercase p-2">
                  {{ n.vehicle?.plate || i18n.t("reports.all_vehicles") }}
                </div>
                <div v-if="!n.ready" class="place-self-center">
                  <div
                    class="px-2 py-1 text-md font-large text-white rounded-full cursor-pointer bg-reyesol-gray"
                  >
                    Generazione del report in corso
                  </div>
                </div>
                <div v-else-if="n.ready == 2" class="place-self-center">
                  <div
                    class="px-2 py-1 text-md font-large text-white rounded-full cursor-pointer bg-reyesol-error"
                  >
                    Errore nella generazione del report
                  </div>
                </div>
                <div v-else class="place-self-center">
                  <div
                    v-if="n.type == 'temp'"
                    class="px-2 py-1 text-xs font-medium text-white rounded-full cursor-pointer bg-theme-10"
                  >
                    {{ i18n.t("reports.type.temp") }}
                  </div>
                  <div
                    v-else-if="custom"
                    class="px-2 py-1 text-xs font-medium text-white rounded-full cursor-pointer bg-theme-2"
                  >
                    {{ i18n.t("reports.type.custom") }}
                  </div>
                  <div
                    v-else
                    class="px-2 py-1 text-xs font-medium text-white rounded-full cursor-pointer bg-theme-2"
                  >
                    {{ i18n.t("reports.type." + n.type) }}
                  </div>
                </div>
              </div>
              <div
                v-if="n.type == 'temp' || n.type == 'custom'"
                class="font-medium capitalize flex flex-wrap"
              >
                <template v-for="service in n.services.split(',')">
                  <template v-if="service">
                    <div
                      :key="service"
                      class="text-xs bg-theme-1 p-1 mr-2 my-1 rounded-md text-white"
                    >
                      {{ i18n.t("reports.services." + service) }}
                    </div>
                  </template>
                </template>
              </div>
              <div
                v-else-if="n.type == 'master'"
                class="font-medium capitalize flex flex-wrap"
              >
                <template
                  v-for="service in [
                    ...new Set(
                      n.services
                        .split(',')
                        .map((i) => i.split('.')[0].split('_')[0]),
                    ),
                  ]"
                >
                  <template v-if="service">
                    <div
                      :key="service"
                      class="text-xs bg-theme-1 p-1 mr-2 my-1 rounded-md text-white"
                    >
                      {{ i18n.t("reports.services." + service + ".name") }}
                    </div>
                  </template>
                </template>
              </div>
              <div class="text-gray-600 text-xs mt-0.5">
                {{
                  dayjs.utc(n.period_from).local().format("DD/MM/YYYY HH:mm")
                }}
                -
                {{ dayjs.utc(n.period_to).local().format("DD/MM/YYYY HH:mm") }}
              </div>
            </div>
          </div>
          <div>
            <div class="dropdown">
              <button
                class="dropdown-toggle md:p-4 focus:outline-none"
                aria-expanded="false"
              >
                <MoreVerticalIcon />
              </button>
              <div class="dropdown-menu w-48">
                <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                  <div
                    class="flex items-center p-2 transition duration-300 ease-in-out bg-white hover:bg-gray-200 rounded-md cursor-pointer"
                    @click="markAsRead(n)"
                  >
                    <CheckIcon class="w-4 h-4 mr-2" />
                    {{ i18n.t("general.mark-as-read") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="n.hash != null"
          class="h-0 overflow-hidden transition-all"
          :class="{ 'h-auto': n.opened }"
        >
          <div class="pt-6">
            <button
              class="btn btn-primary w-auto mr-2 mb-2"
              @click="viewReport(n)"
            >
              <BookOpenIcon class="w-4 h-4 mr-2" />
              {{ i18n.t("reports.view-report") }}
            </button>

            <button
              class="btn btn-secondary w-auto mr-2 mb-2"
              @click="downloadReport(n)"
            >
              <DownloadCloudIcon class="w-4 h-4 mr-2" />
              {{ i18n.t("reports.download-report") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <pagination :meta="reports_meta" class="mx-20 mt-10" @page="selectPage" />
  </div>
</template>

<script>
import { computed } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue3-i18n";
import { useStore } from "@/store";
import EventBus from "@/libs/event-bus";
import dayjs from "dayjs";

import pagination from "@/components/pagination/Main.vue";
export default {
  components: {
    pagination,
  },
  setup() {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const utc = require("dayjs/plugin/utc");
    dayjs.extend(utc);
    const i18n = useI18n();
    const store = useStore();
    const router = useRouter();

    const reports = computed(() => store.getters["reports/collection"]);
    const reports_meta = computed(
      () => store.getters["reports/collection_meta"],
    );

    async function openReport(report) {
      report.opened = true;
      if (!report.read_at) report.read_at = true;
      await store.dispatch("reports/getReport", report.id);
    }

    async function viewReport(report) {
      report.opened = true;
      if (!report.read_at) report.read_at = true;
      router.push({ path: `/report`, query: { r: report.id } });
    }

    // Downalod
    async function downloadReport(report) {
      const file_result = await store.dispatch("reports/linkReport", {
        id: report.id,
        hash: report.hash,
      });
      if (file_result.success == false) {
        EventBus.emit("notification:show", {
          type: "error",
          title: i18n.t("reports.no-data"),
        });
        router.push({ path: `/reports` });
      }
    }

    async function markAsRead(report) {
      report.read_at = true;
      const result = await store.dispatch("reports/markReport", report.id);
      report.read_at = result.validated;
    }

    function markAllAsRead() {
      EventBus.emit("confirm-dialog:show", {
        title: `${i18n.t("general.mark-all-as-read")}`,
        text: `${i18n.t("general.mark-all-as-read-confirm")}`,
        callback: async (confirm) => {
          if (confirm) {
            await store.dispatch("reports/markAll");
            selectPage(1);
          }
        },
      });
    }

    function getIcon(report) {
      switch (report.ready) {
        case 1:
          return "CheckCircleIcon";
        case 0:
          return "loading-icon";
        default:
          return "AlertOctagonIcon";
      }
    }
    function selectPage(page) {
      store.dispatch("reports/getCollection", { page: page });
    }
    selectPage(1);
    console.log(reports);
    return {
      i18n,
      dayjs,
      reports,
      reports_meta,
      getIcon,
      markAsRead,
      markAllAsRead,
      selectPage,
      openReport,
      downloadReport,
      viewReport,
    };
  },
};
</script>
