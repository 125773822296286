import { getInstance } from "@/libs/axios";
const axios = getInstance({
  baseURL: `${process.env.VUE_APP_AUTH_HOST}/api/${process.env.VUE_APP_API_VERSION}/`,
  withAuth: true,
});
import { handleSuccess, handleError } from "./response-handler";
const namespace = "auth";
const api = {
  login: async function (email, password) {
    try {
      const response = await axios.post("login", { email, password });
      return handleSuccess({ namespace, action: "login" }, response);
    } catch (error) {
      return {
        success: false,
        status: error.response.status,
      };
      //return handleError({ namespace, action: "login" }, error.response);
    }
  },
  remember: async function (email) {
    try {
      const response = await axios.post("forgot-password", { email });
      return handleSuccess({ namespace, action: "forgot-password" }, response);
    } catch (error) {
      return handleError(
        { namespace, action: "forgot-password" },
        error.response,
      );
    }
  },
  profile: async function () {
    try {
      const response = await axios.get("profile");
      return handleSuccess({ namespace, action: "profile" }, response);
    } catch (error) {
      return handleError({ namespace, action: "profile" }, error.response);
    }
  },
  customer: async function () {
    try {
      const response = await axios.get("user/customer");
      return handleSuccess({ namespace, action: "customer" }, response);
    } catch (error) {
      return handleError({ namespace, action: "customer" }, error.response);
    }
  },
  logout: async function () {
    try {
      const response = await axios.post("logout");
      return handleSuccess({ namespace, action: "logout" }, response);
    } catch (error) {
      return handleError({ namespace, action: "logout" }, error.response);
    }
  },
  change: async function (old_password, new_password, confirm_password) {
    try {
      const response = await axios.post("change-password", {
        old_password,
        new_password,
        confirm_password,
      });
      return handleSuccess({ namespace, action: "reset-password" }, response);
    } catch (error) {
      return handleError(
        { namespace, action: "reset-password" },
        error.response,
      );
    }
  },
  reset: async function (token, email, password, password_confirmation) {
    try {
      const response = await axios.post("reset-password", {
        token,
        email,
        password,
        password_confirmation,
      });
      return handleSuccess({ namespace, action: "reset-password" }, response);
    } catch (error) {
      return handleError(
        { namespace, action: "reset-password" },
        error.response,
      );
    }
  },
};
export { api as default };
