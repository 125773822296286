<template>
  <div v-if="vehicle" id="service-eyepower">
    <div v-if="data">
      <div class="flex justify-between items-baseline mb-4">
        <div class="text-lg capitalize">
          {{ i18n.t("services.eyepower.last_status") }}
        </div>
        <DateAgo
          v-if="data.power"
          :datetime="data.power.message_datetime"
        ></DateAgo>
        <div v-else>
          {{ i18n.t("services.no_data") }}
        </div>
      </div>
      <!--Summary Data-->
      <div v-if="data && data.power" class="report-box cursor-pointer mb-8">
        <div class="box p-5">
          <div class="flex flex-wrap items-center justify-center">
            <!--Power Level-->
            <VehicleParameter
              :active="data.power.external_voltage !== null"
              :name="i18n.t('services.eyepower.external_voltage.name')"
              icon="TrendingDownIcon"
              :setclass="
                () =>
                  data.power.external_voltage <= 25
                    ? data.power.external_voltage <= 15
                      ? 'bg-reyesol-alert-alarm'
                      : 'bg-reyesol-alert-warning'
                    : 'bg-reyesol-gray'
              "
              :value="data.power.external_voltage + ' %'"
            />
          </div>
          <div class="flex flex-wrap justify-around">
            <VehicleParameter
              :active="data.power.power_mode !== null"
              :name="i18n.t('services.eyepower.power_mode.name')"
              icon="ArchiveIcon"
              :value="
                data.power.power_mode
                  ? i18n.t('services.eyepower.power_mode.opened')
                  : i18n.t('services.eyepower.power_mode.closed')
              "
            />

            <VehicleParameter
              :active="data.power.battery_voltage !== null"
              :name="i18n.t('services.eyepower.battery_voltage.name')"
              icon="TrendingDownIcon"
              :value="data.power.battery_voltage"
            />

            <VehicleParameter
              :active="data.power.battery_capacity !== null"
              :name="i18n.t('services.eyepower.battery_capacity.name')"
              icon="TrendingDownIcon"
              :value="data.power.battery_capacity"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-else class="flex justify-center py-6">
      <loading-icon icon="rings" class="w-20 h-20" />
    </div>

    <!--History-->
    <div class="py-6">
      <div class="text-lg capitalize mb-4">
        {{ i18n.t("services.eyepower.power_history") }}
        <Eyepicker v-model="daterange" />
      </div>

      <div v-if="!loadingHistory">
        <div
          v-if="
            history &&
            history.list &&
            history.list.power &&
            history.list.power.length > 0
          "
        >
          <PowerChart
            :active="active"
            :height="200"
            :power="history.list.power"
          />
        </div>
        <div v-else class="font-light text-xl text-gray-500">
          {{ i18n.t("general.no-results") }}
        </div>
      </div>
      <div v-else class="flex justify-center py-6">
        <loading-icon icon="rings" class="w-20 h-20" />
      </div>
    </div>
  </div>
</template>

<script>
import PowerChart from "@/components/power-chart/Main.vue";
import { watch, ref, computed, reactive, onUnmounted, onMounted } from "vue";
import { useI18n } from "vue3-i18n";
import { useStore } from "vuex";
import { useDaterange } from "@/composables/daterange";
import dayjs from "dayjs";
import EventBus from "@/libs/event-bus";
import VehicleParameter from "@/components/vehicle/VehicleParameter.vue";

export default {
  components: {
    PowerChart,
    VehicleParameter,
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const i18n = useI18n();
    const vehicle = computed(() => store.getters["globalMap/selectedVehicle"]);
    const mapController = store.getters["globalMap/controller"];
    const data = reactive(ref(null));
    const history = reactive(ref(null));
    const selectedEvent = ref(0);
    const loadingHistory = ref(false);
    const {
      daterange,
      from: historyFrom,
      to: historyTo,
    } = useDaterange({
      onChange: getHistory,
    });

    // watch service visibility
    watch(
      () => props.active,
      () => {
        if (props.active) initService();
        else destroyService();
      },
    );

    function updatePower(power) {
      if (data.value == null) return;
      data.value.power = power;

      if (
        dayjs().isSame(historyTo.value, "day") &&
        history.value != null &&
        history.value.list &&
        history.value.list.power.length
      )
        history.value.list.power.unshift(power);
    }

    onMounted(() => {
      EventBus.on("vehicle:updates:power", updatePower);
    });
    onUnmounted(() => {
      EventBus.off("vehicle:updates:power");
    });

    // init service
    async function initService() {
      const dataResult = await store.dispatch(
        "eyepower/getVehicleData",
        vehicle.value.id,
      );
      data.value = dataResult.validated;
      getHistory();
    }

    async function handleHistoryClick(history) {
      if (history.id == selectedEvent.value.id) {
        mapController.flyToDate(history.message_datetime, "service");
        return;
      }
      selectedEvent.value = history;

      EventBus.emit("service:history", {
        vehicle_id: vehicle.value.id,
        from: historyFrom.value,
        to: historyTo.value,
        ev: history,
      });
    }

    // requestReport service
    async function requestReport() {
      emit(
        "report-request",
        store.getters["globalMap/selectedVehicleSensors"].eyepower,
        "eyepower",
        store.getters["globalMap/selectedVehicleSensors"].eyepower,
      );
    }

    // destroy service
    function destroyService() {}

    // get history
    async function getHistory() {
      if (!props.active) return;
      loadingHistory.value = true;

      const historyResult = await store.dispatch("eyepower/getVehicleHistory", {
        vehicleId: vehicle.value.id,
        dateFrom: dayjs(historyFrom.value).format(),
        dateTo: dayjs().isSame(dayjs(historyTo.value), "day")
          ? dayjs().format()
          : dayjs(historyTo.value).format(),
      });
      history.value = historyResult.validated;

      loadingHistory.value = false;
    }

    return {
      i18n,
      dayjs,
      vehicle,
      data,
      history,
      requestReport,
      loadingHistory,
      handleHistoryClick,
      daterange,
    };
  },
};
</script>

<style></style>
