<template>
  <div v-if="driver != null" class="flex w-fit">
    <UserCheckIcon class="self-center" />
    <div class="badge-reyesol-temperatures-list">
      <span v-if="driver" class="temp">{{ driver }}</span>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import { UserCheckIcon } from "@zhuowenli/vue-feather-icons";

export default defineComponent({
  components: {
    UserCheckIcon,
  },
  props: {
    driver: {
      type: String,
      default: null,
    },
  },

  setup() {},
});
</script>
