<template>
  <div
    class="box flex mb-1 shadow-sm cursor-pointer hover:shadow-md hover:bg-cyan-200 overflow-hidden"
    :class="{
      'border border-1 border-primary-1 shadow-lg bg-cyan-100': selected,
    }"
    @click="handleClick"
  >
    <!--status strip-->
    <div
      class="w-3 min-h-full bg-gray-400 text-gray-600"
      :class="`bg-reyesol-${vehicle_status}`"
    ></div>

    <!--vehicle info-->
    <div class="relative inline flex w-full ml-3 mt-2">
      <!--vehicle icons-->
      <div class="mb-1 ml-2 pr-2 place-self-stretch border-r-2">
        <div class="flex w-full h-full">
          <!-- vehicle icon & fridge status-->
          <div class="flex flex-col h-full w-full justify-center">
            <!--vehicle type image-->
            <div class="flex justify-center">
              <Tippy
                tag="img"
                :src="
                  require(
                    `@/assets/images/vehicles/${vehicle.vehicle_type}-primary.svg`,
                  )
                "
                class="h-5 mx-2"
                :content="i18n.t(`vehicles.types.${vehicle.vehicle_type}`)"
              >
              </Tippy>
            </div>
            <!--fridge status-->
            <FridgeStatusBadge
              :is_fridge="is_fridge"
              :overall_status="vehicle.overall_status"
            />
            <!--general status-->
            <GeneralStatusBadge
              :is_general="!is_fridge && vehicle.general_status !== null"
              :general_status="vehicle.general_status"
            />
          </div>
        </div>
      </div>

      <!--plate & fleet-->
      <div class="flex-grow border-r-2 pr-1 mb-1">
        <div class="flex flex-col px-2 h-full items-stretch justify-around">
          <!--plate-->
          <div class="flex flex-row overflow-hidden text-ellipsis">
            <div class="text-start truncate">
              <div class="flex sm:text-md md:text-sm">
                <Tippy
                  tag="img"
                  :src="require(`@/assets/images/props/plate.svg`)"
                  class="h-5 mx-1"
                  :content="i18n.t(`vehicles.plate`)"
                ></Tippy>

                <p class="overflow-hidden text-ellipsis font-bold">
                  {{ vehicle.plate }}
                </p>
              </div>
            </div>
          </div>

          <!--Fleets-->
          <div
            class="flex flex-row overflow-hidden text-ellipsis"
            :class="{ hidden: !vehicleHasFleet(vehicle.fleet_id) }"
          >
            <div class="text-start truncate">
              <div class="flex sm:text-md md:text-sm">
                <Tippy
                  tag="img"
                  :src="require(`@/assets/images/props/fleets.svg`)"
                  class="h-5 mx-1"
                  :content="i18n.t('vehicles.fleet')"
                ></Tippy>

                <p class="overflow-hidden text-ellipsis font-bold">
                  {{ vehicleFleet(vehicle.fleet_id) }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--temps & sets-->
      <div
        v-if="
          is_fridge &&
          (vehicle.T1 != null ||
            vehicle.T2 != null ||
            vehicle.T3 != null ||
            vehicle.T4 != null ||
            vehicle.setpoint_t1 != null ||
            vehicle.setpoint_t2 != null ||
            vehicle.setpoint_t3 != null)
        "
        class="hidden sm:flex xl:ml-0 pt-1 border-r-2 pr-2 pl-2 mb-1"
      >
        <div class="flex flex-col w-full h-full items-stretch justify-around">
          <!--Temperatures-->
          <TemperatureBadge
            :temperatures="vehicle"
            :sensors="vehicle.fridge_temperature_sensors_number"
          />
          <!--Setpoints-->
          <SetpointBadge
            :setpoints="vehicle"
            :compartments="vehicle.fridge_compartments_number"
          />
        </div>
      </div>
      <!--warning & alarm-->
      <div class="ml-2 pt-1">
        <div
          class="flex flex-col w-full pr-2 h-full gap-1 items-stretch justify-around"
        >
          <!--Anomalie-->
          <Tippy
            tag="div"
            class="tooltip w-full text-center"
            :content="`${vehicle_alerts.warning.length} anomalie`"
            :alt="`${vehicle_alerts.warning.length} anomalie`"
          >
            <NotificationBadge :warning="vehicle_alerts.warning.length">
              <InfoIcon class="self-center mr-1 relative" />
            </NotificationBadge>
          </Tippy>
          <!--Allarmi-->
          <Tippy
            tag="div"
            class="tooltip w-full text-center"
            :content="`${vehicle_alerts.alarm.length} alarmi`"
            :alt="`${vehicle_alerts.alarm.length} alarmi`"
          >
            <NotificationBadge :alarm="vehicle_alerts.alarm.length">
              <BellIcon class="self-center mr-1" />
            </NotificationBadge>
          </Tippy>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useI18n } from "vue3-i18n";
import { useStore } from "vuex";
import dayjs from "dayjs";
import { helper } from "@/utils/helper";
import NotificationBadge from "@/components/alerts/NotificationBadge.vue";
import TemperatureBadge from "@/components/vehicle/TemperatureBadge.vue";
import SetpointBadge from "@/components/vehicle/SetpointBadge.vue";
import FridgeStatusBadge from "@/components/vehicle/FridgeStatusBadge.vue";
import GeneralStatusBadge from "@/components/vehicle/GeneralStatusBadge.vue";

export default {
  components: {
    FridgeStatusBadge,
    GeneralStatusBadge,
    NotificationBadge,
    TemperatureBadge,
    SetpointBadge,
  },
  props: {
    vehicle: {
      type: [Object, null],
      default: null,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["select"],
  setup(props, { emit }) {
    const i18n = useI18n();
    const store = useStore();
    const alerts = store.getters["alerts/collection"];
    const fleets = store.getters["fleets/collection"];

    const is_fridge = ref(false);
    const has_eyecold = ref("");

    const vehicle_status = helper.convert_vehicle_status(props.vehicle.status);

    const vehicle_alerts = helper.process_vehicle_alerts(props.vehicle, alerts);

    if (props.vehicle.active_agreements) {
      has_eyecold.value = props.vehicle.active_agreements.find(
        (o) => o.agreement_id == 3,
      );
    }

    if (has_eyecold.value) {
      is_fridge.value = true;
    }

    function vehicleHasFleet(fleet_id) {
      const fleets = store.getters["fleets/collection"];
      const obj = fleets.find((o) => o.id == fleet_id);
      if (obj) return true;
      else return false;
    }

    function vehicleFleet(fleet_id) {
      const obj = fleets.find((o) => o.id == fleet_id);
      if (obj) return obj.code;
      else return "N/A";
    }

    function handleClick() {
      emit("select", props.vehicle);
    }

    return {
      dayjs,
      i18n,
      vehicle_alerts,
      handleClick,
      vehicleFleet,
      vehicleHasFleet,
      is_fridge,
      vehicle_status,
    };
  },
};
</script>
