<template>
  <div v-if="active" class="max-h-screen flex flex-col">
    <!-- Filter Bar-->

    <ServicesFilter
      v-if="filterIsSelected || !somethingIsSelected"
      @filter="handleFilter"
    />
    <!-- filters -->
    <div class="flex filter-tabs">
      <Tippy
        tag="button"
        class="flex w-full flex-row justify-center gap-1 px-1 bg-white hover:shadow-md bg-reyesol-filter"
        :class="{ active: is_connected }"
        :content="i18n.t('filters.is_connected')"
        @click="clickIsConnected()"
      >
        <img class="h-5 pr-1" :src="require(`@/assets/images/link.svg`)" />
        <div
          :class="{ hidden: !is_connected }"
          class="bg-white rounded-md text-black px-1"
        >
          {{ filteredVehicles.length }}
        </div>
      </Tippy>
      <Tippy
        tag="button"
        class="flex w-full flex-row justify-center gap-1 px-1 bg-white hover:shadow-md bg-reyesol-filter"
        :class="{ active: is_fridge }"
        :content="i18n.t('filters.is_fridge')"
        @click="clickIsFridge()"
      >
        <img class="h-5 pr-1" :src="require(`@/assets/images/cold.svg`)" />
        <div
          :class="{ hidden: !is_fridge }"
          class="bg-white rounded-md text-black px-1"
        >
          {{ filteredVehicles.length }}
        </div>
      </Tippy>
      <Tippy
        tag="button"
        class="flex w-full flex-row justify-center gap-1 px-1 bg-white hover:shadow-md bg-reyesol-filter"
        :class="{ active: is_moving }"
        :content="i18n.t('filters.is_moving')"
        @click="clickIsMoving()"
      >
        <img
          class="h-5 pr-1"
          :src="require(`@/assets/images/speedometer.svg`)"
        />
        <div
          :class="{ hidden: !is_moving }"
          class="bg-white rounded-md text-black px-1"
        >
          {{ filteredVehicles.length }}
        </div>
      </Tippy>
      <Tippy
        tag="button"
        class="flex w-full flex-row justify-center gap-1 px-1 bg-white hover:shadow-md bg-reyesol-filter"
        :class="{ active: is_inAlarm }"
        :content="i18n.t('filters.in_alarm')"
        @click="clickIsAlarm()"
      >
        <img class="h-5 pr-1" :src="require(`@/assets/images/alarm.svg`)" />
        <div
          :class="{ hidden: !is_inAlarm }"
          class="bg-white rounded-md text-black px-1"
        >
          {{ filteredVehicles.length }}
        </div>
      </Tippy>
    </div>
    <!-- list -->
    <div class="overflow-y-auto flex-1 pr-1 mb-2">
      <div v-if="filteredVehicles && filteredVehicles.length > 0">
        <div v-for="v in filteredVehicles" :key="v.vehicle_id">
          <div class="intro-y">
            <VehicleCard
              :vehicle="v"
              :selected="v.vehicle_id == selectedVehicle?.id"
              @select="handleVehicleClick"
            />
          </div>
        </div>
      </div>
      <div v-else class="font-light text-xl text-gray-500">
        {{ i18n.t("general.no-results") }}
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useI18n } from "vue3-i18n";
import { useStore } from "vuex";
import dayjs from "dayjs";
import VehicleCard from "./VehicleCard.vue";
import { useRouter } from "vue-router";
import ServicesFilter from "@/components/services-filter/Main";

// import EventBus from "@/libs/event-bus";

export default {
  components: { VehicleCard, ServicesFilter },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    service: {
      type: [String, null],
      default: null,
    },
    feature: {
      type: [String, null],
      default: null,
    },
    vehicles: {
      type: Object,
      default: ref([]),
    },
  },
  emits: ["close"],
  setup(props, { emit }) {
    const i18n = useI18n();
    const store = useStore();
    const router = useRouter();

    const filteredVehicles = ref([]);

    const is_connected = ref(null);

    const is_fridge = ref(null);

    const is_moving = ref(null);

    const is_inAlarm = ref(null);

    const filterIsSelected = ref(null);

    const somethingIsSelected = ref(null);

    const selectedVehicle = computed(() => {
      return (
        store.getters["globalMap/selectedVehicle"] ??
        store.getters["globalMap/externalSelectedVehicle"]
      );
    });

    function handleFilter({ service, feature }) {
      if (service == "system" && feature == "all") {
        router.replace({ query: {} });
      } else {
        store.dispatch("globalMap/setRequestedService", service);
        store.dispatch("globalMap/setRequestedFeature", feature);
        router.push({
          path: "/global-map",
          query: { f: feature, s: service },
        });
      }
    }

    function handleVehicleClick(v) {
      store.dispatch("globalMap/setExternalVehicle", { id: v.vehicle_id });
      router.push({
        path: "/global-map",
        query: { v: v.vehicle_id },
      });
    }

    function filterVehicles() {
      let all = props.vehicles;
      if (is_fridge.value == 1) {
        all = all.filter((vehicle) => {
          const has_eyecold = vehicle.active_agreements.find(
            (o) => o.agreement_id == 3,
          );
          return has_eyecold != null;
        });
      }
      if (is_moving.value == 1) {
        all = all.filter((vehicle) => {
          return vehicle.moving == 1;
        });
      }
      if (is_inAlarm.value == 1) {
        all = all.filter((vehicle) => {
          return vehicle.active_alerts != 0;
        });
      }
      if (is_connected.value == 1) {
        all = all.filter((vehicle) => {
          return vehicle.status == 1;
        });
      }
      return all;
    }

    function closePanel() {
      emit("close");
    }

    function clickIsFridge() {
      if (this.is_fridge == 1) {
        this.is_fridge = null;
      } else {
        this.is_fridge = 1;
      }
      this.filteredVehicles = filterVehicles();
    }

    function clickIsMoving() {
      if (this.is_moving == 1) {
        this.is_moving = null;
      } else {
        this.is_moving = 1;
      }
      this.filteredVehicles = filterVehicles();
    }

    function clickIsAlarm() {
      if (this.is_inAlarm == 1) {
        this.is_inAlarm = null;
        this.filterfeature = props.feature;
      } else {
        this.filterfeature = "alarm";
        this.is_inAlarm = 1;
      }
      this.filteredVehicles = filterVehicles();
    }

    function clickIsConnected() {
      if (this.is_connected == 1) {
        this.is_connected = null;
        this.filterfeature = props.feature;
      } else {
        this.filterfeature = "connected";
        this.is_connected = 1;
      }
      this.filteredVehicles = filterVehicles();
    }

    return {
      handleFilter,
      dayjs,
      i18n,
      is_fridge,
      is_moving,
      is_inAlarm,
      is_connected,
      filterIsSelected,
      somethingIsSelected,
      clickIsFridge,
      clickIsMoving,
      clickIsAlarm,
      clickIsConnected,
      filteredVehicles,
      selectedVehicle,
      closePanel,
      filterVehicles,
      handleVehicleClick,
    };
  },
  watch: {
    vehicles: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.filteredVehicles = newValue;
        this.is_fridge = null;
      },
    },
  },
};
</script>

<style></style>
