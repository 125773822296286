<template>
  <div v-if="active" class="flex flex-col items-center mr-3">
    <div v-if="name" class="py-1 px-2 text-xs font-medium">
      {{ name }}
    </div>
    <div v-if="icon" class="mb-2">
      <component :is="icon" />
    </div>
    <div class="flex items-center" :class="{ 'flex-col': extraColumn }">
      <div
        class="py-1 px-2 bg-reyesol-gray rounded-full text-xs text-black font-medium border-2 border-reyesol-gray"
        :class="assignClass(value)"
      >
        {{ formatValue(value) }}
        <slot></slot>
      </div>
      <slot name="extra"></slot>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    active: {
      type: Boolean,
      default: true,
    },
    extraColumn: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Number, String],
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: "Param",
    },
    format: {
      type: Function,
      default: null,
    },
    setclass: {
      type: [Function, String],
      default: null,
    },
  },

  setup(props) {
    function assignClass(value) {
      if (props.setclass)
        return props.setclass instanceof Function
          ? props.setclass(value)
          : props.setclass;
    }

    function formatValue(value) {
      if (props.format) return props.format(value);
      return value;
    }

    return {
      assignClass,
      formatValue,
    };
  },
});
</script>
