import { type_activities as api } from "@/api";
import {
  state as s,
  getters as g,
  actions as a,
  mutations as m,
} from "./crud-base";

const state = () => {
  return {
    ...s(),
    data: [],
    history: [],
  };
};

// getters
const getters = {
  ...g,
  vehicleData: (state) => (vehicleId) => {
    return state.data.find((d) => d.vehicle_id === vehicleId);
  },
  vehicleHistory: (state) => (vehicleId) => {
    return state.history.find((h) => h.vehicle_id === vehicleId);
  },
};

// actions
const actions = {
  ...a(api),
  async getVehicleData({ dispatch }, vehicleId) {
    // const cachedData = getters.vehicleData(vehicleId);
    // if (cachedData) return { validated: cachedData };
    return await dispatch("fetchVehicleData", vehicleId);
  },

  async fetchVehicleData({ commit, getters }, vehicleId) {
    const result = await api.getVehicleData(vehicleId);
    if (result.success) {
      commit("REMOVE_VEHICLE_DATA", vehicleId);
      commit("PUSH_VEHICLE_DATA", { vehicle_id: vehicleId, ...result.data });
    }
    result.validated = getters.vehicleData(vehicleId);
    return result;
  },

  async getVehicleHistory({ dispatch }, vehicleId) {
    // const cachedData = getters.vehicleHistory(vehicleId);
    // if (cachedData) return { validated: cachedData };
    return await dispatch("fetchVehicleHistory", vehicleId);
  },

  async getStats(_, period) {
    const result = await api.getStats(period);
    result.validated = null;
    if (result.success) result.validated = result.data;
    return result;
  },

  async setActivity(
    _,
    { id, vehicleId, typeId, date, amount, km, hour, supplier },
  ) {
    const result = await api.setActivity(
      id,
      vehicleId,
      typeId,
      date,
      amount,
      km,
      hour,
      supplier,
    );
    result.validated = null;
    if (result.success) result.validated = result.data;
    return result;
  },

  async fetchVehicleHistory(
    { commit, getters },
    { vehicleId, dateFrom, dateTo },
  ) {
    const result = await api.getVehicleHistory(vehicleId, dateFrom, dateTo);
    result.validated = [];
    if (result.success) {
      commit("REMOVE_VEHICLE_HISTORY", vehicleId);
      commit("PUSH_VEHICLE_HISTORY", {
        vehicle_id: vehicleId,
        list: result.data,
      });
      result.validated = getters.vehicleHistory(vehicleId);
    }
    return result;
  },
};

// mutations
const mutations = {
  ...m,
  PUSH_VEHICLE_DATA(state, vehicleData) {
    state.data.push(vehicleData);
  },
  REMOVE_VEHICLE_DATA(state, vehicleId) {
    const i = state.data.findIndex((d) => d.vehicle_id == vehicleId);
    if (i != -1) state.data.splice(i, 1);
  },
  PUSH_VEHICLE_HISTORY(state, vehicleHistory) {
    state.history.push(vehicleHistory);
  },
  REMOVE_VEHICLE_HISTORY(state, vehicleId) {
    const i = state.history.findIndex((h) => h.vehicle_id == vehicleId);
    if (i != -1) state.history.splice(i, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
