const state = () => {
  return {
    trips: [],
  };
};

const getters = {
  trips: (state) => state.trips,
};

const actions = {
  pushTrip: ({ commit }, trip) => commit("PUSH_NEW_TRIP", trip),
  removeTrip: ({ commit }, index) => commit("REMOVE_TRIP", index),
  editTrip: ({ commit }, index) => commit("EDIT_TRIP", index),
};

const mutations = {
  PUSH_NEW_TRIP(state, trip) {
    state.trips.push(trip);
  },
  REMOVE_TRIP(state, index) {
    state.trips.splice(index, 1);
  },
  EDIT_TRIP() {},
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
