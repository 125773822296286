<template>
  <div class="intro-x relative mr-3 sm:mr-6">
    <div class="search hidden sm:block">
      <input
        v-model="searchQuery"
        type="text"
        class="search__input form-control dark:bg-dark-1 border-transparent placeholder-reyesol-input"
        :placeholder="`${i18n.t('general.search')}...`"
        @focus="showSearchDropdown"
        @blur="hideSearchDropdown"
      />
      <SearchIcon class="search__icon dark:text-gray-300" />
    </div>
    <!-- <a class="notification sm:hidden" href="">
      <SearchIcon class="notification__icon dark:text-gray-300" />
    </a> -->
    <div class="search-result" :class="{ show: searchDropdown }">
      <div class="search-result__content">
        <div v-if="searchQuery.length < 2">
          <div>{{ i18n.t("general.min-2-chars") }}</div>
        </div>
        <div
          v-if="
            searchQuery.length >= 2 &&
            !hubs.length &&
            !fleets.length &&
            !vehicles.length
          "
        >
          <h3 class="text-lg">{{ i18n.t("general.no-results") }}</h3>
        </div>

        <!-- hubs -->
        <div v-if="hubs.length" class="mb-5">
          <div class="search-result__content__title uppercase">
            <TargetIcon class="w-4 h-4" />
            {{ i18n.t("general.hubs") }}
          </div>
          <div>
            <div
              v-for="h in hubs"
              :key="h.id"
              class="flex items-center rounded-sm cursor-pointer p-2 hover:bg-gray-300"
              @click="handleHubClick(h)"
            >
              <div
                class="h-4 w-4 mr-4 rounded-sm"
                :style="{
                  'background-color': h.color,
                }"
              ></div>
              <div class="text-lg mr-4">{{ h.code }}</div>
              <div class="text-sm">{{ h.name }}</div>
            </div>
          </div>
        </div>

        <!-- fleets -->
        <div v-if="fleets.length" class="mb-5">
          <div class="search-result__content__title uppercase">
            <FlagIcon class="w-4 h-4" />
            {{ i18n.t("general.fleets") }}
          </div>
          <div>
            <div
              v-for="f in fleets"
              :key="f.id"
              class="flex items-center rounded-sm cursor-pointer p-2 hover:bg-gray-300"
              @click="handleFleetClick(f)"
            >
              <div
                class="h-4 w-4 mr-4 rounded-sm"
                :style="{
                  'background-color': f.color,
                }"
              ></div>
              <div class="text-lg mr-4">{{ f.code }}</div>
              <div class="text-sm">{{ f.description }}</div>
            </div>
          </div>
        </div>

        <!-- vehicles -->
        <div v-if="vehicles.length" class="mb-5">
          <div class="search-result__content__title uppercase">
            <TruckIcon class="w-4 h-4" />
            {{ i18n.t("general.vehicles") }}
          </div>
          <div>
            <div
              v-for="v in vehicles"
              :key="v.id"
              class="flex items-center rounded-sm cursor-pointer p-2 hover:bg-gray-300"
              @click="handleVehicleClick(v)"
            >
              <div
                class="h-4 w-4 mr-4 rounded-sm"
                :style="{
                  'background-color': v.color,
                }"
              ></div>
              <div class="text-lg mr-4">{{ v.plate }}</div>
              <div class="text-sm">{{ v.serial }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue3-i18n";
import { useRouter } from "vue-router";
export default {
  setup() {
    const store = useStore();
    const router = useRouter();

    // dropdown
    const searchDropdown = ref(false);

    const showSearchDropdown = () => {
      searchDropdown.value = true;
    };

    const hideSearchDropdown = () => {
      setTimeout(() => (searchDropdown.value = false), 100);
    };

    // search
    const searchQuery = ref("");
    const hubs = ref([]);
    const fleets = ref([]);
    const vehicles = ref([]);

    watch(searchQuery, search);
    async function search() {
      if (searchQuery.value.length < 2) {
        clearResults();
        return;
      }
      const result = await store.dispatch(
        "globalSearch/search",
        searchQuery.value,
      );
      hubs.value = result.validated.hubs;
      fleets.value = result.validated.fleets;
      vehicles.value = result.validated.vehicles;
    }

    function clearResults() {
      hubs.value = [];
      fleets.value = [];
      vehicles.value = [];
    }

    function handleHubClick(hub) {
      searchQuery.value = "";
      store.dispatch("hubs/setExternalSelected", hub);
      router.push({
        path: "/hubs",
        query: { h: hub.id },
      });
    }

    function handleFleetClick(fleet) {
      searchQuery.value = "";
      store.dispatch("fleets/setExternalSelected", fleet);
      router.push({
        path: "/fleets",
        query: { f: fleet.id },
      });
    }

    function handleVehicleClick(vehicle) {
      searchQuery.value = "";
      store.dispatch("globalMap/clear");
      store.dispatch("globalMap/setExternalVehicle", vehicle);
      router.push({
        path: "/global-map",
        query: { v: vehicle.id },
      });
    }

    return {
      i18n: useI18n(),
      searchDropdown,
      showSearchDropdown,
      hideSearchDropdown,
      search,
      searchQuery,
      hubs,
      fleets,
      vehicles,
      handleHubClick,
      handleFleetClick,
      handleVehicleClick,
    };
  },
};
</script>

<style></style>
