<template>
  <div
    v-if="
      setpoints != null &&
      (setpoints.setpoint_t1 != null ||
        setpoints.setpoint_t2 != null ||
        setpoints.setpoint_t3 != null)
    "
    class="flex w-fit"
  >
    <TargetIcon class="self-center" />
    <div class="badge-reyesol-setpoints-list">
      <span
        v-if="compartments > 0 && setpoints.setpoint_t1 != null"
        class="temp"
        >{{ helper.format_temp(setpoints.setpoint_t1) }}</span
      >
      <span
        v-if="compartments > 1 && setpoints.setpoint_t2 != null"
        class="temp"
        >{{ helper.format_temp(setpoints.setpoint_t2) }}</span
      >
      <span
        v-if="compartments > 2 && setpoints.setpoint_t3 != null"
        class="temp"
        >{{ helper.format_temp(setpoints.setpoint_t3) }}</span
      >
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { TargetIcon } from "@zhuowenli/vue-feather-icons";

import { helper } from "@/utils/helper";

export default defineComponent({
  components: {
    TargetIcon,
  },
  props: {
    setpoints: {
      type: Object,
      default: null,
    },
    compartments: {
      type: [Number, String],
      default: 0,
    },
  },

  setup() {
    return {
      helper,
    };
  },
});
</script>
