<template>
  <div id="notifications-dropdown" class="intro-x dropdown mr-4 sm:mr-6">
    <div
      class="dropdown-toggle notification cursor-pointer"
      :class="{ 'notification--bullet': hasUnreaded }"
      role="button"
      aria-expanded="false"
    >
      <BellIcon class="notification__icon" />
    </div>
    <div class="notification-content pt-2 dropdown-menu">
      <div class="notification-content__box dropdown-menu__content box">
        <div class="flex justify-between items-center mb-5">
          <div class="font-medium capitalize">
            {{ i18n.t("general.notifications") }}

            <div
              class="text-theme-1 text-xs cursor-pointer"
              @click="goToNotifications()"
            >
              {{ i18n.t("general.see-all-notifications") }}
            </div>
          </div>

          <div class="text-theme-1 text-sm cursor-pointer">
            <input
              id="notifications_active"
              :checked="notify"
              class="form-check-switch"
              type="checkbox"
              @input="$emit('update:notify', $event.target.checked)"
            />
          </div>
        </div>
        <div
          v-for="n in notifications"
          :key="n.id"
          class="cursor-pointer relative mt-5 py-2 hover:bg-gray-200 rounded-md"
          @click="goToNotificationTarget(n)"
        >
          <div class="grid grid-cols-6">
            <div class="col-span-1 flex items-center justify-center">
              <component :is="getIcon(n)" />
            </div>
            <div class="col-span-4">
              <div class="font-medium">{{ i18n.t(n.data.message) }}</div>
              <div class="text-gray-600 text-xs mt-0.5">
                {{ dayjs(n.data.datetime).format("DD/MM/YYYY HH:mm:ss") }}
              </div>
            </div>
            <div class="col-span-1 flex items-center justify-center">
              <div
                v-if="!n.read_at"
                class="rounded-full h-3 w-3 bg-theme-24"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, onUnmounted, ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue3-i18n";
import dayjs from "dayjs";
import { useRouter } from "vue-router";

export default {
  props: {
    notify: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:notify"],
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const notifications = computed(
      () => store.getters["notifications/firstPage"],
    );
    const hasUnreaded = computed(() =>
      notifications.value.find((n) => !n.read_at),
    );
    const notifications_active = ref(props.notify);

    let interval = null;
    onMounted(() => {
      store.dispatch("notifications/fetchFirstPage");
      interval = setInterval(
        () => store.dispatch("notifications/fetchFirstPage"),
        60000,
      );
    });
    onUnmounted(() => {
      clearInterval(interval);
    });

    function getIcon(notification) {
      switch (notification.type) {
        case "user":
          return "UserIcon";

        case "report":
          return "FileTextIcon";

        default:
          return "AlertCircleIcon";
      }
    }

    function markAsRead() {}

    function goToNotifications() {
      router.push("/notifications");
      hideDropdown();
    }

    function goToNotificationTarget() {
      hideDropdown();
    }

    function hideDropdown() {
      cash("#notifications-dropdown").dropdown("hide");
    }

    return {
      i18n: useI18n(),
      dayjs,
      notifications,
      notifications_active,
      hasUnreaded,
      getIcon,
      markAsRead,
      goToNotifications,
      goToNotificationTarget,
    };
  },
};
</script>
