<template>
  <div
    v-if="position"
    class="report-box cursor-pointer mb-8"
    @click="emit('click')"
  >
    <div class="box p-5">
      <div class="mb-3 p-3 bg-reyesol-green rounded-full text-center">
        <template v-if="position.moving">
          {{ i18n.t("services.eyegeo.status.moving") }}
        </template>
        <template v-else>
          {{ i18n.t("services.eyegeo.status.stopped") }}
        </template>
      </div>
      <!-- km -->
      <div v-if="position.km" class="mb-3">
        <div class="text-3xl font-medium leading-8 mb-2">
          {{ position.km }}
        </div>
        <div class="text-base text-gray-600">
          {{ i18n.t("services.eyegeo.total_km") }}
        </div>
      </div>

      <!-- latitude, longitude & altitude -->
      <div class="pb-4 mb-4 border-b border-gray-400">
        <div
          v-if="last_position == null"
          class="flex flex-wrap justify-around gap-2"
        >
          <div
            class="text-base text-gray-600 text-center capitalize flex flex-col"
          >
            <span class="font-bold">{{ i18n.t("general.latitude") }}:</span>
            <span class="truncate">{{ position.latitude }}</span>
          </div>
          <div
            class="text-base text-gray-600 text-center capitalize flex flex-col"
          >
            <span class="font-bold">{{ i18n.t("general.longitude") }}:</span>
            <span class="truncate">{{ position.longitude }}</span>
          </div>
          <div
            v-if="position.altitude"
            class="text-base text-gray-600 text-center capitalize flex flex-col"
          >
            <span class="font-bold">{{ i18n.t("general.altitude") }}:</span>
            <span class="truncate">{{ position.altitude }}</span>
          </div>
        </div>
        <div v-else class="flex flex-row justify-between content-center">
          <div class="text-gray-600 break-words">
            {{ last_position }}
          </div>
          <div
            class="my-auto justify-self-end bg-gray-200 p-1 m-2 rounded shadow-md hover:bg-gray-400"
          >
            <a
              :href="makeMapsUrl(position.latitude, position.longitude)"
              target="_blank"
              class=""
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-share-2"
              >
                <circle cx="18" cy="5" r="3"></circle>
                <circle cx="6" cy="12" r="3"></circle>
                <circle cx="18" cy="19" r="3"></circle>
                <line x1="8.59" y1="13.51" x2="15.42" y2="17.49"></line>
                <line x1="15.41" y1="6.51" x2="8.59" y2="10.49"></line>
              </svg>
            </a>
          </div>
        </div>
      </div>

      <!-- direction & speed-->
      <div class="flex justify-around">
        <VehicleParameter
          :active="position.direction != null"
          :name="i18n.t('general.direction')"
          icon="Navigation2Icon"
          :value="position.direction + '°'"
        />

        <VehicleParameter
          :active="position.speed != null"
          :name="i18n.t('general.speed')"
          icon="TrendingUpIcon"
          :value="position.speed + ' Km/h'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, watch, ref } from "vue";
import { useI18n } from "vue3-i18n";
import dayjs from "dayjs";
import { geoReverseSearch } from "@/services/geocoder";
import VehicleParameter from "@/components/vehicle/VehicleParameter.vue";

export default defineComponent({
  components: {
    VehicleParameter,
  },
  props: {
    position: {
      type: Object,
      default: null,
    },
    vehicle: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const i18n = useI18n();
    const last_position = ref(null);

    watch(() => props, init, { deep: true });

    async function init() {
      const has_eyegeo_geocoding = props.vehicle.active_agreements.find(
        (o) => o.agreement_id == 25,
      );
      if (has_eyegeo_geocoding) {
        const result = await geoReverseSearch(
          props.position.latitude,
          props.position.longitude,
        );
        if (!result) {
          last_position.value =
            normalize(props.position.latitude) +
            " , " +
            normalize(props.position.longitude);
        } else {
          last_position.value = result.display_name;
        }
      } else {
        last_position.value = null;
      }
    }

    function normalize(coord) {
      return [parseFloat(coord)];
    }

    function makeMapsUrl(lat, lon) {
      return (
        "https://www.google.com/maps/search/?api=1&query=" + lat + " " + lon
      );
    }

    init();
    return {
      i18n,
      dayjs,
      last_position,
      makeMapsUrl,
    };
  },
});
</script>
