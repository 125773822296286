<template>
  <div id="global-map">
    <div class="flex items-center justify-between py-2">
      <simple-title class="hidden md:block">
        {{ i18n.t("global-map.title") }}
      </simple-title>

      <!-- services and features filter -->
      <!--Menu buttons-->
      <button
        v-if="!somethingIsSelected"
        class="btn bg-gray-300 place-self-end"
        @click="openPanel"
      >
        <Tippy tag="btn" class="h-5" :content="'Menu veicoli'">
          <MenuIcon class="w-5 h-5" />
        </Tippy>
      </button>
      <button v-else class="btn bg-gray-300 place-self-end" @click="closePanel">
        <ArrowRightIcon class="w-5 h-5" />
      </button>
    </div>

    <div class="flex flex-col lg:flex-row">
      <!--Map-->
      <div
        class="relative w-full flex-grow mb-4 max-h-screen md:h-screen"
        :class="{ 'md:w-6/12 lg:w-7/12': somethingIsSelected }"
      >
        <Map
          class="w-full h-full lg:h-screen relative"
          :class="{ 'lg:min-h-screen': !somethingIsSelected }"
          :show-search="['vehicles', 'geocoding']"
          @init="handleMapInit"
          @marker:click="handleMarkerClick"
          @circle:click="handleCircleClick"
          @point:search="handlePlaceSearch"
        ></Map>
      </div>

      <!-- vehicle panel -->

      <div
        class="flex-shrink-0 mb-2 z-20 lg:w-5/12 h-full md:h-screen p-2 bg-gray-200 transition-transform transform-gpu min-w-vcard shadow-md"
        :class="{
          'translate-x-full hidden': !selectedVehicle,
        }"
      >
        <VehicleDetail
          class="mr-4"
          :service="requestedService"
          :vehicle="selectedVehicle"
          @close="closeVehicleDetail"
        />
      </div>

      <!-- other panels -->

      <div
        class="absolute right-0 flex-none z-30 lg:w-4/12 md:w-6/12 h-screen p-2 bg-gray-300 transition-transform transform-gpu shadow-xl max-w-full"
        :class="{
          'translate-x-full': !somethingIsSelected,
        }"
      >
        <FilterDetail
          :active="filterIsSelected"
          :service="requestedService"
          :feature="requestedFeature"
          :vehicles="map_pins"
          @close="closeFilterDetail"
        />
        <HubDetail
          v-if="hubIsSelected"
          :service="requestedService"
          @close="closeHubDetail"
        />
        <PoiDetail
          v-if="poiIsSelected"
          :service="requestedService"
          @close="closePoiDetail"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, onMounted, onUnmounted, computed, createApp } from "vue";
import Map from "@/components/map/Map.vue";
import { useI18n } from "vue3-i18n";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useFleetFilter } from "@/composables/filters";
import dayjs from "dayjs";
import VehicleDetail from "./VehicleDetail.vue";
import HubDetail from "./HubDetail.vue";
import PoiDetail from "./PoiDetail.vue";
import FilterDetail from "./FilterDetail.vue";
import toast from "@/services/toast";
import EventBus from "@/libs/event-bus";
import PopupHubRenderer from "@/components/popup-rendering/PopupHubRenderer.vue";
import PopupPoiRenderer from "@/components/popup-rendering/PopupPoiRenderer.vue";
import PopupTruckRenderer from "@/components/popup-rendering/PopupTruckRenderer.vue";

import { vehicles as vehiclesApi } from "@/api";

// eslint-disable-next-line vue/one-component-per-file
export default {
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Map,
    VehicleDetail,
    HubDetail,
    PoiDetail,
    FilterDetail,
  },
  props: {
    vehicle_id: {
      type: [String, Number, null],
      default: null,
    },
  },
  setup(props) {
    const i18n = useI18n();
    const store = useStore();
    const router = useRouter();
    const user = store.getters["auth/user"];
    const map_pins = ref([]);
    const lastUpdate = ref(null);
    const vehiclePanel = ref(false);

    // map
    let mapController = null;

    function handleMapInit(controller) {
      mapController = controller;
      store.dispatch("globalMap/setController", controller);
    }

    function handleMarkerClick({ marker }) {
      router.push({ path: "/global-map", query: { v: marker.id } });
      //selectVehicle(marker.id); //we already call vehicle select from query change watch
      vehiclePanel.value = true;
    }

    function handleCircleClick({ circle }) {
      if (circle.group == "hubs") {
        router.push({ path: "/global-map", query: { h: circle.id } });
        selectHub(circle.id);
      }
      if (circle.group == "pois") {
        router.push({ path: "/global-map", query: { p: circle.id } });
        selectPoi(circle.id);
      }
    }

    function handlePlaceSearch() {
      closeVehicleDetail(true);
    }

    function handleFilter({ service, feature }) {
      if (service == "system" && feature == "all") closeFilterDetail();
      else {
        store.dispatch("globalMap/setRequestedService", service);
        store.dispatch("globalMap/setRequestedFeature", feature);
        router.push({
          path: "/global-map",
          query: { f: feature, s: service },
        });
      }
    }

    // pins
    const pins = ref([]);
    const { filteredCollection: filteredPins } = useFleetFilter({
      collectionRef: pins,
      fetchCollectionCallback: async () => {
        const result = await store.dispatch("globalMap/getPins");
        return result.validated;
      },
    });

    watch(filteredPins, drawMarkers);
    async function drawMarkers() {
      if (!mapController) return;
      mapController.clearGroup("pins");

      const fleets = ref(store.getters["fleets/collection"]);
      const alerts = store.getters["alerts/collection"];
      const agreements = store.getters["agreements/collection"];

      const feature = requestedFeature.value;
      const service = requestedService.value;
      map_pins.value = filteredPins.value;
      const alert = alerts.find((a) => a.name == feature);
      const is_type = service.startsWith("type_");
      if (is_type) {
        const type = service.substring(5);
        switch (feature) {
          case "standby":
            map_pins.value = map_pins.value.filter(
              (pin) => pin.vehicle_type == type && pin.status == 0,
            );
            break;
          case "connected":
            map_pins.value = map_pins.value.filter(
              (pin) => pin.vehicle_type == type && pin.status == 1,
            );
            break;
          case "disconnected":
            map_pins.value = map_pins.value.filter(
              (pin) => pin.vehicle_type == type && pin.status == 2,
            );
            break;
          case "alarm":
            map_pins.value = map_pins.value.filter(
              (pin) => pin.vehicle_type == type && pin.active_alerts.length > 0,
            );
            break;
          default:
            map_pins.value = map_pins.value.filter(
              (pin) => pin.vehicle_type == type,
            );
            break;
        }
      } else if (service == "system") {
        switch (feature) {
          case "all":
            break;
          case "standby":
            map_pins.value = map_pins.value.filter((pin) => pin.status == 0);
            break;
          case "connected":
            map_pins.value = map_pins.value.filter((pin) => pin.status == 1);
            break;
          case "disconnected":
            map_pins.value = map_pins.value.filter((pin) => pin.status == 2);
            break;
          case "alarm":
            map_pins.value = map_pins.value.filter(
              (pin) => pin.active_alerts.length > 0,
            );
            break;
        }
      } else {
        const agreement = agreements.find((a) => a.code == service);
        if (alert) {
          map_pins.value = map_pins.value.filter((pin) => {
            return (
              pin.active_alerts.find(
                (a) =>
                  a.alert_id == alert.id &&
                  (a.customer_id == null || a.customer_id == user.customer_id),
              ) != undefined
            );
          });
        }

        if (agreement) {
          map_pins.value = map_pins.value.filter((pin) => {
            return (
              pin.active_agreements.find(
                (a) =>
                  a.agreement_id == agreement.id &&
                  a.customer_id == user.customer_id,
              ) != undefined
            );
          });
        }
      }
      map_pins.value.forEach(async (p) => {
        const fleet = fleets.value.find((f) => f.id == p.fleet_id);
        const markerColor = fleet ? fleet.color : "#000000";

        mapController.addMarker({
          id: p.vehicle_id,
          latitude: p.latitude ?? 0,
          longitude: p.longitude ?? 0,
          datetime: p.message_datetime,
          icon: p.vehicle_type,
          color: markerColor,
          group: "pins",
          popup: {
            content: function () {
              const app = createApp(PopupTruckRenderer, { vehicle: p })
                .use(store)
                .use(i18n);
              const content = document.createElement("div");
              app.mount(content);
              return content;
            },
          },
          additionalData: {
            plate: p.plate,
            direction: p.direction,
            status: p.status,
            moving: p.moving,
          },
        });
      });
    }

    // hubs
    const hubs = ref([]);
    async function fetchHubs() {
      const response = await store.dispatch("hubs/getCollection");
      hubs.value = response.validated;
    }

    watch(hubs, drawHubs);
    async function drawHubs() {
      if (!mapController) return;
      mapController.clearGroup("hubs");
      hubs.value.forEach((h) => {
        mapController.addCircle({
          id: h.id,
          latitude: h.latitude,
          longitude: h.longitude,
          radius: h.radius,
          color: h.color,
          group: "hubs",
          popup: {
            content: function () {
              const app = createApp(PopupHubRenderer, { hub: h })
                .use(store)
                .use(i18n);
              const content = document.createElement("div");
              app.mount(content);
              return content;
            },
          },
        });
      });
    }

    // pois
    const pois = ref([]);
    async function fetchPois() {
      const response = await store.dispatch("pois/getCollection");
      pois.value = response.validated;
    }

    watch(pois, drawPois);
    async function drawPois() {
      if (!mapController) return;
      mapController.clearGroup("pois");
      pois.value.forEach((h) => {
        mapController.addCircle({
          id: h.id,
          latitude: h.latitude,
          longitude: h.longitude,
          radius: h.radius,
          color: h.color,
          group: "pois",
          popup: {
            content: function () {
              const app = createApp(PopupPoiRenderer, { poi: h })
                .use(store)
                .use(i18n);
              const content = document.createElement("div");
              app.mount(content);
              return content;
            },
          },
        });
      });
    }

    // selections
    const somethingIsSelected = computed(
      () =>
        vehicleIsSelected.value ||
        hubIsSelected.value ||
        poiIsSelected.value ||
        filterIsSelected.value,
    );

    // vehicle
    const vehicleIsSelected = ref(false);
    const selectedVehicle = ref(null);
    const vehicleUpdateHandle = ref(null);

    onMounted(() => {
      EventBus.on("global-map:refresh", () => {
        setTimeout(() => mapController.initMap(), 400);
        clearFilter();

        closeVehicleDetail();
      });
      fetchHubs();
      fetchPois();
      parseQueryString();
      //closeFilterDetail();
      //closePanel();
    });
    onUnmounted(() => {
      EventBus.off("global-map:refresh");
    });

    /*
    onMounted(() => {
      const sv = store.getters["globalMap/externalSelectedVehicle"];
      if (sv) {
        closeHubDetail();
        closePoiDetail();
        // closeFilterDetail();
        selectVehicle(sv.id);
      }else if(props.vehicle_id){
        closeHubDetail();
        closePoiDetail();
        // closeFilterDetail();
        selectVehicle(props.vehicle_id);
      }
    });
*/
    /*
    watch(
      () => store.getters["globalMap/externalSelectedVehicle"],
      (sv) => {
        if (!sv) return;
        closeHubDetail();
        closePoiDetail();
        closeFilterDetail();
        selectVehicle(sv.id);
      }
    );
*/
    watch(
      () => [props.vehicle_id],
      () => {
        console.log("Refresh props.vehicle_id", props.vehicle_id);
        closeHubDetail();
        closePoiDetail();
        closeFilterDetail();
        selectVehicle(props.vehicle_id);
      },
    );

    async function fetchUpdates() {
      if (selectedVehicle.value == null) return;
      if (lastUpdate.value == null) lastUpdate.value = dayjs();

      const from = lastUpdate.value.format();
      lastUpdate.value = dayjs();
      const updatesType = (
        await vehiclesApi.updates(selectedVehicle.value.id, from)
      ).validated;

      if (updatesType) {
        Object.keys(updatesType).forEach((key) => {
          if (Array.isArray(updatesType[key])) {
            updatesType[key].forEach((v) => {
              EventBus.emit("vehicle:updates:" + key, v);
            });
          } else {
            EventBus.emit("vehicle:updates:" + key, updatesType[key]);
          }
        });
      }
    }

    const querySelectorHub = computed(() => router.currentRoute.value.query.h);
    watch(querySelectorHub, (newVal) => {
      if (newVal) {
        //closeFilterDetail()
        //closePoiDetail()
        //hubIsSelected.value = true
        selectHub(newVal);
      }
    });

    const querySelectorVehicle = computed(
      () => router.currentRoute.value.query.v,
    );
    watch(querySelectorVehicle, (newVal) => {
      if (newVal) {
        selectVehicle(newVal);
      }
    });
    const querySelectorPoi = computed(() => router.currentRoute.value.query.p);
    watch(querySelectorPoi, (newVal) => {
      if (newVal) {
        selectPoi(newVal);
      }
    });
    const querySelectorService = computed(
      () => router.currentRoute.value.query.s,
    );
    watch(querySelectorService, (newVal) => {
      if (newVal) {
        selectFilter({
          service: newVal,
          feature: requestedFeature.value,
        });
      }
    });
    const querySelectorFeature = computed(
      () => router.currentRoute.value.query.f,
    );
    watch(querySelectorFeature, (newVal) => {
      if (newVal) {
        selectFilter({
          service: requestedService.value,
          feature: newVal,
        });
      }
    });

    async function parseQueryString() {
      if ("v" in router.currentRoute.value.query) {
        selectVehicle(router.currentRoute.value.query.v);
        if ("a" in router.currentRoute.value.query) {
          selectAlert(
            router.currentRoute.value.query.a,
            router.currentRoute.value.query.lat,
            router.currentRoute.value.query.lng,
            router.currentRoute.value.query.t,
          );
          return;
        }
        return;
      } else if ("h" in router.currentRoute.value.query) {
        selectHub(router.currentRoute.value.query.h);
        return;
      } else if ("p" in router.currentRoute.value.query) {
        selectPoi(router.currentRoute.value.query.p);
        return;
      } else if (
        "f" in router.currentRoute.value.query ||
        "s" in router.currentRoute.value.query
      ) {
        store.dispatch(
          "globalMap/setRequestedService",
          router.currentRoute.value.query.s,
        );
        store.dispatch(
          "globalMap/setRequestedFeature",
          router.currentRoute.value.query.f,
        );
        selectFilter({
          service: router.currentRoute.value.query.s,
          feature: router.currentRoute.value.query.f,
        });
        return;
      } else {
        if (props.vehicle_id) {
          selectVehicle(props.vehicle_id);
        }
      }
    }

    async function selectVehicle(vehicleId) {
      closeHubDetail();
      closePoiDetail();
      clearVehicleUpdateInterval();

      vehiclePanel.value = true;
      vehicleIsSelected.value = true;
      selectedVehicle.value = null;

      mapController.clearGroup("alert");
      mapController.clearGroup("service");
      store.dispatch("globalMap/clear");

      if (vehicleId) {
        if (map_pins.value.length == 0) {
          setTimeout(() => {
            mapController.resizeMap();
            mapController.flyToMarker(vehicleId, "pins");
          }, 2000);
        } else {
          setTimeout(() => {
            mapController.resizeMap();
            mapController.flyToMarker(vehicleId, "pins");
          }, 400);
        }

        store.dispatch("globalMap/getVehicle", vehicleId).then((result) => {
          if (!result.success) {
            toast.error(i18n.t("general.error"));
            vehicleIsSelected.value = false;
            return;
          }
          selectedVehicle.value = result.validated;

          fetchUpdates();
          if (vehicleUpdateHandle.value != null) {
            clearVehicleUpdateInterval();
            vehicleUpdateHandle.value = setInterval(fetchUpdates, 10000);
          } else {
            vehicleUpdateHandle.value = setInterval(fetchUpdates, 10000);
          }

          // ...
        });
      } else {
        selectedVehicle.value = null;

        setTimeout(() => {
          mapController.resizeMap();
        }, 400);
      }
      store.dispatch("globalMap/resetRequestedService");
      store.dispatch("globalMap/resetRequestedFeature");
      closeFilterDetail();
      closePanel();
    }

    async function selectAlert(alertId, lat, lng, message_datetime) {
      const alerts = store.getters["alerts/collection"];
      const activeAlert = alerts.find((a) => a.id == alertId);
      mapController.addMarker({
        id: alertId,
        group: "alert",
        icon: "alert",
        latitude: lat ?? 0,
        longitude: lng ?? 0,
        datetime: message_datetime,
        popup: {
          content: `
                <h1>${activeAlert.description}</h1>
                <small>${dayjs(message_datetime + "Z").format(
                  "DD/MM/YYYY HH:mm:ss",
                )}</small>
              `,
        },
      });

      mapController.flyToMarker(alertId, "alert");
      setTimeout(() => {
        mapController.resizeMap();
      }, 400);
    }

    function closeVehicleDetail(fromsearch) {
      if (fromsearch == true) {
        vehiclePanel.value = false;
        selectedVehicle.value = null;
        //vehicleIsSelected.value = false;
        store.dispatch("globalMap/resetSelectedVehicle");
        router.replace({ query: {} });
      } else {
        vehiclePanel.value = false;
        selectedVehicle.value = null;
        //vehicleIsSelected.value = false;
        store.dispatch("globalMap/resetSelectedVehicle");
        router.replace({ query: {} });
        setTimeout(() => mapController.initMap(), 400);
        clearVehicleUpdateInterval();
      }
      mapController.clearGroup("service");
    }

    function clearVehicleUpdateInterval() {
      if (vehicleUpdateHandle.value != null)
        clearInterval(vehicleUpdateHandle.value);
      vehicleUpdateHandle.value = null;
      lastUpdate.value = null;
    }

    onUnmounted(() => {
      clearVehicleUpdateInterval();
    });

    // hub
    const hubIsSelected = ref(false);
    const selectedHub = ref(null);
    const hubUpdateHandle = ref(null);

    onMounted(() => {
      const sh = store.getters["globalMap/externalSelectedHub"];
      if (sh) selectHub(sh.id);
    });

    watch(
      () => store.getters["globalMap/externalSelectedHub"],
      (sh) => {
        if (!sh) return;
        selectHub(sh.id);
      },
    );

    async function selectHub(hubId) {
      closeVehicleDetail(false);
      closePoiDetail();
      closeFilterDetail();
      hubIsSelected.value = true;
      selectedHub.value = null;

      clearHubUpdateInterval();

      mapController.clearGroup("service");
      store.dispatch("globalMap/clear");
      const result = await store.dispatch("globalMap/getHub", hubId);

      if (!result.success) {
        toast.error(i18n.t("general.error"));
        hubIsSelected.value = false;
        return;
      }

      selectedHub.value = result.validated;

      //router.push({ path: "/global-map", query: { h: hubId } });
      setTimeout(() => {
        mapController.resizeMap();
        mapController.flyToCircle(hubId, "hubs");
      }, 400);
    }

    function closeHubDetail() {
      selectedHub.value = null;
      hubIsSelected.value = false;
      setTimeout(() => mapController.resizeMap(), 400);
      clearHubUpdateInterval();
    }

    function clearHubUpdateInterval() {
      if (hubUpdateHandle.value != null) clearInterval(hubUpdateHandle.value);
      hubUpdateHandle.value = null;
      lastUpdate.value = null;
    }

    onUnmounted(() => {
      clearHubUpdateInterval();
    });

    // poi
    const poiIsSelected = ref(false);
    const selectedPoi = ref(null);
    const poiUpdateHandle = ref(null);

    onMounted(() => {
      const sh = store.getters["globalMap/externalSelectedPoi"];
      if (sh) selectPoi(sh.id);
    });

    watch(
      () => store.getters["globalMap/externalSelectedPoi"],
      (sh) => {
        if (!sh) return;
        selectPoi(sh.id);
      },
    );

    async function selectPoi(poiId) {
      closeVehicleDetail(false);
      closeHubDetail();
      closeFilterDetail();
      poiIsSelected.value = true;
      selectedPoi.value = null;

      clearPoiUpdateInterval();

      mapController.clearGroup("service");
      store.dispatch("globalMap/clear");
      const result = await store.dispatch("globalMap/getPoi", poiId);

      if (!result.success) {
        toast.error(i18n.t("general.error"));
        poiIsSelected.value = false;
        return;
      }

      selectedPoi.value = result.validated;

      setTimeout(() => {
        mapController.resizeMap();
        mapController.flyToCircle(poiId, "pois");
      }, 400);
    }

    function closePoiDetail() {
      selectedPoi.value = null;
      poiIsSelected.value = false;
      setTimeout(() => mapController.resizeMap(), 400);
      clearPoiUpdateInterval();
    }

    function clearPoiUpdateInterval() {
      if (poiUpdateHandle.value != null) clearInterval(poiUpdateHandle.value);
      poiUpdateHandle.value = null;
      lastUpdate.value = null;
    }

    onUnmounted(() => {
      clearPoiUpdateInterval();
    });

    const filterIsSelected = ref(false);

    function closeFilterDetail() {
      filterIsSelected.value = false;
      vehicleIsSelected.value = false;
      setTimeout(() => mapController.resizeMap(), 400);
    }

    function selectFilter({ service, feature }) {
      //vehiclePanel.value = false;
      //selectedVehicle.value = null;
      //store.dispatch("globalMap/resetSelectedVehicle");
      //store.dispatch("globalMap/resetExternalVehicle");
      closeHubDetail();
      closePoiDetail();
      requestedService.value = service;
      requestedFeature.value = feature;
      filterIsSelected.value = true;
      drawMarkers();
      //setTimeout(() => mapController.initMap(), 400);
    }

    function openPanel() {
      if (
        router.currentRoute.value.query.s &&
        router.currentRoute.value.query.f
      ) {
        selectFilter({
          service: router.currentRoute.value.query.s,
          feature: router.currentRoute.value.query.f,
        });
      } else {
        selectFilter({
          service: "system",
          feature: "all",
        });
      }
      //store.dispatch("globalMap/resetSelectedVehicle");
      //vehicleIsSelected.value = false;
      //closeVehicleDetail();
      //setTimeout(() => mapController.resizeMap(), 400);
    }

    function closePanel() {
      //selectedVehicle.value = null;
      //vehicleIsSelected.value = false;
      //store.dispatch("globalMap/resetSelectedVehicle");
      //clearVehicleUpdateInterval();
      //closeVehicleDetail(false);
      closeHubDetail();
      closePoiDetail();
      closeFilterDetail();
      //setTimeout(() => mapController.initMap(), 400);
    }

    // requested service
    const requestedService = ref(store.getters["globalMap/requestedService"]);

    watch(
      () => store.getters["globalMap/requestedService"],
      (v) => {
        selectFilter({
          service: v,
          feature: requestedFeature.value,
        });
      },
    );
    // requested feature
    const requestedFeature = ref(store.getters["globalMap/requestedFeature"]);
    watch(
      () => store.getters["globalMap/requestedFeature"],
      (v) => {
        selectFilter({
          service: requestedService.value,
          feature: v,
        });
      },
    );

    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const customParseFormat = require("dayjs/plugin/customParseFormat");
    dayjs.extend(customParseFormat);

    function clearFilter() {
      store.dispatch("globalMap/resetRequestedService");
      store.dispatch("globalMap/resetRequestedFeature");
      requestedService.value = "system";
      requestedFeature.value = "all";
      router.replace({ query: {} });
      //emit("filter", { service, feature });
    }

    return {
      i18n,
      // filter
      handleFilter,
      selectFilter,
      map_pins,

      // map
      handleMapInit,
      handleMarkerClick,
      handleCircleClick,
      handlePlaceSearch,

      // pins
      pins,

      // selection
      somethingIsSelected,

      // vehicle
      vehicleIsSelected,
      selectedVehicle,
      closeVehicleDetail,
      vehiclePanel,

      // poi
      poiIsSelected,
      selectedPoi,
      closePoiDetail,

      filterIsSelected,
      closeFilterDetail,

      // hub
      hubIsSelected,
      selectedHub,
      closeHubDetail,

      // requested service
      requestedService,

      // requested feature
      requestedFeature,

      closePanel,

      openPanel,
    };
  },
};
</script>
