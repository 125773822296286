import i18n from "../i18n";
const state = () => {
  return {
    menu: [
      {
        icon: "HomeIcon",
        pageName: "dashboard",
        title: i18n.t("navigation.dashboard"),
      },
      {
        icon: "ShieldIcon",
        pageName: "hubs-index",
        title: i18n.t("navigation.hubs"),
      },
      {
        icon: "TagIcon",
        pageName: "pois-index",
        title: i18n.t("navigation.pois"),
      },
      {
        icon: "FlagIcon",
        pageName: "fleets-index",
        title: i18n.t("navigation.fleets"),
      },
      {
        icon: "DollarSignIcon",
        pageName: "tcos-index",
        title: i18n.t("navigation.tcos"),
      },
      {
        icon: "UsersIcon",
        pageName: "drivers-activity-index",
        title: i18n.t("navigation.drivers"),
      },
    ],
  };
};

// getters
const getters = {
  menu: (state) => state.menu,
};

// actions
const actions = {};

// mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
