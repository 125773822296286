<template>
  <div id="eyegeo-dashboard">
    <div class="content">
      <simple-title>{{ $t("services.eyegeo.name") }}</simple-title>

      <div class="grid grid-cols-12 gap-6">
        <div class="col-span-12 xxl:col-span-9">
          <Map :coordinates="null" :zoom="5" :markers="mks"></Map>
          <button @click="mks.push($map.marker(44.7, 8.9))">add</button>
          <!-- <EyegeoMap :vehicles="vehicles"></EyegeoMap> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Map from "@/components/map/Map";
export default {
  // eslint-disable-next-line vue/no-reserved-component-names
  components: { Map },

  data() {
    return {
      vehicles: [],
      mks: [
        this.$map.marker(44.91666, 8.616667),
        this.$map.marker(44.92, 8.8),
        this.$map.marker(44.92, 10.8),
        this.$map.marker(45.92, 9.8),
      ],
    };
  },

  beforeMount() {
    this.getVehicles();
  },

  methods: {
    async getVehicles() {
      this.vehicles = await this.$store.dispatch("eyegeo/getVehicles");
    },
  },
};
</script>

<style></style>
