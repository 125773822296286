<template>
  <div v-if="vehicle" id="service-eyecontrol">
    <div v-if="data">
      <div class="flex justify-between items-baseline mb-4">
        <div class="text-lg capitalize">
          {{ i18n.t("services.eyecontrol.last_status") }}
        </div>
        <DateAgo
          v-if="data.command"
          :datetime="data.command.message_datetime"
        ></DateAgo>
        <div v-else>
          {{ i18n.t("services.no_data") }}
        </div>
      </div>

      <div v-if="data.command" class="report-box mb-8">
        <div class="box px-4 py-4 mb-3 flex items-center justify-around">
          <VehicleParameter
            :active="data.command.status !== null"
            :name="i18n.t('services.eyecontrol.status.name')"
            icon="TrendingDownIcon"
            :value="data.command.status"
            :setclass="
              () =>
                data.command.status == 5 ||
                data.command.status == 4 ||
                data.command.status == 1
                  ? 'bg-reyesol-alert-warning'
                  : data.command.status == 7 ||
                    data.command.status == 6 ||
                    data.command.status == 3
                  ? 'bg-reyesol-alert-alarm'
                  : 'bg-reyesol-gray'
            "
            :format="(val) => i18n.t('services.eyecontrol.status.' + val)"
          />
          <VehicleParameter
            :active="data.command.user !== null"
            :name="i18n.t('services.eyecontrol.user.name')"
            icon="UserIcon"
            :value="data.command.user"
          />
          <VehicleParameter
            :active="data.command.command !== null"
            :name="i18n.t('services.eyecontrol.command.name')"
            icon="SlidersIcon"
            :value="
              i18n.t('services.eyecontrol.command.' + data.command.command)
            "
          />
          <VehicleParameter
            :active="data.command.command_data !== null"
            :name="i18n.t('services.eyecontrol.command_data.name')"
            icon="InfoIcon"
          >
            <div
              v-for="(item, index) in JSON.parse(data.command.command_data)"
              v-bind:key="item"
            >
              {{ i18n.t("services.eyecontrol.command_data." + index) }}:
              {{ item }}
            </div>
          </VehicleParameter>
        </div>
      </div>
      <div v-else class="flex justify-center py-6">
        <loading-icon icon="rings" class="w-20 h-20" />
      </div>
      <div class="py-6">
        <div class="text-lg capitalize mb-4">
          {{ i18n.t("services.eyecontrol.command_history") }}
          <Eyepicker v-model="daterange" />
        </div>
        <div
          v-if="!loadingHistory"
          class="max-h-80 overflow-y-auto text-center"
        >
          <div v-if="history && history.list">
            <div v-for="h in history.list.command" :key="h.id">
              <div class="intro-y">
                <div
                  class="box px-4 py-4 mb-3 flex items-center cursor-pointer"
                  @click="handleHistoryClick(h)"
                >
                  <div class="flex grow">
                    {{ dayjs(h.message_datetime).format("DD/MM/YYYY HH:mm") }}
                  </div>
                  <VehicleParameter
                    :active="h.status !== null"
                    :name="i18n.t('services.eyecontrol.status.name')"
                    icon="TrendingDownIcon"
                    :value="h.status"
                    :setclass="
                      () =>
                        h.status == 5 || h.status == 4 || h.status == 1
                          ? 'bg-reyesol-alert-warning'
                          : h.status == 7 || h.status == 6 || h.status == 3
                          ? 'bg-reyesol-alert-alarm'
                          : 'bg-reyesol-gray'
                    "
                    :format="
                      (val) => i18n.t('services.eyecontrol.status.' + val)
                    "
                  />
                  <VehicleParameter
                    :active="h.user !== null"
                    :name="i18n.t('services.eyecontrol.user.name')"
                    icon="UserIcon"
                    :value="h.user"
                  />
                  <VehicleParameter
                    :active="h.command !== null"
                    :name="i18n.t('services.eyecontrol.command.name')"
                    icon="SlidersIcon"
                    :value="i18n.t('services.eyecontrol.command.' + h.command)"
                  />
                  <VehicleParameter
                    :active="h.command_data !== null"
                    :name="i18n.t('services.eyecontrol.command_data.name')"
                    icon="InfoIcon"
                  >
                    <div
                      v-for="(item, index) in JSON.parse(h.command_data)"
                      v-bind:key="item"
                    >
                      {{ i18n.t("services.eyecontrol.command_data." + index) }}:
                      {{ item }}
                    </div>
                  </VehicleParameter>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="font-light text-xl text-gray-500">
            {{ i18n.t("general.no-results") }}
          </div>
        </div>
        <loading-icon v-else icon="rings" class="w-20 h-20" />
        <div class="btn-reyesol-report" @click="requestReport">
          <FileTextIcon /> {{ i18n.t("general.request-report") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { watch, ref, computed, reactive, onUnmounted, onMounted } from "vue";
import { useI18n } from "vue3-i18n";
import { useStore } from "vuex";
import { useDaterange } from "@/composables/daterange";
import dayjs from "dayjs";
import EventBus from "@/libs/event-bus";
import VehicleParameter from "@/components/vehicle/VehicleParameter.vue";

export default {
  components: { VehicleParameter },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const i18n = useI18n();
    const vehicle = computed(() => store.getters["globalMap/selectedVehicle"]);
    const mapController = store.getters["globalMap/controller"];
    const data = reactive(ref(null));
    const history = reactive(ref(null));
    const loadingHistory = ref(false);
    const selectedEvent = ref(0);
    const {
      daterange,
      from: historyFrom,
      to: historyTo,
    } = useDaterange({
      onChange: getHistory,
    });

    // watch service visibility
    watch(
      () => props.active,
      () => {
        if (props.active) initService();
        else destroyService();
      },
    );

    function updateCommand(command) {
      if (data.value == null) return;
      data.value.command = command;

      if (
        dayjs().isSame(historyTo.value, "day") &&
        history.value != null &&
        history.value.list &&
        history.value.list.command.length
      )
        history.value.list.command.unshift(command);
    }

    onMounted(() => {
      EventBus.on("vehicle:updates:command", updateCommand);
    });
    onUnmounted(() => {
      EventBus.off("vehicle:updates:command");
    });

    // init service
    async function initService() {
      const dataResult = await store.dispatch(
        "eyecontrol/getVehicleData",
        vehicle.value.id,
      );
      data.value = dataResult.validated;

      getHistory();
    }

    async function handleHistoryClick(history) {
      if (history.id == selectedEvent.value.id) {
        mapController.flyToDate(history.message_datetime, "service");
        return;
      }
      selectedEvent.value = history;
      EventBus.emit("notification:show", {
        type: "info",
        title: i18n.t("services.open_eyegeo_before"),
      });
      EventBus.emit("service:history", {
        vehicle_id: vehicle.value.id,
        from: historyFrom.value,
        to: historyTo.value,
        ev: history,
      });
    }

    // requestReport service
    async function requestReport() {
      emit(
        "report-request",
        ["eyecontrol"],
        "master",
        [].concat(
          store.getters["globalMap/selectedVehicleSensors"].eyecold,
          store.getters[
            "globalMap/selectedVehicleSensors"
          ].eyecold_fridge.filter(
            (v) =>
              v == "eyecold_fridge.overall_status" ||
              v == "eyecold_fridge.alarm_status",
          ),
          store.getters["globalMap/selectedVehicleSensors"].eyedoor,
          store.getters["globalMap/selectedVehicleSensors"].eyecontrol,
        ),
      );
    }

    // destroy service
    function destroyService() {}

    // get history
    async function getHistory() {
      if (!props.active) return;
      loadingHistory.value = true;

      const historyResult = await store.dispatch(
        "eyecontrol/getVehicleHistory",
        {
          vehicleId: vehicle.value.id,
          dateFrom: dayjs(historyFrom.value).format(),
          dateTo: dayjs().isSame(dayjs(historyTo.value), "day")
            ? dayjs().format()
            : dayjs(historyTo.value).format(),
        },
      );
      history.value = historyResult.validated;

      loadingHistory.value = false;
    }

    return {
      i18n,
      dayjs,
      vehicle,
      data,
      history,
      requestReport,
      loadingHistory,
      handleHistoryClick,
      daterange,
    };
  },
};
</script>

<style></style>
