<template>
  <div id="notifications">
    <div class="flex justify-between items-center py-2">
      <simple-title>{{ i18n.t("general.notifications") }}</simple-title>
      <div class="text-theme-1 cursor-pointer" @click="markAllAsRead()">
        {{ i18n.t("general.mark-all-as-read") }}
      </div>
    </div>

    <div>
      <div
        v-for="n in notifications"
        :key="n.id"
        class="box p-6 mb-6 relative cursor-pointer"
      >
        <div
          v-if="!n.read_at"
          class="absolute -top-2 -right-2 px-2 py-1 rounded-md bg-theme-24 text-white"
        >
          {{ i18n.t("general.unreaded") }}
        </div>
        <div class="flex justify-between items-center">
          <div class="flex items-center flex-1" @click="openNotification(n)">
            <div class="flex items-center mr-6">
              <component :is="getIcon(n)" />
            </div>
            <div class="col-span-4">
              <div class="font-medium">{{ i18n.t(n.data.message) }}</div>
              <div class="text-gray-600 text-xs mt-0.5">
                {{ dayjs(n.data.datetime).format("DD/MM/YYYY HH:mm") }}
              </div>
            </div>
          </div>
          <div>
            <div class="dropdown">
              <button
                class="dropdown-toggle p-4 focus:outline-none"
                aria-expanded="false"
              >
                <MoreVerticalIcon />
              </button>
              <div class="dropdown-menu w-48">
                <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                  <div
                    class="flex items-center p-2 transition duration-300 ease-in-out bg-white hover:bg-gray-200 rounded-md cursor-pointer"
                    @click="markAsRead(n)"
                  >
                    <CheckIcon class="w-4 h-4 mr-2" />
                    {{ i18n.t("general.mark-as-read") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="h-0 overflow-hidden transition-all"
          :class="{ 'h-auto': n.opened }"
        >
          <div class="pt-6">
            <div v-if="n.type == 'report'">
              <a :href="n.data.url" class="text-theme-1 underline"
                >Scarica il tuo report</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useI18n } from "vue3-i18n";
import { useStore } from "@/store";
import EventBus from "@/libs/event-bus";
import dayjs from "dayjs";

export default {
  setup() {
    const i18n = useI18n();
    const store = useStore();
    const loading = ref(false);

    let pageNum = 0;
    let totalPages = 1;
    const notifications = ref([]);

    async function nextPage() {
      if (pageNum == totalPages) return;
      loading.value = true;

      const result = await store.dispatch("notifications/getPage", pageNum + 1);
      if (result.success) {
        pageNum++;
        totalPages = result.meta.total_pages;
        const arr = [];
        result.validated.forEach((n) => arr.push({ ...n, opened: false }));
        notifications.value = [...arr];
      }

      loading.value = false;
    }
    nextPage();

    async function openNotification(notification) {
      notification.opened = true;
      if (!notification.read_at) notification.read_at = true;
      await store.dispatch("notifications/getNotification", notification.id);
    }

    async function markAsRead(notification) {
      notification.read_at = true;
      const result = await store.dispatch(
        "notifications/markNotification",
        notification.id,
      );
      notification.read_at = result.validated;
    }

    function markAllAsRead() {
      EventBus.emit("confirm-dialog:show", {
        title: `${i18n.t("general.mark-all-as-read")}`,
        text: `${i18n.t("general.mark-all-as-read-confirm")}`,
        callback: async (confirm) => {
          if (confirm) {
            console.log("mark all");
          }
        },
      });
    }

    function getIcon(notification) {
      switch (notification.type) {
        case "user":
          return "UserIcon";

        case "report":
          return "FileTextIcon";

        default:
          return "AlertCircleIcon";
      }
    }

    return {
      i18n,
      dayjs,
      notifications,
      getIcon,
      markAsRead,
      markAllAsRead,
      openNotification,
      nextPage,
    };
  },
};
</script>
