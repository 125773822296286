<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8 h-full">
      <h2 class="text-lg font-medium mr-auto">
        {{ i18n.t("general.report") }}
      </h2>
      <button
        id="tabulator-print"
        class="btn btn-outline-primary w-1/2 sm:w-auto mr-2"
        @click="onDownload"
      >
        <DownloadCloudIcon class="w-4 h-4 mr-2" /> Download
      </button>
      <div
        v-if="
          from != null && to != null && from.value != null && to.value != null
        "
        class="w-full sm:w-auto flex mt-4 sm:mt-0"
      >
        <div class="bg-primary shadow-md mr-2">
          {{ from.value.format("YYYY-MM-DD") }}
        </div>
        <div class="bg-primary shadow-md mr-2">
          {{ to.value.format("YYYY-MM-DD") }}
        </div>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5 h-full">
      <div v-if="loading" class="flex justify-center py-6">
        <loading-icon icon="rings" class="w-20 h-20" />
      </div>
      <div
        v-if="report && report.type != 'temp'"
        id="grid"
        class="h-full w-full"
      ></div>
      <embed
        v-else
        id="grid"
        src=""
        width="100%"
        height="100%"
        class="min-h-screen w-full"
      />
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "@/store";
import dayjs from "dayjs";
import { useRouter } from "vue-router";
import { useI18n } from "vue3-i18n";
import EventBus from "@/libs/event-bus";
import * as XLSX from "xlsx/xlsx.mjs";
import canvasDatagrid from "canvas-datagrid";

export default {
  setup() {
    const i18n = useI18n();
    const router = useRouter();
    const store = useStore();
    const table_data = ref(null);
    const report = ref(null);
    const from = ref(null);
    const to = ref(null);
    const loading = ref(true);

    onMounted(async () => {
      if ("r" in router.currentRoute.value.query) {
        const report_id = router.currentRoute.value.query.r;
        const getReportresult = await store.dispatch(
          "reports/getReport",
          report_id,
        );
        report.value = getReportresult.validated;
        from.value = dayjs(report.value.period_from);
        to.value = dayjs(report.value.period_to);
        let _grid;
        const file_result = await store.dispatch(
          "reports/downloadReport",
          report_id,
        );
        console.log(file_result);
        if (file_result.success == false) {
          EventBus.emit("notification:show", {
            type: "error",
            title: i18n.t("reports.no-data"),
          });
          router.push({ path: `/reports` });
        } else {
          if (report.value.type == "temp") {
            _grid = document.getElementById("grid");
            const base64_data = btoa(
              new Uint8Array(file_result).reduce((data, byte) => {
                return data + String.fromCharCode(byte);
              }, ""),
            );
            _grid.src = `data:application/pdf;base64,${base64_data}`;
          } else {
            const wb = XLSX.read(file_result, { type: "base64" }); //XLSX: / xlsx.core.min.js read excel through XLSX.read(data, {type: type}) method
            console.log("XLSX", wb);
            const result = {};
            wb.SheetNames.forEach(function (sheetName) {
              const roa = XLSX.utils.sheet_to_json(wb.Sheets[sheetName], {
                raw: false,
                header: 1,
              });
              if (roa.length > 0) result[sheetName] = roa;
            });
            const sheet = wb.SheetNames[0];
            const json = result[sheet];

            _grid = document.getElementById("grid");

            const cdg = canvasDatagrid({
              parentNode: _grid,
            });
            cdg.style.height = "100%";
            cdg.style.width = "100%";

            /* show grid */
            _grid.style.display = "block";

            /* set up table headers */
            let L = 0;
            json.forEach(function (r) {
              if (L < r.length) L = r.length;
            });
            for (let i = json[0].length; i < L; ++i) {
              json[0][i] = "";
            }
            /* load data */
            cdg.data = json;
          }
        }
        loading.value = false;
      } else {
        router.push({ path: `/reports` });
      }
    });

    // Downalod
    const onDownload = async function () {
      const file_result = await store.dispatch("reports/linkReport", {
        id: report.value.id,
        hash: report.value.hash,
      });
      if (file_result.success == false) {
        EventBus.emit("notification:show", {
          type: "error",
          title: i18n.t("reports.no-data"),
        });
        router.push({ path: `/reports` });
      }
    };

    return {
      i18n,
      report,
      from,
      to,
      loading,
      dayjs,
      table_data,
      onDownload,
    };
  },
};
</script>
