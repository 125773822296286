<template>
  <div>
    <div class="mb-2">
      <div class="font-bold text-lg">{{ name }}</div>
      <div class="text-sm text-gray-500">{{ description }}</div>
    </div>
    <div class="flex flex-col space-y-2">
      <Datepicker
        v-model="state"
        multi-calendars="true"
        :format="dateFormat"
        auto-apply
        :close-on-auto-apply="false"
        show-week-numbers
        :max-date="maxDate"
        :min-date="minDate"
        locale="it-IT"
        :clearable="false"
        class="w-full"
        :disabled="sending"
      />
      <button class="btn btn-primary" :disabled="sending" @click="trySend()">
        <span v-if="!sending">
          {{ i18n.t("general.send") }}
        </span>
        <span v-else class="py-2">
          <loading-icon icon="three-dots" color="white" class="h-2" />
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import { useI18n } from "vue3-i18n";
import EventBus from "@/libs/event-bus";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
export default {
  props: {
    name: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    initialValue: {
      type: String,
      default: "",
    },
    minDate: {
      type: Object,
      default: null,
    },
    maxDate: {
      type: Object,
      default: null,
    },
    dateFormat: {
      type: String,
      default: "dd/MM/yyyy HH:mm",
    },
    ask: {
      type: Boolean,
      default: true,
    },
    autosend: {
      type: Boolean,
      default: false,
    },
  },
  components: { Datepicker },
  emits: ["send"],
  setup(props, { emit }) {
    const i18n = useI18n();
    const sending = ref(false);
    watch(
      () => props.initialValue,
      (v) => {
        sending.value = false;
        state.value = v;
      },
    );

    const state = ref(props.initialValue);

    function trySend() {
      if (props.ask) {
        EventBus.emit("confirm-dialog:show", {
          title: i18n.t("services.eyecontrol.confirm-title"),
          text: i18n.t("services.eyecontrol.confirm-text"),
          doubleCheck: true,
          doubleCheckText: i18n.t("services.eyecontrol.confirm-toggle-text"),
          callback: async (confirm) => {
            if (confirm) send();
          },
        });
      } else {
        send();
      }
    }

    function send(value) {
      if (state.value == value) return;
      sending.value = true;
      emit("send", state.value);
      if (props.autosend) sending.value = false;
    }

    return { i18n: useI18n(), state, trySend, send, sending };
  },
};
</script>

<style></style>
