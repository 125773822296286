<template>
  <div class="overflow-hidden">
    <!-- detail panel -->
    <div v-if="poi">
      <!-- heading -->
      <div class="flex items-end justify-between">
        <div class="flex items-baseline">
          <span class="text-xl font-bold mr-4">{{ poi.code }}</span>
          <span class="text-sm mr-4">{{ poi.model }}</span>
        </div>
      </div>
    </div>
    <div class="py-6">
      <div class="text-lg capitalize mb-4">
        {{ i18n.t("poi.vehicles") }}
      </div>
      <div class="overflow-y-auto">
        <div v-if="vehicles && vehicles.length > 0">
          <div v-for="v in vehicles" :key="v.vehicle_id">
            <div class="intro-y">
              <VehicleCard :vehicle="v" @select="handleVehicleClick" />
            </div>
          </div>
        </div>
        <div v-else class="font-light text-xl text-gray-500">
          {{ i18n.t("general.no-results") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, reactive, watch, onMounted } from "vue";
import { useI18n } from "vue3-i18n";
import { useStore } from "vuex";
import dayjs from "dayjs";
// import EventBus from "@/libs/event-bus";
import VehicleCard from "./VehicleCard.vue";
import { useRouter } from "vue-router";

export default {
  components: { VehicleCard },
  props: {
    service: {
      type: [String, null],
      default: null,
    },
  },
  emits: ["close"],
  setup(props, { emit }) {
    const i18n = useI18n();
    const store = useStore();
    const router = useRouter();

    const vehicles = reactive(ref([]));

    // initialization
    const poi = computed(() => store.getters["globalMap/selectedPoi"]);

    watch(
      () => store.getters["globalMap/selectedPoi"],
      () => {
        if (poi.value) {
          get_vehicle();
        }
      },
    );

    async function get_vehicle() {
      if (poi.value) {
        const pois = await store.dispatch("pois/getVehicles", poi.value.id);

        const vehicle = pois.validated;
        const result = reactive([]);
        const pins = await store.dispatch("globalMap/getPins");

        vehicles.value = pins.validated;
        vehicle.forEach((a) => {
          const match = vehicles.value.find(
            (v) => v.vehicle_id == a.vehicle_id,
          );
          if (match != undefined) {
            result.push(match);
          }
        });
        vehicles.value = result;
      }
    }

    function handleVehicleClick(v) {
      store.dispatch("globalMap/setExternalVehicle", { id: v.vehicle_id });
      router.push({
        path: "/global-map",
        query: { v: v.vehicle_id },
      });
    }

    function closePanel() {
      emit("close");
    }

    onMounted(() => {
      get_vehicle();
    });

    return {
      dayjs,
      i18n,
      poi,
      vehicles,
      closePanel,
      handleVehicleClick,
    };
  },
};
</script>

<style></style>
