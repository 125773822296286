<template>
  <div v-if="vehicle" id="service-eyefuel">
    <div v-if="data">
      <div class="flex justify-between items-baseline mb-4">
        <div class="text-lg capitalize">
          {{ i18n.t("services.eyefuel.last_status") }}
        </div>
        <DateAgo
          v-if="data.fuel"
          :datetime="data.fuel.message_datetime"
        ></DateAgo>
        <div v-else>
          {{ i18n.t("services.no_data") }}
        </div>
      </div>
      <!--Summary Data-->
      <div v-if="data.fuel" class="report-box cursor-pointer mb-8">
        <div class="box p-5">
          <div class="flex flex-wrap items-center justify-center">
            <!--Fuel Level-->
            <VehicleParameter
              :active="data.fuel.tank_usage !== null"
              :name="i18n.t('services.eyefuel.tank_usage.name')"
              icon="TrendingDownIcon"
              :setclass="
                () =>
                  data.fuel.tank_usage <= 25
                    ? data.fuel.tank_usage <= 15
                      ? 'bg-reyesol-alert-alarm'
                      : 'bg-reyesol-alert-warning'
                    : 'bg-reyesol-gray'
              "
              :value="data.fuel.tank_usage + ' %'"
            />
          </div>
          <div class="flex flex-wrap justify-around">
            <VehicleParameter
              :active="
                vehicle.configurations.cap_status_active == 'true' &&
                data.fuel.cap_status !== null
              "
              :name="i18n.t('services.eyefuel.cap_status.name')"
              icon="ArchiveIcon"
              :value="
                data.fuel.cap_status
                  ? i18n.t('services.eyefuel.cap_status.opened')
                  : i18n.t('services.eyefuel.cap_status.closed')
              "
            />

            <VehicleParameter
              :active="data.fuel.alarm_status !== null"
              :name="i18n.t('services.eyefuel.alarm_status.name')"
              icon="AlertTriangleIcon"
              :value="
                data.fuel.alarm_status
                  ? i18n.t('services.eyefuel.alarm_status.theft')
                  : i18n.t('services.eyefuel.alarm_status.none')
              "
            />

            <VehicleParameter
              :active="
                vehicle.configurations.siren_active == 'true' &&
                data.siren?.armed !== null
              "
              :name="i18n.t('services.eyefuel.armed.name')"
              :icon="data.fuel.armed ? 'BellIcon' : 'BellOffIcon'"
              :setclass="
                () =>
                  data.fuel.armed && data.fuel.cap_status
                    ? 'bg-reyesol-alert-alarm'
                    : 'bg-reyesol-gray'
              "
              :value="
                data.fuel.armed
                  ? data.fuel.cap_status
                    ? i18n.t('services.eyefuel.armed.active')
                    : i18n.t('services.eyefuel.armed.armed')
                  : i18n.t('services.eyefuel.armed.none')
              "
            />
          </div>
        </div>
      </div>
    </div>
    <div v-else class="flex justify-center py-6">
      <loading-icon icon="rings" class="w-20 h-20" />
    </div>

    <!--History-->
    <div class="py-6">
      <div class="text-lg capitalize mb-4">
        {{ i18n.t("services.eyefuel.fuel_history") }}
        <Eyepicker v-model="daterange" />
      </div>

      <div v-if="!loadingHistory">
        <div
          v-if="
            history &&
            history.list &&
            history.list.fuel &&
            history.list.fuel.length > 0
          "
        >
          <FuelChart :active="active" :height="200" :fuel="history.list.fuel" />
        </div>
        <div v-else class="font-light text-xl text-gray-500">
          {{ i18n.t("general.no-results") }}
        </div>
      </div>
      <div v-else class="flex justify-center py-6">
        <loading-icon icon="rings" class="w-20 h-20" />
      </div>
      <div class="text-lg capitalize mb-4">
        {{ i18n.t("services.eyefuel.fuel_event_history") }}
      </div>
      <!--Event History-->
      <div v-if="!loadingHistory" class="max-h-80 overflow-y-auto">
        <div v-if="history && history.list">
          <div v-for="h in history.list.fuel" :key="h.id">
            <div class="intro-y">
              <div
                class="px-4 py-4 mb-3 mx-2 flex cursor-pointer rounded-md shadow-md hover:shadow-lg hover:bg-gray-200"
                @click="handleHistoryClick(h)"
              >
                <!--Event Datetime-->
                <div class="flex flex-wrap w-full mr-0 items-center">
                  <div class="flex flex-row mr-min-12 mr-0 items-center">
                    <div
                      class="w-10 h-10 justify-center image-cover rounded-md overflow-hidden"
                    >
                      <img
                        alt="pin image"
                        :src="require('@/assets/images/marker-icon-small.png')"
                      />
                    </div>
                    <div class="ml-2">
                      <div class="font-medium">
                        <DateAgo :datetime="h.message_datetime"></DateAgo>
                      </div>
                    </div>
                  </div>

                  <div class="flex flex-row ml-auto">
                    <VehicleParameter
                      :active="h.tank_usage !== null"
                      :name="i18n.t('services.eyefuel.tank_usage.name')"
                      icon="TrendingDownIcon"
                      :setclass="
                        () =>
                          h.tank_usage <= 25
                            ? h.tank_usage <= 15
                              ? 'bg-reyesol-alert-alarm'
                              : 'bg-reyesol-alert-warning'
                            : 'bg-reyesol-gray'
                      "
                      :value="h.tank_usage + ' %'"
                    />

                    <VehicleParameter
                      :active="
                        vehicle.configurations.cap_status_active == 'true' &&
                        h.cap_status !== null
                      "
                      :name="i18n.t('services.eyefuel.cap_status.name')"
                      icon="ArchiveIcon"
                      :value="
                        h.cap_status
                          ? i18n.t('services.eyefuel.cap_status.opened')
                          : i18n.t('services.eyefuel.cap_status.closed')
                      "
                    />

                    <VehicleParameter
                      :active="h.alarm_status !== null"
                      :name="i18n.t('services.eyefuel.alarm_status.name')"
                      icon="AlertTriangleIcon"
                      :value="
                        h.alarm_status
                          ? i18n.t('services.eyefuel.alarm_status.theft')
                          : i18n.t('services.eyefuel.alarm_status.none')
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="font-light text-xl text-gray-500">
          {{ i18n.t("general.no-results") }}
        </div>
      </div>
      <loading-icon v-else icon="rings" class="w-20 h-20" />
      <div class="btn-reyesol-report" @click="requestReport">
        <FileTextIcon /> {{ i18n.t("general.request-report") }}
      </div>
    </div>
  </div>
</template>

<script>
import FuelChart from "@/components/fuel-chart/Main.vue";
import { watch, ref, computed, reactive, onUnmounted, onMounted } from "vue";
import { useI18n } from "vue3-i18n";
import { useStore } from "vuex";
import { useDaterange } from "@/composables/daterange";
import dayjs from "dayjs";
import EventBus from "@/libs/event-bus";
import VehicleParameter from "@/components/vehicle/VehicleParameter.vue";

export default {
  components: {
    FuelChart,
    VehicleParameter,
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const i18n = useI18n();
    const vehicle = computed(() => store.getters["globalMap/selectedVehicle"]);
    const mapController = store.getters["globalMap/controller"];
    const data = reactive(ref(null));
    const history = reactive(ref(null));
    const selectedEvent = ref(0);
    const loadingHistory = ref(false);
    const {
      daterange,
      from: historyFrom,
      to: historyTo,
    } = useDaterange({
      onChange: getHistory,
    });

    // watch service visibility
    watch(
      () => props.active,
      () => {
        if (props.active) initService();
        else destroyService();
      },
    );

    function updateFuel(fuel) {
      if (data.value == null) return;
      data.value.fuel = fuel;

      if (
        dayjs().isSame(historyTo.value, "day") &&
        history.value != null &&
        history.value.list &&
        history.value.list.fuel.length
      )
        history.value.list.fuel.unshift(fuel);
    }

    function updateSiren(siren) {
      if (data.value == null) return;
      data.value.siren = siren;

      if (
        history.value != null &&
        history.value.list &&
        history.value.list.siren?.length
      )
        history.value.list.siren?.unshift(siren);
    }

    onMounted(() => {
      EventBus.on("vehicle:updates:fuel", updateFuel);
      EventBus.on("vehicle:updates:siren", updateSiren);
    });
    onUnmounted(() => {
      EventBus.off("vehicle:updates:fuel");
      EventBus.off("vehicle:updates:siren");
    });

    // init service
    async function initService() {
      const dataResult = await store.dispatch(
        "eyefuel/getVehicleData",
        vehicle.value.id,
      );
      data.value = dataResult.validated;
      getHistory();
    }

    async function handleHistoryClick(history) {
      if (history.id == selectedEvent.value.id) {
        mapController.flyToDate(history.message_datetime, "service");
        return;
      }
      selectedEvent.value = history;
      EventBus.emit("notification:show", {
        type: "info",
        title: i18n.t("services.open_eyegeo_before"),
      });

      EventBus.emit("service:history", {
        vehicle_id: vehicle.value.id,
        from: historyFrom.value,
        to: historyTo.value,
        ev: history,
      });
    }

    // requestReport service
    async function requestReport() {
      emit(
        "report-request",
        store.getters["globalMap/selectedVehicleSensors"].eyefuel,
        "eyefuel",
        store.getters["globalMap/selectedVehicleSensors"].eyefuel,
      );
    }

    // destroy service
    function destroyService() {}

    // get history
    async function getHistory() {
      if (!props.active) return;
      loadingHistory.value = true;

      const historyResult = await store.dispatch("eyefuel/getVehicleHistory", {
        vehicleId: vehicle.value.id,
        dateFrom: dayjs(historyFrom.value).format(),
        dateTo: dayjs().isSame(dayjs(historyTo.value), "day")
          ? dayjs().format()
          : dayjs(historyTo.value).format(),
      });
      history.value = historyResult.validated;

      loadingHistory.value = false;
    }

    return {
      i18n,
      dayjs,
      vehicle,
      data,
      history,
      requestReport,
      loadingHistory,
      handleHistoryClick,
      daterange,
    };
  },
};
</script>

<style></style>
