<template>
  <div
    v-if="loading"
    id="loading"
    class="h-screen flex flex-col items-center justify-center"
  >
    <loading-icon icon="rings" color="white" class="w-40 h-40" />
  </div>
  <div v-else>
    <ConfirmDialog />
    <NotificationsToasts />
    <router-view />
  </div>

  <ModalsContainer />
</template>
<script>
import { useI18n } from "vue3-i18n";
import { useStore } from "vuex";
import { ref } from "vue";
import { ModalsContainer } from "vue-final-modal";

export default {
  components: { ModalsContainer },
  provide: {},
  setup() {
    const loading = ref(true);
    const store = useStore();
    const i18n = useI18n();

    async function initResources() {
      if (store.getters["auth/token"]) {
        const user = store.getters["auth/user"];
        if (!user) {
          await store.dispatch("auth/loadUserAndCustomerInfos");
        }
        await store.dispatch("agreements/getCollection");
        await store.dispatch("alerts/getCollection");
        await store.dispatch("alerts/getChannels");
        i18n.setLocale(store.getters["main/language"]);
      }

      loading.value = false;
    }
    initResources();

    return { loading };
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
