<template>
  <div
    v-if="alarm.length"
    role="alert"
    class="relative block w-full text-base font-regular px-4 py-4 rounded-lg border border-red-400 text-red-500 flex mb-10"
    style="opacity: 1"
  >
    <div class="shrink-0">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        class="h-6 w-6"
      >
        <path
          fill-rule="evenodd"
          d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
          clip-rule="evenodd"
        ></path>
      </svg>
    </div>
    <div class="ml-3 mr-12">
      <p
        class="block antialiased font-sans text-base leading-relaxed text-inherit font-medium"
      >
        {{ i18n.t("alerts.alarm_description") }}:
      </p>
      <ul class="mt-2 ml-2 list-inside list-disc">
        <li v-for="a in alarm" :key="a">
          {{ a.description }}
          <DateAgo
            class="text-reyesol-gray text-sm pl-4"
            :datetime="a.created_at"
          ></DateAgo>
        </li>
      </ul>
    </div>
  </div>
  <div
    v-if="warning.length"
    role="alert"
    class="relative block w-full text-base font-regular px-4 py-4 rounded-lg border border-yellow-400 text-yellow-500 flex mb-10"
    style="opacity: 1"
  >
    <div class="shrink-0">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        class="h-6 w-6"
      >
        <path
          fill-rule="evenodd"
          d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
          clip-rule="evenodd"
        ></path>
      </svg>
    </div>
    <div class="ml-3 mr-12">
      <p
        class="block antialiased font-sans text-base leading-relaxed text-inherit font-medium"
      >
        {{ i18n.t("alerts.warning_description") }}:
      </p>
      <ul class="mt-2 ml-2 list-inside list-disc">
        <li v-for="w in warning" :key="w">
          {{ w.description }}
          <DateAgo
            class="text-reyesol-gray text-sm pl-4"
            :datetime="w.created_at"
          ></DateAgo>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue3-i18n";

export default defineComponent({
  props: {
    warning: {
      type: Array,
      default: () => [],
    },
    alarm: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const i18n = useI18n();

    return {
      i18n,
    };
  },
});
</script>
