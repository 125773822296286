<template>
  <div id="hubs-index">
    <div class="flex flex-col sm:flex-row justify-between py-2">
      <search-title
        :placeholder="i18n.t('hubs.search-by-name')"
        @search="updateSearchQuery"
        >{{ i18n.t("navigation.hubs") }}
        <div
          v-if="hubs && hubs.length > 0"
          class="text-xs inline md:block text-gray-600 w-auto"
        >
          <span class="hidden md:visible">Visualizzati </span>
          {{ hubs.length }} depositi
        </div>
        <div v-else class="text-xs text-gray-600 w-auto">
          {{ i18n.t("hubs.empty") }}
        </div>
      </search-title>

      <div class="flex justify-between py-2 md:items-center">
        <button class="btn btn-secondary mr-1" @click="exampleImport">
          {{ i18n.t("general.example-import-xlsx") }}
        </button>
        <label class="btn btn-primary">
          <loading-icon
            v-if="importing"
            icon="rings"
            color="white"
            class="mr-2"
          />
          {{ i18n.t("general.import-xlsx") }}
          <input type="file" class="hidden" @input="handleImport" />
        </label>
      </div>
    </div>
    <div class="flex flex-wrap">
      <div class="w-full lg:w-2/3">
        <Map
          class="aspect-h-3 aspect-w-4"
          :show-search="true"
          @init="handleMapInit"
          @circle:click="handleCircleClick"
          @circle:init="handleCircleInit"
        ></Map>
      </div>
      <div class="w-full pl-0 lg:pl-4 lg:w-1/3">
        <!-- new hub -->
        <button class="btn btn-primary w-full my-3" @click="addNewHub">
          <div class="w-10 h-10 rounded-md flex items-center justify-center">
            <PlusIcon />
          </div>
          <div class="ml-4 mr-auto">
            <div class="font-medium">{{ i18n.t("hubs.add") }}</div>
          </div>
        </button>

        <!-- hubs -->
        <HubCard
          v-for="h in hubs"
          :key="h.id"
          :hub="h"
          :active="activeHub && h.id == activeHub.id"
          @updated="updateCollection"
          @selected="handleHubSelect"
          @canceled="handleHubCancel"
        ></HubCard>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, watch } from "vue";
import { useI18n } from "vue3-i18n";
import { useStore } from "vuex";
import Map from "@/components/map/Map";
import HubCard from "./HubCard.vue";
import { useCollectionSearch } from "@/composables/collection-search";
import { xlsxToArray, arrayToXlsx } from "@/utils/parse-xlsx";
import EventBus from "@/libs/event-bus";
import { useRouter } from "vue-router";

export default {
  // eslint-disable-next-line vue/no-reserved-component-names
  components: { Map, HubCard },

  setup() {
    const i18n = useI18n();
    const store = useStore();
    const router = useRouter();
    const {
      updateSearchQuery,
      collectionToFilter,
      filteredCollection: hubs,
    } = useCollectionSearch(["code", "name", "address", "city"]);
    collectionToFilter.value = [];
    // utils
    const colors = store.getters["main/colors"];

    function getColorClass(colorKey) {
      if (colorKey == "white" || colorKey == "black")
        return `bg-${colorKey} border border-black`;
      return `bg-reyesol-${colorKey}`;
    }

    function getHubAddress(hub) {
      const arr = [];
      if (hub.address != null) arr.push(hub.address);
      if (hub.city != null) arr.push(hub.city);
      if (hub.zip_code != null) arr.push(hub.zip_code);
      return arr.join(", ");
    }

    // map
    let mapController = null;

    function handleMapInit(controller) {
      mapController = controller;
    }

    function handleCircleClick({ circle }) {
      selectHub(circle);
    }

    function handleCircleInit({ instance, circle, map }) {
      instance.on({
        mousedown: function () {
          map.value.dragging.disable();
          findAndSelectHubWithoutFly(circle.id);
          map.value.on("mousemove", function (e) {
            instance.setRadius(instance.getLatLng().distanceTo(e.latlng));
            return false;
          });
          map.value.on("mouseup", function () {
            map.value.removeEventListener("mousemove");
            map.value.removeEventListener("mouseup");
            map.value.dragging.enable();
            const radius = instance.getRadius();
            const hub = hubs.value.find((f) => f.id == activeHub.value.id);
            hub.radius = radius;
            activeHub.value.radius = radius;
            store.dispatch("hubs/update", activeHub.value);
            fetchHubs();
          });
        },
      });
    }

    onMounted(async () => {
      await fetchHubs();

      if ("f" in router.currentRoute.value.query) {
        findAndSelectHub(router.currentRoute.value.query.f);
        return;
      }

      const sf = store.getters["hubs/externalSelected"];
      if (sf) findAndSelectHub(sf.id);
      store.dispatch("hubs/resetExternalSelected");
    });

    watch(
      () => store.getters["hubs/externalSelected"],
      (sf) => {
        if (!sf) return;
        findAndSelectHub(sf.id);
        store.dispatch("hubs/resetExternalSelected");
      },
    );

    // fetch hubs

    const hubsAreas = ref([]);

    async function fetchHubs() {
      const response = await store.dispatch("hubs/getCollection");
      collectionToFilter.value = response.validated;
    }
    fetchHubs();

    function updateCollection(c) {
      collectionToFilter.value = c;
    }

    watch(hubs, drawAreas);
    async function drawAreas() {
      if (!mapController) return;
      mapController.clearCircles();
      hubs.value.forEach((h) => {
        mapController.addCircle({
          id: h.id,
          latitude: h.latitude,
          longitude: h.longitude,
          radius: h.radius,
          color: h.color,
        });
      });
    }

    // active hub
    const activeHub = ref(null);

    function handleHubSelect(hub) {
      selectHub(hub);
    }

    function handleHubCancel() {
      activeHub.value = null;
      collectionToFilter.value = collectionToFilter.value.filter((f) => f.id);
    }

    function findAndSelectHubWithoutFly(hubId) {
      const hub = collectionToFilter.value.find((f) => f.id == hubId);
      selectHub(hub, true);
    }

    function findAndSelectHub(hubId) {
      const hub = collectionToFilter.value.find((f) => f.id == hubId);
      selectHub(hub);
    }

    function selectHub(hub, stopFly) {
      if (stopFly != true) mapController.flyToCircle(hub.id);
      activeHub.value = hub;
    }

    // new hub
    function addNewHub() {
      if (activeHub.value && !activeHub.value.id) return;
      collectionToFilter.value = [
        {
          code: null,
          name: i18n.t("hubs.new"),
          color: "#000000",
          radius: 1,
          latitude: null,
          longitude: null,
          address: null,
          city: null,
          zip_code: null,
        },
        ...collectionToFilter.value,
      ];
      activeHub.value = collectionToFilter.value[0];
    }

    // import
    const importing = ref(false);

    async function exampleImport() {
      arrayToXlsx([
        [
          "name",
          "code",
          "color",
          "radius",
          "latitude",
          "longitude",
          "address",
          "city",
          "zip_code",
        ],
        [
          "Test Hub",
          "TST01",
          "#FF00FF",
          "250",
          "44,9149281",
          "8,6322739",
          "Via Galimberti, 52",
          "Alessandria",
          "15121",
        ],
      ]);
    }
    async function handleImport(e) {
      importing.value = true;
      const files = e.target.files;
      if (files.length) {
        const data = await xlsxToArray(files[0]);
        if (data.length) await storeImport(data);
      }
      importing.value = false;
    }

    async function storeImport(data) {
      EventBus.emit("confirm-dialog:show", {
        title: `${i18n.t("hubs.confirm-import-title")}`,
        callback: async (confirm) => {
          if (confirm) {
            for (let i = 0; i < data.length; i++) {
              const result = await store.dispatch("hubs/store", data[i]);
              if (result.success && result.validated.length) {
                updateCollection(result.validated);
              }
            }
          }
        },
      });
    }

    return {
      i18n,
      updateSearchQuery,

      // utils
      colors,
      getColorClass,
      getHubAddress,

      // fetch hubs
      hubs,
      hubsAreas,
      updateCollection,

      // active hub
      activeHub,
      handleHubSelect,
      handleHubCancel,

      // new hub
      addNewHub,

      // map
      handleMapInit,
      handleCircleInit,
      handleCircleClick,

      // import
      importing,
      handleImport,
      exampleImport,
    };
  },
};
</script>

<style></style>
