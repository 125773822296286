import { ref, watch } from "vue";
import dayjs from "dayjs";

const from = ref(dayjs().startOf("day").toDate());
const to = ref(dayjs().toDate());

const daterange = ref([from.value, to.value]);

// watch from and to
watch([from, to], () => {
  daterange.value = [from.value, to.value];
});

export function useDaterange({ onChange }) {
  // watch date range
  watch(daterange, () => {
    const dateFrom = daterange.value[0] || from.value;
    const dateTo = daterange.value[1] || to.value;

    from.value = dateFrom;
    to.value = dateTo;

    if (onChange && typeof onChange == "function") {
      onChange({ from, to });
    }
  });

  return {
    daterange,
    from,
    to,
  };
}
