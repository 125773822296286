<template>
  <button id="filterDialog" ref="filterDialog"></button>
  <div
    class="w-full bg-white mb-2 shadow-sm"
    :class="{
      'bg-reyesol-orange border-reyesol-orange text-white ':
        (requestedFeature !== 'all') | (requestedService !== 'system'),
    }"
  >
    <div class="dropdown px-2 rounded-md">
      <div class="dropdown-toggle flex h-10 w-full cursor-pointer">
        <button class="flex-grow h-10 rounded-md" aria-expanded="false">
          <span
            v-if="
              (requestedService !== 'system') | (requestedFeature !== 'all')
            "
            class="flex justify-self-start text-gray-800"
          >
            Filtra per:
            {{ getServiceName(requestedService) }} | Stato:
            {{ getFeatureName(requestedService, requestedFeature) }}
          </span>
          <span v-else class="flex justify-self-start text-gray-600">{{
            i18n.t("services.filter")
          }}</span>
        </button>

        <div
          v-if="requestedFeature == 'all' && requestedService == 'system'"
          class="flex justify-self-end place-self-center opacity-60"
        >
          <ChevronDownIcon></ChevronDownIcon>
        </div>
        <div
          v-if="(requestedFeature != 'all') | (requestedService != 'system')"
          class="flex justify-self-end place-self-center text-black"
          @click="clearFilter()"
        >
          <XIcon></XIcon>
        </div>
      </div>
      <div class="flex w-full h-2/3 overflow-y-scroll dropdown-menu rounded-md">
        <div class="dropdown-menu__content box w-full">
          <div class="accordion pt-4 p-4 bg-white rounded-md">
            <div
              v-for="service in services"
              :key="service.key"
              class="accordion-item"
            >
              <div
                :id="`services-filter-accordion-${service.key}`"
                class="accordion-header"
              >
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  :data-bs-target="`#services-filter-accordion-collapse-${service.key}`"
                  aria-expanded="false"
                  :aria-controls="`services-filter-accordion-collapse-${service.key}`"
                >
                  {{ service.name }}
                </button>
              </div>
              <div
                :id="`services-filter-accordion-collapse-${service.key}`"
                class="accordion-collapse"
                :aria-labelledby="`services-filter-accordion-content-${service.key}`"
                data-bs-parent="#services-filter-accordion"
                style="display: none"
              >
                <div
                  class="accordion-body text-gray-700 dark:text-gray-600 leading-relaxed"
                >
                  <div
                    v-for="feature in service.features"
                    :key="feature.key"
                    class="form-check mt-2"
                  >
                    <input
                      :id="`radio-feature-${service.key}-${feature.key}`"
                      class="form-check-input"
                      type="radio"
                      name="radio-feature-group"
                      :checked="
                        requestedService == service.key &&
                        requestedFeature == feature.key
                      "
                      @input="setServiceAndFeature(service.key, feature.key)"
                      @click="blur()"
                    />
                    <label
                      class="form-check-label"
                      :for="`radio-feature-${service.key}-${feature.key}`"
                      @click="blur()"
                    >
                      {{ feature.name }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue3-i18n";
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { ChevronDownIcon, XIcon } from "@zhuowenli/vue-feather-icons";

export default {
  components: { ChevronDownIcon, XIcon },
  emits: ["filter"],
  setup(props, { emit }) {
    const i18n = useI18n();
    const store = useStore();
    const router = useRouter();
    const services = computed(() => store.getters["main/services"]);
    const requestedService = ref(store.getters["globalMap/requestedService"]);
    const requestedFeature = ref(store.getters["globalMap/requestedFeature"]);
    watch(
      () => store.getters["globalMap/requestedService"],
      () => {
        requestedService.value = store.getters["globalMap/requestedService"];
      },
    );
    watch(
      () => store.getters["globalMap/requestedFeature"],
      () => {
        requestedFeature.value = store.getters["globalMap/requestedFeature"];
      },
    );
    function setServiceAndFeature(service, feature) {
      store.dispatch("globalMap/setRequestedService", service);
      store.dispatch("globalMap/setRequestedFeature", feature);
      requestedService.value = service;
      requestedFeature.value = feature;
      emit("filter", { service, feature });
    }
    function clearFilter() {
      store.dispatch("globalMap/resetRequestedService");
      store.dispatch("globalMap/resetRequestedFeature");
      requestedService.value = "system";
      requestedFeature.value = "all";
      router.replace({ query: {} });
      //emit("filter", { service, feature });
    }
    function setActiveService(serviceKey) {
      const accordionButton = document.querySelector(
        `#services-filter-accordion-${serviceKey} > button`,
      );
      if (accordionButton) {
        accordionButton.classList.remove("collapsed");
      }
      const accordionCollapse = document.querySelector(
        `#services-filter-accordion-collapse-${serviceKey}`,
      );
      if (accordionCollapse) {
        accordionCollapse.classList.remove("collapse");
        accordionCollapse.classList.add("show");
        accordionCollapse.style.display = "block";
      }
    }
    function getServiceName(serviceKey) {
      const service = services.value.find((s) => s.key == serviceKey);
      return service ? service.name : "";
    }
    function getFeatureName(serviceKey, featureKey) {
      const service = services.value.find((s) => s.key == serviceKey);
      if (!service) return "";
      const feature = service.features.find((f) => f.key == featureKey);
      return feature ? feature.name : "";
    }

    onMounted(() => {
      if (requestedService.value) setActiveService(requestedService.value);
      if (router.currentRoute.value.name == "dashboard") {
        (requestedFeature.value = "all"), (requestedService.value = "system");
      }
    });

    return {
      i18n,
      services,
      requestedService,
      requestedFeature,
      setActiveService,
      setServiceAndFeature,
      getServiceName,
      getFeatureName,
      clearFilter,
    };
  },
  methods: {
    blur: function () {
      this.$nextTick(() => {
        if (this.$refs.filterDialog) {
          this.$refs.filterDialog.click();
        }
      });
    },
  },
};
</script>

<style></style>
