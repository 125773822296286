<template>
  <div v-if="vehicle" id="service-eyeway">
    <div v-if="data">
      <div class="flex justify-between items-baseline mb-4">
        <div class="text-lg capitalize">
          {{ i18n.t("services.eyeway.last_status") }}
        </div>
        <DateAgo
          v-if="data.axle"
          :datetime="data.axle.message_datetime"
        ></DateAgo>
        <div v-else>
          {{ i18n.t("services.no_data") }}
        </div>
      </div>
      <div
        v-if="
          data.axle &&
          (vehicle.configurations.tyre_pressure_status_active ||
            vehicle.configurations.breaks_status_active)
        "
        class="report-box cursor-pointer mb-8"
      >
        <div class="box p-5">
          <!-- axle, cap, alarm -->
          <div class="flex justify-around">
            <VehicleParameter
              :active="
                vehicle.configurations.breaks_status_active &&
                data.axle.breaks_status != null
              "
              :name="i18n.t('services.eyeway.breaks_status.name')"
              icon="AlertTriangleIcon"
              :value="data.axle.breaks_status"
              :setclass="
                (val) => (val ? 'bg-reyesol-alert-alarm' : 'bg-reyesol-gray')
              "
              :format="
                (val) =>
                  val
                    ? i18n.t('services.eyeway.breaks_status.critical')
                    : i18n.t('services.eyeway.breaks_status.normal')
              "
            />

            <VehicleParameter
              :active="
                vehicle.configurations.tyre_pressure_status_active &&
                data.axle.tyres_pressure_status != null
              "
              :name="i18n.t('services.eyeway.tyres_pressure_status.name')"
              icon="AlertTriangleIcon"
              :value="data.axle.tyres_pressure_status"
              :setclass="
                (val) => (val ? 'bg-reyesol-alert-alarm' : 'bg-reyesol-gray')
              "
              :format="
                (val) =>
                  val
                    ? i18n.t('services.eyeway.tyres_pressure_status.critical')
                    : i18n.t('services.eyeway.tyres_pressure_status.normal')
              "
            />
          </div>
        </div>
      </div>

      <div v-if="data.axle" class="report-box cursor-pointer mb-8">
        <div class="box p-5 py-6">
          <!-- axle, cap, alarm -->
          <div class="flex justify-around">
            <VehicleParameter
              :active="data.axle.odometer != null"
              :name="i18n.t('services.eyeway.odometer.name')"
              icon="ArchiveIcon"
              :value="data.axle.odometer + ' Km'"
            />
            <VehicleParameter
              :active="data.axle.load != null"
              :name="i18n.t('services.eyeway.load.name')"
              icon="TrendingUpIcon"
              :value="data.axle.load + ' Kg'"
            />
            <VehicleParameter
              :active="data.axle.speed != null"
              :name="i18n.t('services.eyeway.speed.name')"
              icon="FastForwardIcon"
              :value="data.axle.speed + ' Km/h'"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-else class="flex justify-center py-6">
      <loading-icon icon="rings" class="w-20 h-20" />
    </div>

    <div class="py-6">
      <div class="text-lg capitalize mb-4">
        {{ i18n.t("services.eyeway.speed_history") }}
        <Eyepicker v-model="daterange" />
      </div>
      <div v-if="!loadingHistory">
        <div
          v-if="
            history &&
            history.list &&
            history.list.axle &&
            history.list.axle.length > 0
          "
        >
          <AxleChart :active="active" :height="200" :axle="history.list.axle" />
        </div>
        <div v-else class="font-light text-xl text-gray-500">
          {{ i18n.t("general.no-results") }}
        </div>
        <div class="btn-reyesol-report" @click="requestReport">
          <FileTextIcon /> {{ i18n.t("general.request-report") }}
        </div>
      </div>
      <div v-else class="flex justify-center py-6">
        <loading-icon icon="rings" class="w-20 h-20" />
      </div>
    </div>
  </div>
</template>

<script>
import AxleChart from "@/components/axle-chart/Main.vue";
import { watch, ref, computed, reactive, onUnmounted, onMounted } from "vue";
import { useI18n } from "vue3-i18n";
import { useStore } from "vuex";
import { useDaterange } from "@/composables/daterange";
import dayjs from "dayjs";
import EventBus from "@/libs/event-bus";
import VehicleParameter from "@/components/vehicle/VehicleParameter.vue";

export default {
  components: {
    AxleChart,
    VehicleParameter,
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const vehicle = computed(() => store.getters["globalMap/selectedVehicle"]);
    const data = reactive(ref(null));
    const history = reactive(ref(null));
    const loadingHistory = ref(false);
    const {
      daterange,
      from: historyFrom,
      to: historyTo,
    } = useDaterange({
      onChange: getHistory,
    });

    // watch service visibility
    watch(
      () => props.active,
      () => {
        if (props.active) initService();
        else destroyService();
      },
    );

    function updateAxle(axle) {
      if (data.value == null) return;
      data.value.axle = axle;

      if (
        dayjs().isSame(historyTo.value, "day") &&
        history.value != null &&
        history.value.list &&
        history.value.list.axle.length
      )
        history.value.list.axle.unshift(axle);
    }

    onMounted(() => {
      EventBus.on("vehicle:updates:axle", updateAxle);
    });
    onUnmounted(() => {
      EventBus.off("vehicle:updates:axle");
    });

    // init service
    async function initService() {
      const dataResult = await store.dispatch(
        "eyeway/getVehicleData",
        vehicle.value.id,
      );
      data.value = dataResult.validated;

      getHistory();
    }

    // requestReport service
    async function requestReport() {
      emit(
        "report-request",
        store.getters["globalMap/selectedVehicleSensors"].eyeway,
        "eyeway",
        store.getters["globalMap/selectedVehicleSensors"].eyeway,
      );
    }

    // destroy service
    function destroyService() {}

    // get history
    async function getHistory() {
      if (!props.active) return;
      loadingHistory.value = true;

      const historyResult = await store.dispatch("eyeway/getVehicleHistory", {
        vehicleId: vehicle.value.id,
        dateFrom: dayjs(historyFrom.value).format(),
        dateTo: dayjs().isSame(dayjs(historyTo.value), "day")
          ? dayjs().format()
          : dayjs(historyTo.value).format(),
      });
      history.value = historyResult.validated;
      loadingHistory.value = false;
    }

    return {
      i18n: useI18n(),
      dayjs,
      vehicle,
      data,
      history,
      requestReport,
      loadingHistory,
      daterange,
    };
  },
};
</script>

<style></style>
