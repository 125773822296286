<template>
  <div class="relative z-50">
    <input
      v-model="geocodingQuery"
      type="text"
      class="form-control w-full ring-transparent rounded-sm z-10"
      :placeholder="placeholder"
      @keypress.enter="handleEnter()"
      @keydown.up="goToNextResult(-1)"
      @keydown.down="goToNextResult(1)"
      @keydown.esc="handleEsc(false)"
      @input="handlefocus(true)"
      @focusout="handlefocus(false)"
    />
    <!--<button
      v-if="!geocodingResults.length"
      class="absolute top-0 right-0 btn btn-primary"
      @click="handleEnter()"
    >
      {{ i18n.t("general.search") }}
    </button>-->
    <div
      v-if="geocodingQuery.length > 1"
      id="resetField"
      class="absolute top-0 right-0 m-2 cursor-pointer bg-white rounded-full z-0"
      @click="geocodingQuery = ''"
    >
      <XCircleIcon></XCircleIcon>
    </div>
    <div
      v-show="geocodingResults.length"
      class="absolute bg-white rounded-md border border-gray-200 top-12 h-72 overflow-auto z-50"
      @mouseleave="goToResult()"
    >
      <div
        v-for="(r, i) in geocodingResults"
        :key="i"
        class="text-gray-100 cursor-pointer rounded-md p-2 bg-white z-50"
        :class="{
          'bg-theme-20': activeResultIndex == i,
        }"
        :style="{
          color: activeResultIndex == i ? 'black' : 'gray',
        }"
        @click="selectPlace(r)"
        @mouseover="goToResult(i)"
      >
        {{ r.display_name }}
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import { geoSearch } from "@/services/geocoder";
import { useI18n } from "vue3-i18n";
import { XCircleIcon } from "@zhuowenli/vue-feather-icons";
export default {
  components: { XCircleIcon },
  props: {
    placeholder: {
      type: String,
      default: "",
    },
  },
  emits: ["point"],
  setup(_, { emit }) {
    const i18n = useI18n();
    const geocodingQuery = ref("");
    const geocodingResults = ref([]);
    const infocus = ref(null);
    async function geocodingSearch() {
      geocodingResults.value = await geoSearch(geocodingQuery.value);
    }
    // handle query change
    watch(geocodingQuery, () => {
      activeResultIndex.value = null;
      geocodingResults.value = [];
      if (geocodingQuery.value.length > 2 && infocus.value == true) {
        geocodingSearch();
      }
    });
    // handle enter
    function handleEnter() {
      if (!geocodingResults.value.length) {
        geocodingSearch();
      } else {
        selectPlace();
      }
    }
    // handle esc
    function handleEsc() {
      geocodingResults.value = [];
    }
    // result handling
    const activeResultIndex = ref(null);

    //handle focus
    function handlefocus(state) {
      infocus.value = state;
    }
    function goToNextResult(indexIncrement) {
      if (!geocodingResults.value.length) return;
      if (activeResultIndex.value == null) {
        if (indexIncrement > 0) return (activeResultIndex.value = 0);
        if (indexIncrement < 0)
          return (activeResultIndex.value = geocodingResults.value.length - 1);
      }
      if (indexIncrement > 0)
        return (activeResultIndex.value =
          activeResultIndex.value < geocodingResults.value.length - 1
            ? activeResultIndex.value + 1
            : 0);
      if (indexIncrement < 0)
        return (activeResultIndex.value =
          activeResultIndex.value > 0
            ? activeResultIndex.value - 1
            : geocodingResults.value.length - 1);
    }
    function goToResult(geocodingResultIndex) {
      activeResultIndex.value = geocodingResultIndex ?? null;
    }
    function selectPlace(geocodingResult) {
      let g = geocodingResult;
      if (geocodingResult == null) {
        if (geocodingResults.value.length == 0) return;
        if (activeResultIndex.value == null) {
          g = geocodingResults.value[0];
        } else {
          g = geocodingResults.value[activeResultIndex.value];
        }
      }
      geocodingQuery.value = g.display_name;
      emit("point", {
        address: g.address.name,
        city: g.address.city,
        zip_code: g.address.postcode,
        latitude: g.lat,
        longitude: g.lon,
      });
      activeResultIndex.value = null;
      geocodingResults.value = [];
      geocodingQuery.value = "";
    }

    return {
      i18n,
      handleEnter,
      geocodingQuery,
      geocodingResults,
      geocodingSearch,
      selectPlace,
      activeResultIndex,
      goToNextResult,
      goToResult,
      handlefocus,
      handleEsc,
      infocus,
    };
  },
};
</script>

<style></style>
