<template>
  <div
    v-if="
      temperatures != null &&
      (temperatures.T1 != null ||
        temperatures.T2 != null ||
        temperatures.T3 != null ||
        temperatures.T4 != null)
    "
    class="flex w-fit"
  >
    <ThermometerIcon class="self-center" />
    <div class="badge-reyesol-temperatures-list">
      <span v-if="sensors > 0 && temperatures.T1 != null" class="temp">{{
        helper.format_temp(temperatures.T1)
      }}</span>
      <span v-if="sensors > 1 && temperatures.T2 != null" class="temp">{{
        helper.format_temp(temperatures.T2)
      }}</span>
      <span v-if="sensors > 2 && temperatures.T3 != null" class="temp">{{
        helper.format_temp(temperatures.T3)
      }}</span>
      <span v-if="sensors > 3 && temperatures.T4 != null" class="temp">{{
        helper.format_temp(temperatures.T4)
      }}</span>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import { helper } from "@/utils/helper";

import { ThermometerIcon } from "@zhuowenli/vue-feather-icons";
export default defineComponent({
  components: {
    ThermometerIcon,
  },
  props: {
    temperatures: {
      type: Object,
      default: null,
    },
    sensors: {
      type: [Number, String],
      default: 0,
    },
  },

  setup() {
    return {
      helper,
    };
  },
});
</script>
