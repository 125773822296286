<template>
  <div v-if="vehicle" id="service-eyegeo">
    <div v-if="data">
      <div class="flex justify-between items-baseline mb-4">
        <div class="text-lg capitalize">
          {{ i18n.t("services.eyegeo.last_position") }}
        </div>
        <div v-if="data.position">
          <DateAgo :datetime="data.position.message_datetime"></DateAgo>
        </div>
        <div v-else>
          {{ i18n.t("services.no_data") }}
        </div>
      </div>
      <LastPosition :vehicle="vehicle" :position="data.position"></LastPosition>
    </div>
    <div v-else class="flex justify-center py-6">
      <loading-icon icon="rings" class="w-20 h-20" />
    </div>

    <div class="py-6">
      <div class="text-lg capitalize mb-4">
        {{ i18n.t("services.eyegeo.positions_history") }}
        <Eyepicker v-model="daterange" />
      </div>
      <div class="btn-reyesol-report" @click="requestReport">
        <FileTextIcon /> {{ i18n.t("general.request-report") }}
      </div>
    </div>
  </div>
</template>

<script>
import { watch, ref, computed, createApp, onMounted, onUnmounted } from "vue";
import { useI18n } from "vue3-i18n";
import { useStore } from "vuex";
import { useDaterange } from "@/composables/daterange";
import EventBus from "@/libs/event-bus";
import dayjs from "dayjs";
import LastPosition from "@/components/map/LastPosition.vue";
import PopupPointRenderer from "@/components/popup-rendering/PopupPointRenderer.vue";
import PopupTripRenderer from "@/components/popup-rendering/PopupTripRenderer.vue";
import { helper as $h } from "@/utils/helper";

// eslint-disable-next-line vue/one-component-per-file
export default {
  components: {
    LastPosition,
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const i18n = useI18n();
    const store = useStore();
    const vehicle = computed(() => store.getters["globalMap/selectedVehicle"]);
    const mapController = store.getters["globalMap/controller"];
    const selected_trip = ref(null);
    const data = ref(null);
    const history = ref([]);
    const loadingHistory = ref(false);
    const {
      daterange,
      from: historyFrom,
      to: historyTo,
    } = useDaterange({
      onChange: getHistory,
    });
    const lastData = ref({ vehicle_id: null, from: null, to: null, ev: null });

    onMounted(() => {
      EventBus.on("vehicle:updates:trip", updateTrip);
      EventBus.on("vehicle:updates:position", updatePosition);
      EventBus.on("service:history", loadHistoryTrip);
    });
    onUnmounted(() => {
      EventBus.off("vehicle:updates:trip");
      EventBus.off("vehicle:updates:position");
      EventBus.off("service:history");
    });

    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const isSameOrBefore = require("dayjs/plugin/isSameOrBefore");
    dayjs.extend(isSameOrBefore);
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const isSameOrAfter = require("dayjs/plugin/isSameOrAfter");
    dayjs.extend(isSameOrAfter);

    // watch service visibility
    watch(
      () => props.active,
      () => {
        if (props.active) initService();
        else destroyService();
      },
    );

    function updatePosition(position) {
      if (data.value == null) return;
      data.value.position = position;
      selected_trip.value = data.value.trip;
    }

    function updateTrip(trip) {
      if (data.value == null) return;
      if (selected_trip.value == data.value.trip) {
        data.value.trip = trip;
        selected_trip.value = data.value.trip;
        handleHistoryClick(data.value.trip);
      } else {
        data.value.trip = trip;
        selected_trip.value = data.value.trip;
      }
    }

    // init service
    async function initService() {
      const dataResult = await store.dispatch(
        "eyegeo/getVehicleData",
        vehicle.value.id,
      );
      data.value = dataResult.validated;

      selected_trip.value = data.value.trip;
      //handleHistoryClick(selected_trip.value);
      getHistory();
    }

    // requestReport service
    async function requestReport() {
      emit(
        "report-request",
        store.getters["globalMap/selectedVehicleSensors"].eyegeo,
        "eyegeo",
        store.getters["globalMap/selectedVehicleSensors"].eyegeo,
      );
    }

    // destroy service
    function destroyService() {}

    // get history
    async function getHistory(update = true) {
      if (!props.active) return;

      loadingHistory.value = true;
      if (update) {
        loadHistoryTrip({
          vehicle_id: vehicle.value.id,
          from: dayjs(historyFrom.value).format(),
          to: dayjs().isSame(dayjs(historyTo.value), "day")
            ? dayjs().format()
            : dayjs(historyTo.value).format(),
        });
      }
      loadingHistory.value = false;
    }

    async function loadHistoryTrip({ vehicle_id, from, to, ev }) {
      if (
        lastData.value.vehicle_id != vehicle_id ||
        lastData.value.from != from ||
        lastData.value.to != to ||
        lastData.value.ev != ev
      ) {
        lastData.value.vehicle_id = vehicle_id;
        lastData.value.from = from;
        lastData.value.to = to;
        lastData.value.ev = ev;
        const historyResult = await store.dispatch(
          "eyegeo/getVehicleTripHistory",
          {
            vehicleId: vehicle_id,
            trip: true,
            dateFrom: dayjs(from).format(),
            dateTo: dayjs().isSame(dayjs(to), "day")
              ? dayjs().format()
              : dayjs(to).format(),
          },
        );

        if (ev && historyResult.validated && historyResult.validated.list) {
          historyResult.validated.list.forEach((a) => {
            if (a.end) {
              if (
                dayjs(a.end.message_datetime).isSameOrAfter(
                  ev.message_datetime,
                ) &&
                dayjs(a.start.message_datetime).isSameOrBefore(
                  ev.message_datetime,
                )
              ) {
                result = a;
              }
            }
          });

          if (result) {
            await drawTripRoute(result);
            mapController.flyToDate(ev.message_datetime, "service");
          } else {
            EventBus.emit("notification:show", {
              type: "error",
              title: i18n.t("services.trip_not_in_range"),
            });
          }
        } else {
          console.log('mapController.clearGroup("service")');
          mapController.clearGroup("service");
          if (historyResult.validated && historyResult.validated.list) {
            history.value = historyResult.validated;
            drawTripsSummary(
              history.value.list,
              historyResult.validated.summary,
              from,
            );
          }
        }
      }
    }

    async function handleHistoryClick(item) {
      if (selected_trip.value.id == item.id) {
        mapController.flyToPath(item.id, "service");
        return;
      }

      mapController.flyToPath(item.id, "service");
    }

    async function drawTripsSummary(items, summary, from) {
      mapController.clearGroup("service");

      if (items == null) return;

      if (items.length == 0) return;
      const start_position = items[0];
      let end_position = start_position;

      mapController.addMarker({
        id: start_position.start.id,
        group: "service",
        icon: "start",
        color: "#000000",
        latitude: start_position.start.latitude,
        longitude: start_position.start.longitude,
        datetime: start_position.start.message_datetime,
        popup: {
          content: function () {
            const app = createApp(PopupTripRenderer, { trip: start_position })
              .use(store)
              .use(i18n);
            const content = document.createElement("div");
            app.mount(content);
            return content;
          },
        },
        additionalData: {},
      });

      if (items.length > 1) {
        end_position = items[items.length - 1];
        for (const trip_idx in items) {
          const single_trip = items[trip_idx];
          if (
            single_trip.end &&
            end_position.end &&
            end_position.end.id != single_trip.end.id
          )
            mapController.addMarker({
              id: single_trip.end.id,
              group: "service",
              icon: "stop",
              color: "#000000",
              latitude: single_trip.end.latitude,
              longitude: single_trip.end.longitude,
              datetime: single_trip.end.message_datetime,
              popup: {
                content: function () {
                  const app = createApp(PopupPointRenderer, {
                    point: single_trip.end,
                  })
                    .use(store)
                    .use(i18n);
                  const content = document.createElement("div");
                  app.mount(content);
                  return content;
                },
              },
              additionalData: {},
            });
        }
      }
      if (end_position.end) {
        mapController.addMarker({
          id: end_position.end.id,
          group: "service",
          icon: "end",
          color: "#000000",
          latitude: end_position.end.latitude,
          longitude: end_position.end.longitude,
          datetime: end_position.end.message_datetime,
          popup: {
            content: function () {
              const app = createApp(PopupPointRenderer, {
                point: end_position.end,
              })
                .use(store)
                .use(i18n);
              const content = document.createElement("div");
              app.mount(content);
              return content;
            },
          },
          additionalData: {},
        });
      }

      const points = L.PolylineUtil.decode(summary);

      mapController.addPath({
        id: "paths",
        group: "service",
        markers: points.map((m, id) => {
          return {
            id: id,
            group: "service",
            icon: "dot",
            latitude: m[0] ?? 0,
            longitude: m[1] ?? 0,
            datetime: false,
            popup: {
              content: function () {
                const app = createApp(PopupPointRenderer, {
                  point: {
                    id: id,
                    vehicle: vehicle.value.id,
                    from: from,
                    latitude: m[0] ?? 0,
                    longitude: m[1] ?? 0,
                  },
                })
                  .use(store)
                  .use(i18n);
                const content = document.createElement("div");
                app.mount(content);
                return content;
              },
            },
          };
        }),
      });
      mapController.flyToPath("paths", "service");
    }

    async function drawTripRoute(item) {
      //mapController.clearGroup("service");
      if (typeof item.positions == "undefined") {
        const trip = await store.dispatch("eyegeo/getVehicleTrip", {
          vehicleId: vehicle.value.id,
          tripId: item.id,
        });
        item = trip.data;
      }

      if (item && item.polyline) {
        selected_trip.value = item;
        mapController.addPath({
          id: item.id,
          group: "service",
          markers: item.positions
            .sort(
              (a, b) =>
                dayjs(b.message_datetime) - dayjs(a.message_datetime) ||
                b.id - a.id,
            )
            .map((m) => {
              return {
                id: m.id,
                group: "service",
                icon: "dot",
                latitude: m.latitude ?? 0,
                longitude: m.longitude ?? 0,
                datetime: m.message_datetime,
                popup: {
                  content: function () {
                    const app = createApp(PopupPointRenderer, { point: m })
                      .use(store)
                      .use(i18n);
                    const content = document.createElement("div");
                    app.mount(content);
                    return content;
                  },
                },
              };
            }),
        });
      }
    }

    return {
      i18n,
      dayjs,
      h: $h,
      vehicle,
      data,
      history,
      requestReport,
      loadingHistory,
      daterange,
      mapController,
      handleHistoryClick,
      selected_trip,
    };
  },
};
</script>

<style></style>
