<template>
  <div v-if="meta && meta.total_pages > 1" class="flex justify-between">
    <div
      v-if="meta.current_page > 1"
      class="mr-1 mb-1 px-4 py-3 text-sm leading-4 border rounded hover:bg-white focus:border-indigo-500 focus:text-indigo-500"
      @click.stop="selectPage(1)"
    >
      1
    </div>
    <div class="flex flex-wrap -mb-1">
      <template v-if="meta.current_page > before">
        <template
          v-for="(item, index) in Math.min(before, meta.current_page - before)"
          :key="item"
        >
          <div
            class="mr-1 mb-1 px-4 py-3 text-sm leading-4 border rounded hover:bg-white focus:border-indigo-500 focus:text-indigo-500"
            @click.stop="
              selectPage(
                meta.current_page -
                  (Math.min(before, meta.current_page - before) - index),
              )
            "
          >
            {{
              meta.current_page -
              (Math.min(before, meta.current_page - before) - index)
            }}
          </div>
        </template>
      </template>
      <div
        class="mr-1 mb-1 px-4 py-3 text-sm leading-4 border rounded hover:bg-white focus:border-indigo-500 focus:text-indigo-500 bg-white"
      >
        {{ meta.current_page }}
      </div>
      <template v-if="meta.current_page < meta.total_pages">
        <template
          v-for="item in Math.min(after, meta.total_pages - meta.current_page)"
          :key="item"
        >
          <div
            class="mr-1 mb-1 px-4 py-3 text-sm leading-4 border rounded hover:bg-white focus:border-indigo-500 focus:text-indigo-500"
            @click.stop="selectPage(meta.current_page + item)"
          >
            {{ meta.current_page + item }}
          </div>
        </template>
      </template>
    </div>
    <div
      v-if="meta.current_page + after <= meta.total_pages"
      class="mr-1 mb-1 px-4 py-3 text-sm leading-4 border rounded hover:bg-white focus:border-indigo-500 focus:text-indigo-500"
      @click.stop="selectPage(meta.total_pages)"
    >
      {{ meta.total_pages }}
    </div>
  </div>
</template>

<script>
import { onMounted, onUnmounted } from "vue";

export default {
  props: {
    meta: {
      type: Object,
      default: null,
    },
    before: {
      type: Number,
      default: 2,
    },
    after: {
      type: Number,
      default: 2,
    },
  },
  emits: ["page"],
  setup(props, { emit }) {
    onMounted(() => {});
    onUnmounted(() => {});

    async function selectPage(page) {
      emit("page", page);
    }
    return {
      selectPage,
    };
  },
};
</script>
