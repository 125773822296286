<template>
  <div v-if="vehicle" id="service-eyecrono">
    <div class="flex flex-wrap-reverse">
      <div class="w-full">
        <div
          v-if="loading"
          class="flex flex-col place-items-center justify-center py-6"
        >
          <loading-icon icon="rings" class="w-40 h-40" />

          <div class="w-full text-center text-xl font-bold mt-6">
            Sei in attesa del caricamento del portale Eyecrono. Ti preghiamo di
            attendere.
          </div>
        </div>
        <div
          v-else-if="data != null"
          class="flex flex-col place-items-center justify-center py-6"
        >
          <div class="w-full text-center text-xl font-bold my-6 text-green-500">
            Richiesta effettuata con successo. Sei in attesa di essere
            reindirizzato.
          </div>

          <CheckCircleIcon class="w-40 h-40 text-green-500" />

          <div class="w-full text-center text-md font-bold mt-6">
            Se non dovessi essere reindirizzato correttamente sei pregato di
            premere il link qui sotto.
          </div>

          <a :href="data.url" class="btn btn-primary rounded-full w-full mt-6">
            Vai alla piattaforma
          </a>
        </div>
        <div
          v-else
          class="flex flex-col place-items-center justify-center py-6"
        >
          <AlertCircleIcon class="w-40 h-40 text-red-500" />

          <div class="w-full text-center text-xl font-bold mt-6 text-red-500">
            <p>Errore durante la procedura di attivazione.</p>
            <p>La pregiamo di contattare l'assistenza tecnica Fiway.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useI18n } from "vue3-i18n";
import { useStore } from "vuex";
import dayjs from "dayjs";

export default {
  components: {},
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const i18n = useI18n();
    const store = useStore();
    const vehicle = computed(() => store.getters["globalMap/selectedVehicle"]);

    const loading = ref(true);
    const data = ref(null);

    // watch service visibility
    watch(
      () => props.active,
      () => {
        if (props.active) {
          initService();
        } else {
          destroyService();
        }
      },
      { immediate: true },
    );

    async function initService() {
      const response = await store.dispatch("eyecrono/getPlatform");
      loading.value = false;
      if (response.success) {
        data.value = response.data;
        const newWin = window.open(response.data.url, "_blank");
        if (!newWin || newWin.closed || typeof newWin.closed == "undefined") {
          window.location.href = response.data.url;
        } else {
          newWin.blur();
          window.focus();
          if (vehicle.value.unit_code && !isNaN(vehicle.value.unit_code)) {
            newWin.window.onload = function () {
              newWin.window.location.href =
                "https://reyesol.fm-track.com/overview/" +
                vehicle.value.unit_code;
            };
          }
        }
      }
    }

    function destroyService() {
      // destroy service

      loading.value = true;
    }

    return {
      i18n,
      vehicle,
      dayjs,
      loading,
      data,
    };
  },
};
</script>
