<template>
  <div class="mt-2 box flex flex-col pointer-events-none">
    <div class="flex items-center border-b-2 border-theme-7 mb-1 pb-2">
      <div class="pl-4 w-full">
        <div class="font-bold flex text-base mb-2 content-center">
          <img
            :src="
              require(
                `@/assets/images/vehicles/${vehicle.vehicle_type}-primary.svg`,
              )
            "
            class="w-6 h-6 mr-2"
          />
          <span
            class="pointer-events-auto cursor-pointer hover:text-reyesol-blue truncate"
            @click="handleClick"
            >{{ vehicle.plate }}</span
          >
          <div class="ml-auto mr-5 justify-self-end">
            <ConnectionBadge
              :status="vehicle.status"
              :popover="true"
              class="py-0"
            />
          </div>
        </div>
        <div class="flex flex-row justify-between content-center pr-4">
          <div class="text-gray-600 font-thin text-xs break-words">
            {{ last_position }}
          </div>
          <div
            class="my-auto justify-self-end bg-gray-200 p-1 rounded shadow-md hover:bg-gray-400 m-2"
          >
            <a
              :href="makeMapsUrl(vehicle.latitude, vehicle.longitude)"
              target="_blank"
              class="pointer-events-auto"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-share-2"
              >
                <circle cx="18" cy="5" r="3"></circle>
                <circle cx="6" cy="12" r="3"></circle>
                <circle cx="18" cy="19" r="3"></circle>
                <line x1="8.59" y1="13.51" x2="15.42" y2="17.49"></line>
                <line x1="15.41" y1="6.51" x2="8.59" y2="10.49"></line>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-grow flex items-center">
      <div
        class="flex-grow grid grid-cols-3 md:grid-cols-3 justify-center items-center divide-x"
      >
        <div v-if="is_fridge" class="flex text-center justify-center">
          <img
            v-if="is_fridge"
            :src="require(`@/assets/images/cold.svg`)"
            class="w-5 h-5 text-blue-900 self-center"
          />
          <span
            v-if="vehicle.overall_status != null && vehicle.overall_status == 1"
            class="ml-1 py-1 px-1 rounded text-xs bg-reyesol-fridge-on text-white font-medium"
          >
            ON
          </span>
          <span
            v-if="vehicle.overall_status != null && vehicle.overall_status == 0"
            class="ml-1 py-1 px-1 rounded text-xs bg-reyesol-fridge-off text-white font-medium"
          >
            OFF
          </span>
        </div>
        <div
          v-else-if="vehicle.general_status !== null"
          class="flex text-center justify-center"
        >
          <img
            :src="require(`@/assets/images/zap-on.svg`)"
            class="w-5 h-5 text-blue-900 self-center"
          />
          <span
            v-if="vehicle.general_status == 1"
            class="ml-1 py-1 px-1 rounded text-xs bg-reyesol-fridge-on text-white font-medium"
          >
            ON
          </span>
          <span
            v-else
            class="ml-1 py-1 px-1 rounded text-xs bg-reyesol-fridge-off text-white font-medium"
          >
            OFF
          </span>
        </div>
        <div v-else class="flex text-center justify-center"></div>
        <div>
          <div class="text-center">
            <div class="text-center text-xs">
              {{ i18n.t("vehicles.fleet") }}
            </div>
            <div class="font-medium truncate">
              {{ vehicleFleet(vehicle.fleet_id) }}
            </div>
          </div>
        </div>
        <div>
          <div class="text-center">
            <div class="text-center text-xs">
              {{ i18n.t("vehicles.unit_code") }}
            </div>
            <div class="font-medium truncate">
              {{ vehicle.unit_code || "***" }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="flex-grow flex items-center my-2"
      :class="{
        'justify-end':
          !is_fridge && vehicle && (vehicle.driver || vehicle.moving),
      }"
    >
      <div v-if="is_fridge" class="flex-grow flex-cols items-center mt-2 pl-3">
        <!--Temperatures-->
        <TemperatureBadge
          :temperatures="vehicle"
          :sensors="vehicle.fridge_temperature_sensors_number"
        />
        <!--Setpoints-->
        <SetpointBadge
          :setpoints="vehicle"
          :compartments="vehicle.fridge_compartments_number"
          class="pt-1"
        />
      </div>
      <div v-if="!is_fridge" class="flex-grow flex-cols items-center mt-2 pl-3">
        <!--Moving-->
        <MovingBadge :moving="vehicle.moving" />
        <!--Driver-->
        <DriverBadge :driver="vehicle.driver" class="pt-1" />
      </div>

      <!--warning & alarm-->
      <div class="ml-2 pt-1">
        <div
          class="flex flex-col w-full pr-2 h-full gap-1 items-stretch justify-around"
        >
          <!--Anomalie-->
          <Tippy
            tag="div"
            class="tooltip w-full text-center"
            :content="`${vehicle_alerts.warning.length} anomalie`"
            :alt="`${vehicle_alerts.warning.length} anomalie`"
          >
            <NotificationBadge :warning="vehicle_alerts.warning.length">
              <InfoIcon class="self-center mr-1 relative" />
            </NotificationBadge>
          </Tippy>
          <!--Allarmi-->
          <Tippy
            tag="div"
            class="tooltip w-full text-center"
            :content="`${vehicle_alerts.alarm.length} alarmi`"
            :alt="`${vehicle_alerts.alarm.length} alarmi`"
          >
            <NotificationBadge :alarm="vehicle_alerts.alarm.length">
              <BellIcon class="self-center mr-1" />
            </NotificationBadge>
          </Tippy>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue3-i18n";
import { geoReverseSearch } from "@/services/geocoder";
import { ref, watch } from "vue";
import { BellIcon, InfoIcon } from "@zhuowenli/vue-feather-icons";
import { useStore } from "vuex";

import EventBus from "@/libs/event-bus";
import dayjs from "dayjs";
import ConnectionBadge from "@/components/connection-status/ConnectionBadge.vue";
import NotificationBadge from "@/components/alerts/NotificationBadge.vue";
import TemperatureBadge from "@/components/vehicle/TemperatureBadge.vue";
import SetpointBadge from "@/components/vehicle/SetpointBadge.vue";
import MovingBadge from "@/components/vehicle/MovingBadge.vue";
import DriverBadge from "@/components/vehicle/DriverBadge.vue";

import Tippy from "@/global-components/tippy/Main.vue";

import { helper } from "@/utils/helper";

export default {
  components: {
    BellIcon,
    InfoIcon,
    ConnectionBadge,
    TemperatureBadge,
    SetpointBadge,
    NotificationBadge,
    MovingBadge,
    DriverBadge,
    Tippy,
  },
  props: {
    vehicle: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const i18n = useI18n();
    const store = useStore();
    const last_position = ref(null);

    const alerts = store.getters["alerts/collection"];

    const vehicle_alerts = helper.process_vehicle_alerts(props.vehicle, alerts);

    const has_eyegeo_geocoding = props.vehicle.active_agreements.find(
      (o) => o.agreement_id == 25,
    );

    async function init() {
      if (!last_position.value) {
        if (has_eyegeo_geocoding) {
          const result = await geoReverseSearch(
            props.vehicle.latitude,
            props.vehicle.longitude,
          );
          if (result) {
            last_position.value = result.display_name;
          } else {
            last_position.value =
              "lat: " +
              normalize(props.vehicle.latitude) +
              ", long:" +
              normalize(props.vehicle.longitude);
          }
        } else {
          last_position.value =
            "lat: " +
            normalize(props.vehicle.latitude) +
            ", long:" +
            normalize(props.vehicle.longitude);
        }
      }
    }

    watch(() => props, init, { deep: true });

    const fleets = store.getters["fleets/collection"];

    const is_fridge = ref(false);

    const has_eyecold = props.vehicle.active_agreements.find(
      (o) => o.agreement_id == 3,
    );

    if (has_eyecold) {
      is_fridge.value = true;
    }
    function vehicleFleet(fleet_id) {
      const obj = fleets.find((o) => o.id == fleet_id);
      if (obj) return obj.code;
      else return "N/A";
    }
    function normalize(coord) {
      return [parseFloat(coord)];
    }

    function handleClick() {
      EventBus.emit("popup:select", props.vehicle.vehicle_id);
    }

    function makeMapsUrl(lat, lon) {
      return (
        "https://www.google.com/maps/search/?api=1&query=" + lat + " " + lon
      );
    }
    init();
    return {
      dayjs,
      i18n,
      vehicleFleet,
      vehicle_alerts,
      normalize,
      makeMapsUrl,
      is_fridge,
      has_eyegeo_geocoding,
      last_position,
      handleClick,
    };
  },
};
</script>

<style></style>
