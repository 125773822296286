import i18n from "../i18n";
import Cookies from "js-cookie";

const generalFeatures = [
  ...["all", "connected", "disconnected", "standby"].map((f) => ({
    key: f,
    name: i18n.t(`features.${f}.name`),
  })),
];
const alarmFeatures = [
  ...["alarm"].map((f) => ({
    key: f,
    name: i18n.t(`features.${f}.name`),
  })),
];
const serviceStandardFeatures = [
  ...["active"].map((f) => ({
    key: f,
    name: i18n.t(`features.${f}.name`),
  })),
];

const features = {
  system: [...generalFeatures, ...alarmFeatures],
  type_R: [...generalFeatures, ...alarmFeatures],
  type_M: [...generalFeatures, ...alarmFeatures],
  type_S: [...generalFeatures, ...alarmFeatures],
  type_V: [...generalFeatures, ...alarmFeatures],
  type_T: [...generalFeatures, ...alarmFeatures],
  type_P: [...generalFeatures, ...alarmFeatures],
  type_C: [...generalFeatures, ...alarmFeatures],
  type_U: [...generalFeatures, ...alarmFeatures],
  eyegeo: [
    ...serviceStandardFeatures,
    ...["speed_limit", "hub_enter", "hub_exit", "poi_enter", "poi_exit"].map(
      (f) => ({
        key: f,
        name: i18n.t(`features.${f}.name`),
      }),
    ),
  ],
  eyecold: [
    ...serviceStandardFeatures,
    ...[
      "cooling_system_off",
      "temperature_out_of_range",
      "setpoint_out_of_range",
      "local_unit_override",
      "cooling_system_alarm",
      "cooling_door_open",
    ].map((f) => ({
      key: f,
      name: i18n.t(`features.${f}.name`),
    })),
  ],
  eyedoor: [
    ...serviceStandardFeatures,
    ...["door_open", "door_siren", "door_locking"].map((f) => ({
      key: f,
      name: i18n.t(`features.${f}.name`),
    })),
  ],
  eyefuel: [
    ...serviceStandardFeatures,
    ...["cap_open", "fuel_alarm", "fuel_running_low"].map((f) => ({
      key: f,
      name: i18n.t(`features.${f}.name`),
    })),
  ],
  eyeway: [
    ...serviceStandardFeatures,
    ...["brake", "tyres", "overload"].map((f) => ({
      key: f,
      name: i18n.t(`features.${f}.name`),
    })),
  ],
  eyecar: [...serviceStandardFeatures],
  carbontax: [...serviceStandardFeatures],
  eyepharma: [...serviceStandardFeatures],
  eyecontrol: [...serviceStandardFeatures],
  // DISABLED  eyepower: [...serviceStandardFeatures],
  eyeshare: [
    ...serviceStandardFeatures,
    ...["sub_agreement_ends"].map((f) => ({
      key: f,
      name: i18n.t(`features.${f}.name`),
    })),
  ],
  eyeservice: [
    ...serviceStandardFeatures,
    ...["service", "fgas", "recorder", "maintenance"].map((f) => ({
      key: f,
      name: i18n.t(`features.${f}.name`),
    })),
  ],
  tandt: [
    ...serviceStandardFeatures,
    ...["tandt_locked", "tandt_unlocked", "tandt_unauthorized"].map((f) => ({
      key: f,
      name: i18n.t(`features.${f}.name`),
    })),
  ],
};

const state = () => {
  return {
    language: Cookies.get("language") || "it",
    notify_alerts: Cookies.get("notify_alerts") || "true",
    notify_notifications: Cookies.get("notify_notifications") || "true",
    colors: [
      { key: "red", value: "#D32929" },
      { key: "blue", value: "#2e51bb" },
      { key: "green", value: "#13B176" },
      { key: "yellow", value: "#FBC500" },
      { key: "orange", value: "#F78B00" },
      { key: "white", value: "#FFFFFF" },
      { key: "black", value: "#000000" },
    ],

    services: [
      ...[
        "system",
        "type_R",
        "type_M",
        "type_V",
        "type_S",
        "type_T",
        "type_P",
        "type_C",
        "type_U",
        "eyegeo",
        "eyecar",
        "eyegeo.directions",
        "eyecold",
        "eyedoor",
        "crono",
        "eyefuel",
        "eyeway",
        "carbontax",
        "eyepharma",
        "eyeshare",
        "eyecontrol",
        // DISABLED "eyepower",
        "eyeservice",
        "tandt",
      ].map((s) => ({
        key: s,
        name: i18n.t(`services.${s}.name`),
        features: s in features ? features[s] : [],
      })),
    ],

    filters: {
      fleets: [],
    },
  };
};

// getters
const getters = {
  language: (state) => state.language,
  notify_alerts: (state) => state.notify_alerts,
  notify_notifications: (state) => state.notify_notifications,
  colors: (state) => state.colors,
  services: (state) => state.services,
  filters: (state) => state.filters,
  fleetsFilter: (state) => state.filters.fleets,
};

// actions
const actions = {
  // language
  setLanguage({ commit }, code) {
    commit("SET_LANGUAGE", code);
  },
  // alerts
  setNotifyAlerts({ commit }, val) {
    commit("SET_NOTIFY_ALERTS", val);
  },
  // notifications
  setNotifyNotifications({ commit }, val) {
    commit("SET_NOTIFY_NOTIFICATIONS", val);
  },
  // filters
  setFleetsFilter({ commit }, fleets) {
    commit("SET_FLEETS_FILTER", fleets);
  },
  addFleetToFleetsFilter({ commit }, fleet) {
    commit("ADD_FLEET_TO_FLEETS_FILTER", fleet);
  },
  removeFleetFromFleetsFilter({ commit }, fleetId) {
    commit("REMOVE_FLEET_FROM_FLEETS_FILTER", fleetId);
  },
  clearFleetsFilter({ commit }) {
    commit("CLEAR_FLEETS_FILTER");
  },
};

// mutations
const mutations = {
  SET_LANGUAGE(state, code) {
    Cookies.set("language", code, { expires: 365 });
    state.language = code;
  },
  SET_NOTIFY_ALERTS(state, val) {
    Cookies.set("notify_alerts", val, { expires: 365 });
    state.notify_alerts = val;
  },
  SET_NOTIFY_NOTIFICATIONS(state, val) {
    Cookies.set("notify_notifications", val, { expires: 365 });
    state.notify_notifications = val;
  },
  // filters
  SET_FLEETS_FILTER(state, fleets) {
    state.filters.fleets = fleets.map((f) => {
      return { id: f.id, code: f.code, color: f.color };
    });
  },
  ADD_FLEET_TO_FLEETS_FILTER(state, fleet) {
    state.filters.fleets = [
      ...state.filters.fleets,
      {
        id: fleet.id,
        code: fleet.code,
        color: fleet.color,
      },
    ];
  },
  REMOVE_FLEET_FROM_FLEETS_FILTER(state, fleetId) {
    const i = state.filters.fleets.findIndex((f) => f.id == fleetId);
    if (i !== -1) {
      state.filters.fleets.splice(i, 1);
      state.filters.fleets = [...state.filters.fleets];
    }
  },
  CLEAR_FLEETS_FILTER(state) {
    state.filters.fleets = [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
