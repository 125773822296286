<template>
  <Chart
    type="line"
    :width="width"
    :height="height"
    :data="data"
    :options="options"
  />
</template>
<script>
import { computed, reactive, ref, watch } from "vue";
import dayjs from "dayjs";

export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 0,
    },
    height: {
      type: Number,
      default: 0,
    },
    power: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const datasets = ref([]);
    const labels = ref([]);
    const data = reactive({
      labels: computed(() => Array.from(new Set(labels.value))),
      datasets: computed(() => datasets.value),
    });

    function init() {
      labels.value = [];
      datasets.value = [];
      setChartData();
    }
    watch(() => props, init, { deep: true });
    init();

    function setChartData() {
      if (!props.active || !props.power) return;

      const data_external = props.power
        .map((item) => {
          if (item.external_voltage == null) return;
          return {
            x: dayjs(item.message_datetime).format(),
            y: item.external_voltage,
          };
        })
        .filter((e) => e);
      if (data_external.length > 0) {
        datasets.value.push({
          label: "Voltaggio esterno",
          data: data_external,
          borderWidth: 2,
          borderColor: "#537bc4",
          backgroundColor: "transparent",
          pointBorderColor: "transparent",
        });
      }
    }
    const options = {
      plugins: {
        pan: {
          enabled: true,
          mode: "xy",
        },
        zoom: {
          enabled: true,
          mode: "xy",
        },
      },
      scales: {
        xAxes: [
          {
            type: "time",
            time: {
              unit: "hour",
            },
            title: {
              display: true,
              text: "Orario",
            },
            ticks: {
              display: true,
              autoSkip: false,
              maxRotation: 0,
              major: {
                enabled: true,
              },
              font: function (context) {
                if (context.tick && context.tick.major) {
                  return {
                    weight: "bold",
                  };
                }
              },
            },
          },
        ],
        yAxes: [
          {
            type: "linear",
            position: "left",
            stack: "temps",
            stackWeight: 3,
            grid: {
              borderColor: "#777700",
            },
            ticks: {
              min: 0,
              max: 100,
            },
          },
        ],
      },
    };

    return {
      data,
      options,
    };
  },
};
</script>
