<template>
  <div v-if="vehicle" id="service-eyedoor">
    <div v-if="data">
      <div class="flex justify-between items-baseline mb-4">
        <div class="text-lg capitalize">
          {{ i18n.t("services.eyedoor.last_status") }}
        </div>
        <DateAgo
          v-if="data.door"
          :datetime="data.door.message_datetime"
        ></DateAgo>
        <div v-else>
          {{ i18n.t("services.no_data") }}
        </div>
      </div>

      <div v-if="data.door" class="report-box cursor-pointer mb-8">
        <div class="box p-5">
          <!-- door, cap, alarm -->
          <div class="flex justify-around">
            <VehicleParameter
              :active="
                vehicle.configurations.door1_active == 'true' &&
                data.door.door_1 != null
              "
              :name="i18n.t('services.eyedoor.door_1.name')"
              :icon="data.door.door_1 ? 'LogInIcon' : 'LockIcon'"
              :value="
                data.door.door_1
                  ? i18n.t('services.eyedoor.door_1.opened')
                  : i18n.t('services.eyedoor.door_1.closed')
              "
            />

            <VehicleParameter
              :active="
                vehicle.configurations.door2_active == 'true' &&
                data.door.door_2 != null
              "
              :name="i18n.t('services.eyedoor.door_2.name')"
              :icon="data.door.door_2 ? 'LogInIcon' : 'LockIcon'"
              :value="
                data.door.door_2
                  ? i18n.t('services.eyedoor.door_2.opened')
                  : i18n.t('services.eyedoor.door_2.closed')
              "
            />

            <VehicleParameter
              :active="
                vehicle.configurations.door3_active == 'true' &&
                data.door.door_3 != null
              "
              :name="i18n.t('services.eyedoor.door_3.name')"
              :icon="data.door.door_3 ? 'LogInIcon' : 'LockIcon'"
              :value="
                data.door.door_3
                  ? i18n.t('services.eyedoor.door_3.opened')
                  : i18n.t('services.eyedoor.door_3.closed')
              "
            />

            <VehicleParameter
              :active="
                vehicle.configurations.door4_active == 'true' &&
                data.door.door_4 != null
              "
              :name="i18n.t('services.eyedoor.door_4.name')"
              :icon="data.door.door_4 ? 'LogInIcon' : 'LockIcon'"
              :value="
                data.door.door_4
                  ? i18n.t('services.eyedoor.door_4.opened')
                  : i18n.t('services.eyedoor.door_4.closed')
              "
            />

            <VehicleParameter
              :active="
                vehicle.configurations.siren_active == 'true' &&
                data.door.armed != null
              "
              :name="i18n.t('services.eyedoor.armed.name')"
              :icon="data.door.armed ? 'BellIcon' : 'BellOffIcon'"
              :setclass="
                () =>
                  data.door.armed &&
                  (data.door.door_1 ||
                    data.door.door_2 ||
                    data.door.door_3 ||
                    data.door.door_4)
                    ? 'bg-reyesol-alert-alarm'
                    : 'bg-reyesol-gray'
              "
              :value="
                data.door.armed
                  ? data.door.door_1 ||
                    data.door.door_2 ||
                    data.door.door_3 ||
                    data.door.door_4
                    ? i18n.t('services.eyedoor.armed.active')
                    : i18n.t('services.eyedoor.armed.armed')
                  : i18n.t('services.eyedoor.armed.none')
              "
            />
          </div>
        </div>
      </div>
    </div>
    <div v-else class="flex justify-center py-6">
      <loading-icon icon="rings" class="w-20 h-20" />
    </div>

    <div class="py-6">
      <div class="text-lg capitalize mb-4">
        {{ i18n.t("services.eyedoor.door_history") }}
        <Eyepicker v-model="daterange" />
      </div>
      <div v-if="!loadingHistory" class="max-h-80 overflow-y-auto">
        <div v-if="history && history.list">
          <div v-for="h in history.list.door" :key="h.id">
            <div v-if="h" class="intro-y">
              <div
                class="box px-4 py-4 mb-3 flex items-center cursor-pointer mx-2 shadow-md hover:shadow-lg hover:bg-gray-200"
                :class="{ 'bg-gray-200': selectedEvent.id === h.id }"
                @click="handleHistoryClick(h)"
              >
                <div
                  class="w-10 h-10 flex items-center justify-center image-cover rounded-md overflow-hidden"
                >
                  <img
                    alt="pin image"
                    :src="require('@/assets/images/marker-icon-small.png')"
                  />
                </div>
                <div class="ml-4 mr-auto">
                  <div class="font-medium">
                    <DateAgo :datetime="h.message_datetime"></DateAgo>
                  </div>
                </div>

                <VehicleParameter
                  :active="
                    vehicle.configurations.door1_active == 'true' &&
                    h.door_1 !== null
                  "
                  :name="i18n.t('services.eyedoor.door_1.name')"
                  :icon="h.door_1 ? 'LogInIcon' : 'LockIcon'"
                  :value="
                    h.door_1
                      ? i18n.t('services.eyedoor.door_1.opened')
                      : i18n.t('services.eyedoor.door_1.closed')
                  "
                />

                <VehicleParameter
                  :active="
                    vehicle.configurations.door2_active == 'true' &&
                    h.door_2 !== null
                  "
                  :name="i18n.t('services.eyedoor.door_2.name')"
                  :icon="h.door_2 ? 'LogInIcon' : 'LockIcon'"
                  :value="
                    h.door_2
                      ? i18n.t('services.eyedoor.door_2.opened')
                      : i18n.t('services.eyedoor.door_2.closed')
                  "
                />
                <VehicleParameter
                  :active="
                    vehicle.configurations.door3_active == 'true' &&
                    h.door_3 !== null
                  "
                  :name="i18n.t('services.eyedoor.door_3.name')"
                  :icon="h.door_3 ? 'LogInIcon' : 'LockIcon'"
                  :value="
                    h.door_3
                      ? i18n.t('services.eyedoor.door_3.opened')
                      : i18n.t('services.eyedoor.door_3.closed')
                  "
                />
                <VehicleParameter
                  :active="
                    vehicle.configurations.door4_active == 'true' &&
                    h.door_4 !== null
                  "
                  :name="i18n.t('services.eyedoor.door_4.name')"
                  :icon="h.door_4 ? 'LogInIcon' : 'LockIcon'"
                  :value="
                    h.door_4
                      ? i18n.t('services.eyedoor.door_4.opened')
                      : i18n.t('services.eyedoor.door_4.closed')
                  "
                />

                <VehicleParameter
                  :active="
                    vehicle.configurations.siren_active == 'true' &&
                    h.armed !== null
                  "
                  :name="i18n.t('services.eyedoor.armed.name')"
                  :icon="h.armed ? 'BellIcon' : 'BellOffIcon'"
                  :setclass="
                    () =>
                      h.armed && (h.door_2 || h.door_1 || h.door_3 || h.door_4)
                        ? 'bg-reyesol-alert-alarm'
                        : 'bg-reyesol-gray'
                  "
                  :value="
                    h.armed
                      ? h.door_2 || h.door_1 || h.door_3 || h.door_4
                        ? i18n.t('services.eyedoor.armed.active')
                        : i18n.t('services.eyedoor.armed.armed')
                      : i18n.t('services.eyedoor.armed.none')
                  "
                />
              </div>
            </div>
          </div>
        </div>
        <div v-else class="font-light text-xl text-gray-500">
          {{ i18n.t("general.no-results") }}
        </div>
      </div>
      <loading-icon v-else icon="rings" class="w-20 h-20" />
      <div class="btn-reyesol-report" @click="requestReport">
        <FileTextIcon /> {{ i18n.t("general.request-report") }}
      </div>
    </div>
  </div>
</template>

<script>
import { watch, ref, computed, reactive, onUnmounted, onMounted } from "vue";
import { useI18n } from "vue3-i18n";
import { useStore } from "vuex";
import { useDaterange } from "@/composables/daterange";
import dayjs from "dayjs";
import EventBus from "@/libs/event-bus";
import VehicleParameter from "@/components/vehicle/VehicleParameter.vue";

export default {
  components: { VehicleParameter },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const i18n = useI18n();
    const store = useStore();
    const vehicle = computed(() => store.getters["globalMap/selectedVehicle"]);
    const mapController = store.getters["globalMap/controller"];
    const data = reactive(ref(null));
    const history = reactive(ref(null));
    const loadingHistory = ref(false);
    const selectedEvent = ref(0);
    const {
      daterange,
      from: historyFrom,
      to: historyTo,
    } = useDaterange({
      onChange: getHistory,
    });

    // watch service visibility
    watch(
      () => props.active,
      () => {
        if (props.active) initService();
        else destroyService();
      },
    );

    function updateDoor(door) {
      if (data.value == null) return;
      data.value.door = door;
      if (
        dayjs().isSame(historyTo.value, "day") &&
        history.value != null &&
        history.value.list &&
        history.value.list.door.length
      )
        history.value.list.door.unshift(door);
    }

    onMounted(() => {
      EventBus.on("vehicle:updates:door", updateDoor);
    });
    onUnmounted(() => {
      EventBus.off("vehicle:updates:door");
    });

    async function handleHistoryClick(history) {
      if (history.id == selectedEvent.value.id) {
        mapController.flyToDate(history.message_datetime, "service");
        return;
      }
      selectedEvent.value = history;
      EventBus.emit("notification:show", {
        type: "info",
        title: i18n.t("services.open_eyegeo_before"),
      });

      EventBus.emit("service:history", {
        vehicle_id: vehicle.value.id,
        from: historyFrom.value,
        to: historyTo.value,
        ev: history,
      });
    }

    // init service
    async function initService() {
      const dataResult = await store.dispatch(
        "eyedoor/getVehicleData",
        vehicle.value.id,
      );
      data.value = dataResult.validated;
      getHistory();
    }

    // requestReport service
    async function requestReport() {
      emit(
        "report-request",
        ["eyedoor"],
        "master",
        [].concat(
          store.getters["globalMap/selectedVehicleSensors"].eyecold,
          store.getters[
            "globalMap/selectedVehicleSensors"
          ].eyecold_fridge.filter(
            (v) =>
              v == "eyecold_fridge.overall_status" ||
              v == "eyecold_fridge.alarm_status",
          ),
          store.getters["globalMap/selectedVehicleSensors"].eyedoor,
          store.getters["globalMap/selectedVehicleSensors"].eyecontrol,
        ),
      );
    }

    // destroy service
    function destroyService() {}
    // get history
    async function getHistory() {
      if (!props.active) return;
      loadingHistory.value = true;

      const historyResult = await store.dispatch("eyedoor/getVehicleHistory", {
        vehicleId: vehicle.value.id,
        dateFrom: dayjs(historyFrom.value).format(),
        dateTo: dayjs().isSame(dayjs(historyTo.value), "day")
          ? dayjs().format()
          : dayjs(historyTo.value).format(),
      });
      history.value = historyResult.validated;
      loadingHistory.value = false;
    }

    return {
      i18n,
      dayjs,
      vehicle,
      data,
      history,
      requestReport,
      loadingHistory,
      daterange,
      handleHistoryClick,
      selectedEvent,
    };
  },
};
</script>

<style></style>
